<template>
    <div class="inventory-management">
        <div>
            <div class="title-info">
                <div class="item">
                    <span class="text">粉丝特征名称</span>
                    <el-select v-model="fansName" placeholder="请选择" clearable @change="fansChange"
                               style="width: 420px;">
                        <el-option v-for="fansItem in fansList" :key="fansItem.id" :label="fansItem.name"
                                   :value="fansItem.id"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <span class="text">商品类目</span>
                    <el-cascader
                            style="width:420px"
                            v-model="category_id"
                            :options="goodsClassList"
                            :props="keyObjClass"
                            @change="handleChangeGoodsClass">
                    </el-cascader>
                </div>
            </div>
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">发货地址管理</div>
                    <el-button type="primary" @click="scoringOperationBtn()">添加得分操作</el-button>
                </div>
                <el-table :data="addressList" style="width: 100%; flex: 1" size="medium" border
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                    <el-table-column prop="where_address" label="所在地址"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" align="center"></el-table-column>
                    <el-table-column prop="postcode" label="邮编 " align="center">
                        <template slot-scope="scope">
                          <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话 " align="center"></el-table-column>
                    <el-table-column prop="ship_name" label="发货人姓名 " align="center"></el-table-column>
                    <el-table-column label="默认 " align="center">
                        <template slot-scope="scope">
                          <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="score" label="分值 " align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link type="success" :underline="false" @click="editAddress(scope.row,scope.$index)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="deleteAddress(scope.$index)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">在售商品</div>
                    <el-button type="primary" @click="goodsBtn()">添加商品</el-button>
                </div>
                <div class="goods-table">
                    <div class="goods-table-header">
                        <div class="table-header-item" :style="styleFlex[0]"><span>商品标题</span></div>
                        <div class="table-header-item" :style="styleFlex[1]"><span>总库存</span></div>
                        <div class="table-header-item" :style="styleFlex[2]"><span>市场价</span></div>
                        <div class="table-header-item" :style="styleFlex[3]"><span>销售价</span></div>
                        <div class="table-header-item" :style="styleFlex[4]"><span>得分库存</span></div>
                        <div class="table-header-item" :style="styleFlex[5]"><span>分值</span></div>
                        <div class="table-header-item" :style="styleFlex[6]"><span>操作</span></div>
                    </div>
                    <div class="goods-table-body">
                        <div class="table-body-item" v-for="(item,index) in good_data">
                            <div class="body-item-top">
                                <div class="item-top-item" :style="styleFlex[0]">
                                    <el-select v-model="item.good_id" placeholder="请选择商品" :ref="`good_id_${index}`" @change="selectGoodsChange(item.good_id,index)">
                                        <el-option
                                                v-show="isSelectGoods(item.goods_id)"
                                                v-for="item in goodsList"
                                                :key="item.goods_id"
                                                :label="item.good_title"
                                                :value="item.goods_id">
                                        </el-option>
                                        <el-option :value="0" label="暂无数据" v-if="goodsChooseList.length === goodsList.length" :disabled="true"></el-option>
                                    </el-select>
                                </div>
                                <div class="item-top-item" :style="styleFlex[1]">
                                    <el-input-number :ref="`total_inventory_${index}`" :controls="false" v-model="item.total_inventory" :precision="0" :min="1" :max="9999999999"></el-input-number>
                                </div>
                                <div class="item-top-item" :style="styleFlex[2]">
                                    <el-input-number :ref="`market_${index}`" :controls="false" v-model="item.market_price" :precision="2" :min="0.01" :max="999999.99"></el-input-number>
                                </div>
                                <div class="item-top-item" :style="styleFlex[3]">
                                    <el-input-number :ref="`sale_price_${index}`" :controls="false"  @change="setSale(item)" v-model="item.sale_price" :precision="2" :min="0.01" :max="999999.99"></el-input-number>
                                </div>
                                <div class="item-top-item" :style="styleFlex[4]">
                                    <el-input-number :ref="`scores_inventory_${index}`" :controls="false" v-model="item.scores_inventory" :precision="0" :min="0" :max="999999999"></el-input-number>
                                </div>
                                <div class="item-top-item" :style="styleFlex[5]">
                                    <el-input-number :ref="`score_${index}`" :controls="false" v-model="item.score" :precision="2" :min="0.01" :max="100"></el-input-number>
                                </div>
                                <div class="item-top-item" :style="styleFlex[6]">
                                    <el-link type="danger" @click="deleteBtn(index)">删除</el-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="cancel">取消</el-button>
            <el-button size="medium" type="primary" @click="saveInventory()">保存</el-button>
        </div>
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="addressTitle" @close="resetForm()"
                   custom-class="green-dialog02" :visible.sync="addressDialogVisible" width="500px">
            <el-form :model="addressForm" ref="addressForm" label-position="right" :rules="addressRules"
                     :hide-required-asterisk="true" label-width="112px">
                <el-form-item label="所在地区" prop="where_address">
                    <div style="display: flex;">
                        <el-select v-model="addressForm.province" filterable placeholder="请选择省" style="width: 150px;margin-right: 10px;" @change="selectProvince">
                            <el-option
                                    v-for="item in provinceList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                        <el-select v-model="addressForm.city" filterable placeholder="请选择市" style="width: 150px;margin-right: 10px;" @change="selectCity">
                            <el-option
                                    v-for="item in cityList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                        <el-select v-model="addressForm.area" filterable placeholder="请选择区" style="width: 150px;margin-right: 10px;">
                            <el-option
                                    v-for="item in areaList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址" prop="detailed_address">
                    <el-input v-model="addressForm.detailed_address"  autocomplete="off" style="width:340px"
                              placeholder="请输入详细地址"  maxlength="100" show-word-limit ></el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="postcode" class="emil-num">
                    <el-input v-model.number="addressForm.postcode" placeholder="请输入邮编"
                              maxlength="6" clearable style="width: 340px"></el-input>

<!--                    <el-input-number v-model="addressForm.emil"  :max="999999" :controls="false"  placeholder="请输入邮编" style="width:340px"></el-input-number>-->
                </el-form-item>
                <el-form-item label="联系电话"  prop="phone">
                    <el-input v-model="addressForm.phone" placeholder="请输入联系电话" maxlength="11" clearable style="width:340px"></el-input>
                </el-form-item>
                <el-form-item label="发货人姓名"  prop="ship_name">
                    <el-input v-model="addressForm.ship_name"  placeholder="请输入发货人姓名" style="width:340px" maxlength="5" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="是否默认"  prop="defualt">
                    <el-checkbox v-model="addressForm.defualt"></el-checkbox>
                </el-form-item>
                <el-form-item label="分值"  prop="score" class="emil-num">
                    <el-input-number v-model="addressForm.score" :min="0.1" :max="100" :precision="1" :controls="false"  placeholder="请输入分值" style="width:340px"></el-input-number>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="addressDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addressBtn('addressForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {adminGoodGetCategory,fanList,transactionGetArea,transactionAdminRepertoryCreate,transactionGetGoodsInfo,operationProblemOpDetail} from '@/utils/apis'
    export default {
        name: "InventoryManagement",
        props: ['practiceForm'],
        data(){
            //手机号验证
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('手机号不能为空'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            let checkPostcode = (rule, value, callback) => {
                if (value) {
                    const reg = /[1-9]\d{5}(?!\d)/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('邮编格式不正确'));
                    }
                } else {
                    callback();
                }  
            };
            return{
                fansId:'',
                fansName:'',
                category_id:'',
                fansList:[],
                goodsClassList: [],//商品类目列表
                keyObjClass: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                //发货地址列表
                addressList:[],
                addressTitle:'',
                addressDialogVisible:false,
                addressForm:{
                    where_address:'',
                    province:'',
                    city:'',
                    area:'',
                    detailed_address:'',
                    postcode:'',
                    phone:'',
                    ship_name:'',
                    defualt:'',
                    score:  0,
                },
                provinceList:[],
                cityList:[],//市
                areaList:[],//区
                addressRules: {
                    where_address:[
                        {required: true, message: '请选择所在地区', trigger: ['blur', 'change']},
                    ],
                    detailed_address:[
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
                    ],
                    phone: {validator: checkPhone, trigger: ['blur', 'change']},
                    ship_name:[
                        {required: true, message: '请输入发货人姓名', trigger: 'blur'},
                        { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                    ],
                    score: [
                        {required: true, message: '请输入分值', trigger: 'blur'},
                    ],
                    postcode: [
                        {validator: checkPostcode, trigger: ['blur']},
                    ]
                },
                good_data:[], //设置商品数据
                goodsList:[], // 商品列表
                goodsChooseList:[],
                styleFlex:[
                    {
                        flex:0.6,
                        marginRight:'20px'
                    },
                    {
                        flex:0.7,
                        marginRight:'20px'
                    },
                    {
                        flex:0.8,
                        marginRight:'20px'
                    },
                    {
                        flex:0.8,
                        marginRight:'20px'
                    },
                    {
                        flex:0.8,
                        marginRight:'20px'
                    },
                    {
                        flex:0.5
                    },
                ],
                addressTableIndex:[],
            }
        },
        mounted() {
            this.getGoodsClassList();
            this.getFansList();
            this.getAreaList()
            if (this.$route.query.id){
                this.getOperationProblemOpDetail()
            }
        },
        methods:{
            //获取详情
            getOperationProblemOpDetail(){
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.fansId = res.data.fans_name_id;
                    this.fansName= res.data.fans_special_name;
                    if (res.data.goods_class_id){
                        this.category_id = res.data.goods_class_id;
                        this.getTransactionGetGoods(this.category_id[this.category_id.length - 1])
                    }
                    this.addressList = res.data.deliver_address;
                    this.good_data = res.data.goods_management;
                    this.good_data.forEach((item)=>{
                        this.goodsChooseList.push(item.good_id)
                    });
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取商品类目
            getGoodsClassList() {
                adminGoodGetCategory().then((res) => {
                    this.goodsClassList = res.data;
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 获取粉丝特征列表
            getFansList() {
                fanList().then((res) => {
                    this.fansList = res.data.data
                })
            },
            fansChange(val){
                if (val) {
                    this.fansList.forEach(item => {
                        if (val === item.id) {
                            this.fansId = item.id
                            this.fansName = item.name
                        }
                    })
                }
            },
            //选择商品列表
            handleChangeGoodsClass() {
                this.getTransactionGetGoods(this.category_id[this.category_id.length - 1])
            },
            // 获取商品信息
            getTransactionGetGoods(category_id){
                let params = {
                    goods_class_id:category_id
                }
                transactionGetGoodsInfo(params).then((res)=>{
                    this.goodsList = res.data;
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            //获取地区列表
            getAreaList() {
                transactionGetArea().then((res) => {
                    this.provinceList = res.data;
                }).catch((err)  => {
                    console.log(err);
                })
            },
            //根据省选择市
            selectProvince(val) {
                if (val) {
                    this.provinceList.forEach(item => {
                        if (item.area_name === val) {
                            this.cityList = item.children_city;
                        }
                    })
                }
                this.addressForm.city = this.cityList[0].area_name
                this.selectCity(this.addressForm.city)
            },
            //根据市选择区
            selectCity(val){
                if (val) {
                    this.cityList.forEach(item => {
                        if (item.area_name === val) {
                            this.areaList = item.children_region;
                        }
                    })
                }
                this.addressForm.area = this.areaList[0].area_name
                this.addressForm.where_address = `${this.addressForm.province}${this.addressForm.city}${this.addressForm.area}`
            },
            //添加得分操作
            scoringOperationBtn(){
                this.addressDialogVisible = true;
                this.addressTitle='添加得分操作';
                this.addressTableIndex = -1;
            },
            // 编辑
            editAddress(row,index){
                this.addressTitle='编辑得分操作';
                this.addressDialogVisible=true
                this.addressForm.where_address= row.where_address;
                this.addressForm.province= row.where_address.split(' ')[0];
                this.addressForm.city= row.where_address.split(' ')[1];
                this.addressForm.area= row.where_address.split(' ')[2];
                this.addressForm.detailed_address= row.detailed_address;
                this.addressForm.postcode= row.postcode;
                this.addressForm.phone= row.phone;
                this.addressForm.ship_name= row.ship_name;
                this.addressForm.defualt= row.defualt===1;
                this.addressForm.score= row.score;
                this.addressTableIndex = index;
            },
            //确定
            addressBtn(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let where_address = this.addressForm.province + " " + this.addressForm.city + " " + this.addressForm.area
                        let ob = {
                            where_address:where_address,
                            detailed_address:this.addressForm.detailed_address,
                            postcode:this.addressForm.postcode,
                            phone:this.addressForm.phone,
                            ship_name:this.addressForm.ship_name,
                            score:this.addressForm.score,
                        }
                        let defaultIndex = -1
                        for(let i = 0; i < this.addressList.length; i++){
                            if(this.addressList[i].defualt == 1){
                                defaultIndex = i;
                                break;
                            }
                        }
                        //如果已经有了默认地址
                        if(defaultIndex != -1 && defaultIndex !=this.addressTableIndex && this.addressForm.defualt){
                            this.$confirm('是否修改默认地址', '提示消息', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                customClass: 'green-message-box',
                                type: 'warning'
                            }).then(() => {
                                this.addressList[defaultIndex].defualt = 0
                                ob.defualt = this.addressForm.defualt?1:0
                                if (this.addressTableIndex>-1){
                                    // this.addressList[this.addressTableIndex] = ob;
                                    this.$set(this.addressList, this.addressTableIndex, ob);
                                }else{
                                    // this.addressList.push(ob);
                                    this.$set(this.addressList, this.addressTableIndex, ob);
                                }
                                this.resetForm()
                                this.addressDialogVisible=false;
                            }).catch(() => {
                                this.addressForm.defualt = 0
                            })
                        } else {
                            ob.defualt = this.addressForm.defualt?1:0
                            if (this.addressTableIndex>-1){
                                // this.addressList[this.addressTableIndex] = ob;
                                this.$set(this.addressList, this.addressTableIndex, ob);
                            }else{
                                this.addressList.push(ob);
                                // this.$set(this.addressList, this.addressTableIndex, ob);
                            }
                            this.resetForm()
                            this.addressDialogVisible=false;
                        }
                    } else {
                        return false;
                    }
                });
            },
            //删除
            deleteAddress(index){
                this.addressList.splice(index, 1);
            },
            //添加商品
            goodsBtn(){
                let obj = {
                    good_id:'', // 选择的商品id
                    total_inventory:void 0, //总库存
                    market_price:void 0, //市场价
                    sale_price:void 0, //销售价
                    scores_inventory:void 0, //得分率
                    score:void 0, //分值
                }
                this.good_data.push(obj)
            },
            //删除
            deleteBtn(index){
                this.$confirm(`是否删除该商品，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    this.good_data.splice(index,1);
                    this.goodsChooseList = []
                    this.good_data.forEach(item=>{
                        if(item.good_id){
                            this.goodsChooseList.push(item.good_id)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //保存
            saveInventory() {
                let res = this.verificationData()
                if(!res){
                    return
                }
                let isSave = true;
                for (let i = 0; i < this.good_data.length; i++) {
                    let item = this.good_data[i]
                    if (item.sale_price > item.market_price) {
                        isSave = false;
                        this.$message.warning('销售价不能大于商品市场价')
                        break;
                    }
                    if(!item.scores_inventory){
                        this.$message.warning('请添加得分库存！');
                        this.$refs[`scores_inventory_${i}`][0].$el.classList.add('isError')
                        isSave = false
                        break
                    } else {
                        this.$refs[`scores_inventory_${i}`][0].$el.classList.remove('isError')
                    }
                    if(!item.score){
                        this.$message.warning('请添加分值！');
                        this.$refs[`score_${i}`][0].$el.classList.add('isError')
                        isSave = false
                        break
                    } else {
                        this.$refs[`score_${i}`][0].$el.classList.remove('isError')
                    }
                }
                if (isSave) {
                    let param = {
                        op_name: this.practiceForm.name,
                        exam_module_id: this.practiceForm.module_level_three_value,
                        category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                        assess_time: this.practiceForm.time,
                        task_goal: this.practiceForm.taskList,
                        task_background: this.practiceForm.taskBackground,
                        task_analysis: this.practiceForm.taskAnalysis,
                        task_operation: this.practiceForm.taskOperation,
                        scoring_criteria: this.practiceForm.scoreList,
                        fans_name_id:this.fansId,
                        fans_special_name:this.fansName,
                        goods_class_id:this.category_id[this.category_id.length - 1],
                        deliver_address:this.addressList,
                        goods_management:this.good_data,
                    };
                    if (this.$route.query.id) {
                        param.id = this.$route.query.id;
                    }
                    transactionAdminRepertoryCreate(param)
                        .then((res) => {
                            this.$message.success(res.msg);
                            this.$router.push("/practice/index");
                        })
                        .catch((err) => {
                            console.error("err", err);
                        });
                }
            },
            verificationData(){
                if (!this.practiceForm.name) {
                    this.$message.warning('请填写名称！');
                    return false
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return false
                }
                if (this.practiceForm.taskList.length === 0) {
                    this.$message.warning('请添加任务目标！');
                    return false
                }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写任务背景！');
                    return false
                }
                if (!this.practiceForm.taskAnalysis) {
                    this.$message.warning('请填写任务分析！');
                    return false
                }
                if (!this.practiceForm.taskOperation) {
                    this.$message.warning('请填写任务操作！');
                    return false
                }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return false
                }
                if (!this.fansName) {
                    this.$message.warning('请选择粉丝特征名称！');
                    return false
                }
                if (!this.category_id) {
                    this.$message.warning('请选择商品类目！');
                    return false
                }
                if(this.addressList.length == 0){
                    this.$message.warning('请添加发货地址管理！');
                    return false
                }
                if(this.good_data.length == 0){
                    this.$message.warning('请添加在售商品！');
                    return false
                }
                return true
            },
            // 筛选商品 去掉已经选择的
            selectGoodsChange(id,index){
                for (let i = 0; i < this.goodsList.length; i++){
                    if(this.goodsList[i].goods_id === id){
                        this.good_data[index].total_inventory=this.goodsList[i].stock
                        this.good_data[index].market_price=this.goodsList[i].market_price
                        this.good_data[index].sale_price=this.goodsList[i].sales_price
                    }
                }
                this.goodsChooseList = []
                this.good_data.forEach(item=>{
                    if(item.good_id){
                        this.goodsChooseList.push(item.good_id)
                    }
                })
            },
            isSelectGoods(id){
                if(this.goodsChooseList.includes(id)){
                    return false
                } else {
                    return true
                }
            },
            // 销售价不能大于市场价
            setSale(item){
                if(item.sale_price > item.market_price){
                    this.$message({
                        type:'error',
                        message:'销售价不能大于商品市场价'
                    })
                }
            },
            // 重置表单
            resetForm(){
                this.$refs.addressForm.resetFields();
                this.addressForm = this.$options.data().addressForm;
            },
            cancel(){
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .inventory-management{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 30px;
        .title-info {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
            .optimize-title-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .optimize-title01 {
                    font-size: 16px;
                    color: #333;
                    position: relative;
                    padding-left: 16px;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 16px;
                        background: #2DC079;
                        left: 0;
                        top: 4px;
                    }
                }
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
            }
            .goods-table{
                border: 1px solid #EEEEEE;
                box-sizing: border-box;
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                .goods-table-header{
                    padding: 0 30px;
                    display: flex;
                    background: #EEEEEE;
                    height: 60px;
                    line-height: 60px;
                    box-sizing: border-box;
                    span{
                        color: #666666;
                        font-size: 16px;
                    }
                    .table-header-item{
                        text-align: center;
                        flex: 1;
                        width: 1%;
                        &:first-of-type{
                            text-align: left;
                        }
                    }
                }
                .goods-table-body{
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    .table-body-item{
                        display: flex;
                        flex-direction: column;
                        .body-item-top{
                            padding: 0 30px;
                            box-sizing: border-box;
                            width: 100%;
                            display: flex;
                            border-bottom: 1px solid #EEEEEE;
                            .item-top-item, .item-bottom-item{
                                width: 1%;
                                flex: 1;
                                height: 70px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                ::v-deep .el-date-editor{
                                    .el-input__inner{
                                        padding: 0 10px;
                                    }
                                    .el-input__prefix{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .emil-num{
            ::v-deep .el-input-number {
                .el-input__inner {
                    text-align: left;
                }
            }
        }
        .dialog-footer {
            margin-top: 20px;
            text-align: center;
        }
    }
</style>
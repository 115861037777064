<template>
    <div class="community-promotion">
        <div class="">
            <!-- <div class="title-info">
                <div class="optimize-title">群信息设置</div>
                <div class="item">
                    <span class="text">微信群名称</span>
                    <el-input v-model="communityPromotionForm.wx_group_name" placeholder="请输入群名称" style="width: 360px"></el-input>
                </div>
                <div class="item">
                    <span class="text">微信群人数</span>
                    <el-input-number v-model="communityPromotionForm.wx_group_people_count" :controls="false" placeholder="请输入微信群人数"  :min="1"
                                     :max="999999999" style="width: 360px"></el-input-number>
                </div>
                <div class="item">
                    <span class="text">群成员名称</span>
                    <el-input v-model="communityPromotionForm.wx_group_member_name" placeholder="请输入群成员名称" style="width: 360px"></el-input>
                </div>
            </div> -->
            <div class="title-info">
                <div class="optimize-title">分值设置</div>
                <div class="item">
                    <span class="text">群聊名称</span>
                    <el-input v-model="communityPromotionForm.group_chat_name" placeholder="请输入群聊名称"  maxlength="15"
                              show-word-limit style="width: 360px; margin-right: 10px"></el-input>
                    分值
                    <el-input-number
                            v-model="communityPromotionForm.group_chat_name_score"
                            :precision="1"
                            :min="0.1"
                            :max="100"
                            :controls="false"
                            style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">备注</span>
                    <el-input v-model="communityPromotionForm.mark" placeholder="请输入备注"  maxlength="15"
                              show-word-limit style="width: 360px; margin-right: 10px"></el-input>
                    分值
                    <el-input-number
                            v-model="communityPromotionForm.mark_score"
                            :precision="1"
                            :min="0.1"
                            :max="100"
                            :controls="false"
                            style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text"  style="vertical-align: top">公告</span>
                    <el-input type="textarea" v-model="communityPromotionForm.group_notice" placeholder="请输入群公告"  maxlength="2000"
                              show-word-limit style="width: 360px; margin-right: 10px;vertical-align: middle"></el-input>
                    分值
                    <el-input-number
                            v-model="communityPromotionForm.group_notice_score"
                            :precision="1"
                            :min="0.1"
                            :max="100"
                            :controls="false"
                            style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="cancel">取消</el-button>
            <el-button size="medium" type="primary" @click="savePromotion()">保存</el-button>
        </div>
    </div>
</template>

<script>
    import {grouppromotionSetting,grouppromotionGetTrainInfo} from '@/utils/apis'
    export default {
        name: "CommunityPromotion",
        props: ['practiceForm'],
        data(){
            return{
                communityPromotionForm:{
                    op_id:'',
                    // wx_group_name:'',//微信群名称
                    // wx_group_people_count:void 0,//微信群人数
                    // wx_group_member_name:'',//群成员名称
                    group_chat_name:'',//群聊名称
                    group_chat_name_score:void 0,//群聊名称分值
                    mark:'',//备注
                    mark_score:void 0,//备注分值
                    group_notice:'',//群公告
                    group_notice_score:void 0,//群公告分值
                },
            }
        },
        mounted() {
            if (this.$route.query.id){
                this.getOperationsOperationsSetting()
            }
        },
        methods:{
            //编辑
            getOperationsOperationsSetting(){
                let param = {
                    op_id: this.$route.query.id
                }
                grouppromotionGetTrainInfo(param).then((res) => {
                    // this.communityPromotionForm.wx_group_name = res.data.wx_group_name;
                    // this.communityPromotionForm.wx_group_people_count = res.data.wx_group_people_count;
                    // this.communityPromotionForm.wx_group_member_name = res.data.wx_group_member_name;
                    this.communityPromotionForm.group_chat_name = res.data.group_chat_name;
                    this.communityPromotionForm.group_chat_name_score = res.data.group_chat_name_score;
                    this.communityPromotionForm.mark = res.data.mark;
                    this.communityPromotionForm.mark_score = res.data.mark_score;
                    this.communityPromotionForm.group_notice = res.data.group_notice;
                    this.communityPromotionForm.group_notice_score = res.data.group_notice_score;
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 保存
            savePromotion(){
                let res = this.verificationData()
                if(!res){
                    return
                }
                let param = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    group_data:this.communityPromotionForm,
                }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                }
                grouppromotionSetting(param).then((res) => {
                   if(res.code==200){
                    this.$message.success(res.msg)
                    this.$router.push('/practice/index')
                   }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //数据验证
            verificationData(){
                if (!this.practiceForm.name) {
                    this.$message.warning('请填写名称！');
                    return false
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return false
                }
                if (this.practiceForm.taskList.length === 0) {
                    this.$message.warning('请添加任务目标！');
                    return false
                }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写操作要求！');
                    return false
                }
                if (!this.practiceForm.taskAnalysis) {
                    this.$message.warning('请填写任务分析！');
                    return false
                }
                if (!this.practiceForm.taskOperation) {
                    this.$message.warning('请填写任务操作！');
                    return false
                }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return false
                }
                // if(!this.communityPromotionForm.wx_group_name){
                //     this.$message({type: 'warning', message: '请输入微信群名称！'});
                //     return false
                // }
                // if(!this.communityPromotionForm.wx_group_people_count){
                //     this.$message({type: 'warning', message: '请输入微信群人数！'});
                //     return false
                // }
                // if(!this.communityPromotionForm.wx_group_member_name){
                //     this.$message({type: 'warning', message: '请输入群成员名称！'});
                //     return false
                // }
                if(!this.communityPromotionForm.group_chat_name){
                    this.$message({type: 'warning', message: '请输入群聊名称！'});
                    return false
                }
                if(!this.communityPromotionForm.group_chat_name_score){
                    this.$message({type: 'warning', message: '请输入群聊名称分值！'});
                    return false
                }
                if(!this.communityPromotionForm.mark){
                    this.$message({type: 'warning', message: '请输入备注！'});
                    return false
                }
                if(!this.communityPromotionForm.mark_score){
                    this.$message({type: 'warning', message: '请输入备注分值！'});
                    return false
                }
                if(!this.communityPromotionForm.group_notice){
                    this.$message({type: 'warning', message: '请输入公告！'});
                    return false
                }
                if(!this.communityPromotionForm.group_notice_score){
                    this.$message({type: 'warning', message: '请输入公告分值！'});
                    return false
                }
                return true
            },
            //取消
            cancel(){
                this.$router.push({
                    path: '/practice/index'
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .community-promotion{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 30px;
        .title-info {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
            }
            ::v-deep .el-textarea__inner{
                height: 120px;
                resize: none
            }
            ::v-deep .el-input-number {
                .el-input__inner {
                    text-align: left;
                }
            }
        }
        .dialog-footer{
            text-align: center;
            margin-top: 30px;
        }
    }
</style>
<template>
    <div class="order-manage">
       <template v-if="module_id==91">
        <div class="title-info">
            <div class="optimize-title-top">
                <div class="optimize-title01">评价列表</div>
                <el-button type="primary" @click="addGoodsMeal('添加商品')">添加商品</el-button>
            </div>
        </div>
        <el-table :data="comment_content.slice((goods_page_config.page - 1) *goods_page_config.page_size, goods_page_config.page * goods_page_config.page_size)" style="width: 100%; flex: 1" size="medium" border
            :header-cell-style="{ fontWeight: 'normal', color: '#666', background: '#F6F6F6', fontSize: '16px', height: '60px' }"
            :cell-style="{ fontSize: '12px', color: '#333', height: '70px', }">
            <el-table-column prop="good_title" label="评价商品" width="160">
                <!-- <template slot-scope="scope">{{ scope.row.good_title }}</template> -->
            </el-table-column>
            <el-table-column prop="grade" label="评价星级" width="120">
                <template slot-scope="scope">
  <el-select v-model="scope.row.grade" placeholder="请选择">
    <el-option
      v-for="item in startOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.grade)"
      >
    </el-option>
  </el-select>
</template>
            </el-table-column>
            <el-table-column prop="content" label="买家评价内容">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.content"
                     placeholder="请输入内容"
                     :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.content)"
              :maxlength="500"
                     ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="image" label="买家评价图片" width="300">
                <template slot-scope="scope" >
                   <div class="imgBox" >
                    <div class="goods-img"   v-for="(item,index) in scope.row.image">
                        <div class="src-box-evimg" >
                           
                            <el-upload  action="operationProblem/uploadCommentImage"
                        :headers="headersParams" name="file" accept=".jpg, .jpeg, .png" :show-file-list="false"
                        :on-success="evaluateHandleCoverSuccess" :before-upload="evaluateBeforeCoverUpload">
                            <img class="evimg" v-if="scope.row.image[index]" style="margin-top: 8px;" :src="scope.row.image[index]" @click="getScopedRow(scope.$index,index)" alt="">
                            <img class="evimg-upload" v-else style="margin-top: 5px;" src="@/assets/images/admin/upload.png" @click="getScopedRow(scope.$index,index)" alt="">
                    </el-upload>
                        </div>
                        <i v-if="scope.row.image[index]" @click="clearImg1(scope.$index,index)" class="el-icon-error clear-good-img"></i>
                    </div>
                   </div>


                </template>
            </el-table-column>
            <el-table-column prop="right_keywords" label="正确关键词">
                <template slot-scope="scope">
  <el-input v-model="scope.row.right_keywords"
                     placeholder="请输入正确关键词，多个用#分割"
                     :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              :maxlength="200"
              class="input-error"
              @blur="saveItem(scope.row.right_keywords)"
                     ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="wrong_keywords" label="错误关键词">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.wrong_keywords"
                     placeholder="请输入正确关键词，多个用#分割"
                     :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              :maxlength="200"
              @blur="saveItem(scope.row.wrong_keywords)"
                     ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="分值" width="100">
                <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.score"
              :min="0.5"
              :max="100"
              :controls="false"
              :precision="1"
              :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.score)"
              placeholder="请输入"
              pl
            >
            </el-input-number>
          </template>
            </el-table-column>
            <el-table-column  label="操作" width="70">
                <template slot-scope="scope">
            <el-link
              type="danger"
              :underline="false"
              @click.native.prevent="deleteRow(scope.$index, scope.row)"
              >删除</el-link
            >
          </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
      @current-change="goodsPageChange"
      :current-page.sync="goods_page_config.page"
      :page-size="goods_page_config.page_size"
      layout="prev, pager, next, jumper"
      :total="comment_content.length">
        </el-pagination>
        </div>
       </template>

        <div class="title-info">
            <div class="optimize-title-top">
                <div class="optimize-title01">网店订单管理</div>
                <el-button type="primary" @click="addOrderBtn()">添加订单</el-button>
            </div>
        </div>
        <el-table :data="orderManagementContent.slice((answer_page_config.page - 1) * answer_page_config.page_size, answer_page_config.page * answer_page_config.page_size)" style="width: 100%; flex: 1" size="medium" border
            :header-cell-style="{ fontWeight: 'normal', color: '#666', background: '#F6F6F6', fontSize: '16px', height: '60px' }"
            :cell-style="{ fontSize: '12px', color: '#333', height: '70px' }">
            <el-table-column prop="order_no" label="订单编号"></el-table-column>
            <el-table-column label="发货信息分值" align="center">
                <template slot-scope="scope">
                         <span>{{ scope.row.delivery_information_score }}</span>
                </template>
            </el-table-column>
            <el-table-column label="物流公司分值" align="center">
                <template slot-scope="scope">
                         <span>{{ scope.row.delivery_mode_score }}</span>
                </template>
            </el-table-column>
            <el-table-column label="物流单号分值" align="center">
                <template slot-scope="scope">
                         <span>{{ scope.row.logistics_number_score }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="220px">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="editGoods(scope.row, scope.$index)">编辑
                        </el-link>
                        <el-link type="danger" :underline="false" @click="deleteGoods(scope.$index,scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../../assets/images/admin/null-order-data.png" alt="">
                        <span>暂无订单</span>
                    </div>
                </div>
            </template>
        </el-table>
        <div class="pagination">
            <el-pagination
      @current-change="answerPageChange"
      :current-page.sync="answer_page_config.page"
      :page-size="answer_page_config.page_size"
      layout="prev, pager, next, jumper"
      :total="orderManagementContent.length">
    </el-pagination>
        </div>
        
        <div class="upload-content" style="margin-top: 40px;">
            <material-upload @change="finishUpload" :url.sync="fileUrl" :name="fileName" @setname="setFileName"></material-upload>
            <!-- <div class="title-info">
            <div class="optimize-title-top">
                <div class="optimize-title01">上传素材</div>
            </div>
            <el-upload
          action="upload/material"
          :data="{ type: 1 }"
          :headers="headerParam"
          :on-success="handleCoverSuccessAnswer1"
          :on-error="imageError"
          :before-upload="beforImg"
          :show-file-list="false"
        >
          <el-button type="primary" plain>点击上传</el-button>
        </el-upload>
        </div> -->
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="goBack">取消</el-button>
            <el-button size="medium" type="primary" @click="saveOrder()">保存</el-button>
        </div>
        <!--订单弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="dialogTitle"
            custom-class="green-dialog02" :visible.sync="orderDialogVisible" @close="resetForm()" width="35%">
            <el-form :model="addOrderForm" ref="addOrderForm" :rules="addOrderRules" :label-width="formLabelWidth"
                label-position="right" :hide-required-asterisk="true">
                <el-form-item label="商品主图" prop="img">
                    <span class="school-prompt">(尺寸要求800*800的png、jpg和jpeg的图片)</span>
                    <div class="goods-img-box" v-if="addOrderForm.file">
                        <div class="src-box">
                            <img :src="addOrderForm.file" alt="">
                        </div>
                        <i @click="clearImg()" class="el-icon-error clear-good-img"></i>
                    </div>
                    <el-upload class="goods-uploader" v-else action="operationProblem/uploadGoodsImg"
                        :headers="headersParams" name="file" accept=".jpg, .jpeg, .png" :show-file-list="false"
                        :on-success="handleCoverSuccess" :before-upload="beforeCoverUpload">
                        <i class="el-icon-plus goods-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="标题" :label-width="formLabelWidth" prop="goods_title">
                    <el-input v-model="addOrderForm.goods_title" autocomplete="off" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="订单编号" prop="order_no">
                    <template slot-scope="scope">
                        <div style="display: flex;justify-content: space-between">
                            <el-input-number v-model="addOrderForm.order_no" :precision="0" :controls="false"
                                placeholder="请输入订单编号" style="width: 354px;margin-right: 10px"></el-input-number>
                            <el-button type="primary" plain @click="randomGeneration()">随机生成</el-button>
                        </div>
                    </template>
                </el-form-item>
                <el-form-item label="单价" prop="unit_price">
                    <el-input-number v-model="addOrderForm.unit_price" :precision="0" :min="0" :controls="false"
                        placeholder="请输入单价" style="width: 380px"></el-input-number>
                </el-form-item>
                <el-form-item label="数量" prop="number">
                    <el-input-number v-model="addOrderForm.number" :precision="0" :min="0" :controls="false"
                        placeholder="请输入数量" style="width: 380px"></el-input-number>
                </el-form-item>
                <el-form-item label="运费" prop="number">
                    <el-input-number v-model="addOrderForm.freight" :precision="0" :min="0" :controls="false"
                        placeholder="请输入数量" style="width: 380px"></el-input-number>
                </el-form-item>
                <el-form-item label="支付金额" prop="pay_amount">
                    <el-input-number v-model="addOrderForm.pay_amount" :precision="0" :min="0" :controls="false"
                        placeholder="请输入支付金额" style="width: 380px"></el-input-number>
                </el-form-item>
                <div style="display: flex;">
                    <el-form-item label="发货信息" prop="delivery_information">
                        <el-input type="textarea" placeholder="请填写发货信息" autosize :rows="2" :maxlength="150"
                            style="width: 180px;" v-model="addOrderForm.delivery_information"></el-input>
                    </el-form-item>
                    <el-form-item label="分值" prop="delivery_information_score">
                        <el-input-number v-model="addOrderForm.delivery_information_score" :precision="1" :min="0.5"
                            :max="100" :controls="false" style="width: 100px"></el-input-number>
                        <span style="margin-left: 10px;">分</span>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="物流公司" prop="delivery_mode">
                        <el-select v-model="addOrderForm.delivery_mode" placeholder="选择物流公司" clearable
                            style="width: 180px">
                            <el-option v-for="item in deliveryModeList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分值" prop="delivery_mode_score">
                        <el-input-number v-model="addOrderForm.delivery_mode_score" :precision="1" :min="0.5" :max="100"
                            :controls="false" style="width: 100px"></el-input-number>
                        <span style="margin-left: 10px;">分</span>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="物流单号" prop="logistics_number">
                        <el-input placeholder="请输入物流单号" v-model="addOrderForm.logistics_number" style="width: 180px">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="分值" prop="logistics_number_score">
                        <el-input-number v-model="addOrderForm.logistics_number_score" :precision="1" :min="0.5"
                            :max="100" :controls="false" style="width: 100px"></el-input-number>
                        <span style="margin-left: 10px;">分</span>
                    </el-form-item>
                </div>
                <!-- <el-form-item label="订单状态" prop="order_status_id">
                    <el-select v-model="addOrderForm.order_status_id" placeholder="选择订单状态"  clearable style="width: 380px" @change="changeOrder">
                        <el-option
                                v-for="item in orderStatusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="得分操作"  prop="scoring_operation">
                    <el-select v-model="addOrderForm.scoring_operation" placeholder="选择得分操作"  clearable style="width: 380px" @change="changeScoringOperation">
                        <el-option
                                v-for="item in scoringOperationList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="初始物流" prop="logistics"
                              v-if="addOrderForm.order_status_id===1&&addOrderForm.scoring_operation==='出库'||
                              addOrderForm.order_status_id===2&&addOrderForm.scoring_operation==='修改快递单号'||
                              addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退货审核（通过）'||
                               addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退款审核（通过）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退货审核（通过）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（通过）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（通过）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（通过）'">
                    <el-select v-model="addOrderForm.logistics" placeholder="选择初始物流"  clearable style="width: 380px">
                        <el-option
                                v-for="item in logisticsList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="初始单号"  prop="odd_numbers"
                              v-if="addOrderForm.order_status_id===2&&addOrderForm.scoring_operation==='修改快递单号'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退货审核（通过）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（通过）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（通过）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（通过）'">
                    <el-input-number v-model="addOrderForm.odd_numbers" :precision="0" :controls="false" placeholder="请输入初始单号" style="width: 380px"></el-input-number>
                </el-form-item>
                <el-form-item label="出库方式" prop="delivery_mode"
                              v-if="addOrderForm.order_status_id===1&&addOrderForm.scoring_operation==='出库'||
                              addOrderForm.order_status_id===2&&addOrderForm.scoring_operation==='修改快递单号'">
                    <el-select v-model="addOrderForm.delivery_mode" placeholder="选择出库方式"  clearable style="width: 380px">
                        <el-option
                                v-for="item in deliveryModeList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物流单号" prop="logistics_number"
                              v-if="addOrderForm.order_status_id===1&&addOrderForm.scoring_operation==='出库'||
                              addOrderForm.order_status_id===2&&addOrderForm.scoring_operation==='修改快递单号'">
                    <el-input-number v-model="addOrderForm.logistics_number" :controls="false" style="width: 380px"></el-input-number>
                </el-form-item>
                <el-form-item label="出库情况"  prop="delivery_status"
                              v-if="addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退款审核（通过）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（通过）'">
                    <el-select v-model="addOrderForm.delivery_status"  clearable placeholder="选择出库情况" style="width: 380px">
                        <el-option
                                v-for="item in operationList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核原因"  prop="audit_reasons"
                              v-if="addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（通过）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（通过）'">
                    <el-select v-model="addOrderForm.audit_reasons"  clearable placeholder="选择审核原因" style="width: 380px">
                        <el-option
                                v-for="item in auditReasonsList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="驳回原因"  prop="reasons_rejection"
                              v-if="addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===3&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='换货审核（驳回）'||
                              addOrderForm.order_status_id===4&&addOrderForm.scoring_operation==='退款审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='退货审核（驳回）'||
                              addOrderForm.order_status_id===5&&addOrderForm.scoring_operation==='换货审核（驳回）'">
                    <el-select v-model="addOrderForm.reasons_rejection" clearable placeholder="选择驳回原因" style="width: 380px">
                        <el-option
                                v-for="item in reasonsRejectionList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分值"  prop="score">
                    <el-input-number v-model="addOrderForm.score" :min="0.1" :max="100" :precision="1" :controls="false" style="width: 380px"></el-input-number>
                </el-form-item> -->
            </el-form>
            <div class="dialog-footer">
                <el-button @click="orderDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="confirmForm('addOrderForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加商品的弹框 -->
        <el-dialog :title="dialog_title" :close-on-click-modal="false" :visible.sync="dialog_val" width="650px" @close="closeDialog()" custom-class="green-dialog add-task-dialog assign-goods-dialog">
      <div class="assign-search">
        <span class="text">选择分类</span>
        <el-cascader v-model="category_val" :options="category_list" :props="key_option" @change="selectCategory" clearable class="practice-cascader"> </el-cascader>
      </div>
      <el-scrollbar class="upload-file-content">
        <el-checkbox-group v-model="checked_goods" class="goods-checkbox-group">
          <el-checkbox :label="item.id" v-for="item in all_goods_list" class="goods-checkbox" :disabled="dialog_title === '添加套餐' || dialog_title === '编辑套餐' ? selected_goods.answer_val.includes(item.id) : selected_goods.goods_val.includes(item.id)">{{ item.good_title }}</el-checkbox>
        </el-checkbox-group>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="sureGoods()">确 定</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import materialUpload from '../../../../util/materialUpload.vue'
import { operationProblemSaveOrderManagement, operationProblemOrderStatus, operationProblemGetOperation, newOperationProblemGetOperation, operationProblemOpDetail } from '@/utils/apis'
import { adminGoodsList, adminCategoryList, operateProductService, opDetail } from "@/utils/productService";
export default {
    name: "OrderManage",
    props: ['practiceForm',"module_id"],
    components: {
        materialUpload
    },
    data() {
        return {
            headerParam: {
        Authorization: localStorage.getItem("adminToken") || "",
      },
      hosturl: localStorage.getItem("hosturl"),
            fileUrl: '',
            fileName: '',
            orderTable: [],
            orderTableIndex: [],
            formLabelWidth: '100px',
            dialogTitle: '',//弹窗标题
            orderDialogVisible: false,//弹窗
            addOrderForm: {
                file: '',
                goods_img: '',
                goods_title: '',
                order_no: void 0,
                unit_price: void 0,

                logistics_number: void 0,

                number: void 0,
                pay_amount: void 0,

                order_status_id: '',

                order_status_name: '',

                scoring_operation: '',
                logistics: '',
                odd_numbers: void 0,
                delivery_mode: '',
                delivery_status: '',
                reasons_rejection: '',
                audit_reasons: '',
                score: void 0,

                // new
                freight: void 0,//运费
                delivery_information: '',//发货信息
                delivery_information_score: void 0,//发货信息分数
                delivery_mode_score: void 0,//物流公司分数
                logistics_number_score: void 0, //物流单号分数
            },
            orderStatusList: [],
            scoringOperationList: [],//得分操作
            logisticsList: [],//初始物流
            deliveryModeList: [],//出库方式
            operationList: [],//出库情况
            reasonsRejectionList: [],//驳回原因
            auditReasonsList: [],//驳回原因
            orderManagementContent: [],//订单管理内容
            addOrderRules: {
                goods_img: { required: true, message: '请上传商品主图', trigger: 'change' },
                goods_title: { required: true, message: '请输入商品标题', trigger: 'blur' },
                order_no: { required: true, message: '请输入订单编号', trigger: ['blur', 'change'] },
                unit_price: { required: true, message: '请输入单价', trigger: 'blur' },
                logistics_number: { required: true, message: '请输入物流单号', trigger: 'blur' },
                number: { required: true, message: '请输入数量', trigger: 'blur' },
                pay_amount: { required: true, message: '请输入实付金额', trigger: 'blur' },
                // order_status_id: [{ required: true, message: '请选择订单状态', trigger: 'change' }],
                // scoring_operation: [{ required: true, message: '请选择得分操作', trigger: 'change' }],
                // logistics: [{ required: true, message: '请选择初始物流', trigger: 'change' }],
                // odd_numbers: { required: true, message: '请输入初始单号', trigger: 'blur' },
                delivery_mode: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
                operation: [{ required: true, message: '请选择出库情况', trigger: 'change' }],
                // score: { required: true, message: '请输入分值', trigger: 'blur' },
                delivery_information_score: [{ required: true, message: '请输入发货信息分值', trigger: 'blur' }],
                delivery_mode_score: [{ required: true, message: '请输入物流公司分值', trigger: 'blur' }],
                logistics_number_score: [{ required: true, message: '请输入物流单号分值', trigger: 'blur' }],
                delivery_information: [{ required: true, message: '请输入发货信息', trigger: 'blur' }]

            },
            headersParams: {
                Authorization: localStorage.getItem('adminToken') || ''
            },
            // headerSrc:null,
            // hostUrl:localStorage.getItem('hosturl') || '',
            comment_content:[
               
            ],
            startOptions:[
                {
                value: 5,
                label: '5星'
            },  {
                value: 4,
                label: '4星'
            },  {
                value: 3,
                label: '3星'
            },  {
                value: 2,
                label: '2星'
            },  {
                value: 1,
                label: '1星'
            },
        ],
        scopeRowIndex:0,
        imgsIndex:0,
        dialog_title:'添加商品',
        key_option: {
        label: "name",
        value: "id",
        children: "children",
      },
      goods_table: {
        goods_list: [],
        answer_list: [],
      },
      goods_page_config: {
        page: 1,
        page_size: 5,
      },
      answer_page_config: {
        page: 1,
        page_size: 5,
      },
      category_val: void 0,
      category_list: [],
      all_goods_list: [],
      selected_goods: {
        goods_val: [],
        answer_val: [],
      },
      checked_goods: [],
      dialog_val: false,
      current_goods_id: void 0,
      current_parent_id: void 0,
      uploadFile:{
            name:'',
            url:""
        }
      
        }
       

    },
    computed: {
        logistics: function () {
            return this.module_id
        }
    },
    watch: {
        logistics:function (val) {
            this.getOperation()
        }
    },
    mounted() {
        this.getOrderStatus()
        this.getOperation()
       this.getCategorysList()
        this.getGoodsList();
        if (this.$route.query.id) {
            this.getPracticeGoodsDetail();
        }
    },
    methods: {
        // 上传素材
        handleCoverSuccessAnswer1(res){
            console.log(res);

        },
        imageError(err) {
      // console.log(err);
    },
    beforImg(file) {
    //   file;
    },
        setFileName(name) {
            this.fileName = name
        },
        finishUpload(val) {
              this.fileUrl = val.url;
              this.fileName = val.name;
        },
        //网店订单
        getPracticeGoodsDetail() {
            let param = {
                id: this.$route.query.id
            }
            operationProblemOpDetail(param).then((res) => {
                this.orderManagementContent = res.data.order_management_content;
                let material_file = res.data.material_file[0];
                this.fileName = material_file.name;
                this.fileUrl = material_file.url;
                this.comment_content=res.data.comment_content
            }).catch((err) => {
                console.log(err);
            })
        },
        //添加订单
        addOrderBtn() {
            this.dialogTitle = '添加订单';
            this.orderDialogVisible = true;
            this.orderTableIndex = -1;
        },
        //编辑
        editGoods(row, index) {
            // console.log(row)
            // console.log(this.answer_page_config.page,this.answer_page_config.page_size,index);
            
           if(this.answer_page_config.page>1){
            index=(this.answer_page_config.page-1)*this.answer_page_config.page_size+index
           }
            
            this.addOrderForm.file = row.goods_img;
            this.addOrderForm.goods_title = row.goods_title;
            this.addOrderForm.order_no = row.order_no;
            this.addOrderForm.unit_price = row.unit_price;
            this.addOrderForm.logistics_number = row.logistics_number;
            this.addOrderForm.number = row.number;
            this.addOrderForm.pay_amount = row.pay_amount;
            // this.addOrderForm.order_status_id= row.order_status;
            this.addOrderForm.order_status_name = row.status_name;
            // this.addOrderForm.scoring_operation= row.scoring_operation;
            // this.addOrderForm.logistics= row.logistics;
            // this.addOrderForm.odd_numbers= row.odd_numbers;
            this.addOrderForm.delivery_mode = row.delivery_mode;
            // this.addOrderForm.delivery_status= row.delivery_status;
            // this.addOrderForm.reasons_rejection= row.reasons_rejection;
            // this.addOrderForm.audit_reasons= row.audit_reasons;
            // this.addOrderForm.score= row.score;
            this.orderTableIndex = index;
            this.addOrderForm.freight = row.freight,
            this.addOrderForm.delivery_information = row.delivery_information,
            this.addOrderForm.delivery_information_score = row.delivery_information_score,
            this.addOrderForm.delivery_mode_score = row.delivery_mode_score,
            this.addOrderForm.logistics_number_score = row.logistics_number_score
            this.dialogTitle = '编辑订单';
            this.orderDialogVisible = true
        },
        // changeOrder(val){
        //   this.addOrderForm.order_status_id =val;
        //     for (let i in this.orderStatusList){
        //         if(this.orderStatusList[i].id===val){
        //             this.addOrderForm.order_status_name = this.orderStatusList[i].name;
        //             break;
        //         }
        //     }
        //   this.addOrderForm.scoring_operation = ""; //修改订单状态之后，需要重新选择得分操作
        //   this.getOperation()
        // },


        changeScoringOperation(val) {
            this.addOrderForm.scoring_operation = val;
            this.getOperation()
        },

        //获取订单状态
        getOrderStatus() {
            operationProblemOrderStatus().then((res) => {
                this.orderStatusList = res.data
                this.$message.success(res.msg);
            }).catch((err) => {
                // console.log(err);
            });
        },
        //获取

        getOperation() {
            let param = {
                status: 1,
                scoring_operation: "出库"
            }
            if(this.module_id == 91 || this.module_id == 67) {
                newOperationProblemGetOperation(param).then((res) => {
                    this.deliveryModeList = res.data.delivery_mode
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                operationProblemGetOperation(param).then((res) => {
                    // this.scoringOperationList = res.data.scoring_operation
                    // this.logisticsList = res.data.logistics
                    this.deliveryModeList = res.data.delivery_mode
                    // this.operationList = res.data.delivery_status
                    // this.reasonsRejectionList = res.data.reasons_rejection
                    // this.auditReasonsList = res.data.audit_reasons
                    // this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                });
            }
        },

        // start 上传图片
        beforeCoverUpload(file) {
            const is2M = file.size / 1024 / 1024 < 10;
            if (!is2M) {
                this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过2MB');
                return false;
            } else {
                const isSize = new Promise((resolve, reject) => {
                    const width = 800;
                    const height = 800;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                    return file;
                }, () => {
                    this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过2MB');
                    return Promise.reject();
                },
                );
                return isSize;
            }
        },
        // 评价图片上传之前
        evaluateBeforeCoverUpload(file) {
            // const is2M = file.size / 1024 / 1024 < 10;
            const maxSize = 10 * 1024 * 1024; // 10MB
            if (file.size>maxSize) {
                this.$message.warning('图片大小不可超过10MB');
                return false;
            } else {
                // const isSize = new Promise((resolve, reject) => {
                //     const width = 800;
                //     const height = 800;
                //     const _URL = window.URL || window.webkitURL;
                //     const img = new Image();
                //     img.onload = () => {
                //         const valid = img.width === width && img.height === height;
                //         valid ? resolve() : reject();
                //     };
                //     img.src = _URL.createObjectURL(file);
                // }).then(() => {
                //     return file;
                // }, () => {
                //     this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过2MB');
                //     return Promise.reject();
                // },
                // );
                // return isSize;
            }
        },
        // 上传图片
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                // this.headerSrc = this.hostUrl + res.data; // 有域名
                this.addOrderForm.file = res.data; //无域名
                this.$message.success('头像上传成功')
            } else {
                this.$message.error('头像上传失败，请稍后再试~')
            }
        },
        getScopedRow(rowIndex,imgIndex){
            console.log(rowIndex,"kkk");
            this.scopeRowIndex=rowIndex;
            this.imgsIndex=imgIndex

        },
        // 上传评价图片
        evaluateHandleCoverSuccess(res, file) {
            if (res.code === 200) {
                // console.log("评价图片上传成功",res);
                // console.log(this.imgsIndex);
                // this.comment_content[this.scopeRowIndex].image.push(res.data)
                // this.comment_content[this.scopeRowIndex].image[this.imgsIndex]=res.data[0]
                // console.log(this.comment_content[this.scopeRowIndex].image);
                if(this.goods_page_config.page>1){
                    const index=this.scopeRowIndex+(this.goods_page_config.page-1)*this.goods_page_config.page_size
                    this.comment_content[index].image.splice(this.imgsIndex,1)
                this.comment_content[index].image.splice(this.imgsIndex,0,res.data[0])
                }else{
                this.comment_content[this.scopeRowIndex].image.splice(this.imgsIndex,1)
                this.comment_content[this.scopeRowIndex].image.splice(this.imgsIndex,0,res.data[0])
                }
               
                // this.headerSrc = this.hostUrl + res.data; // 有域名
                // this.addOrderForm.file = res.data; //无域名
                // console.log(this.comment_content[this.scopeRowIndex].image);
                this.$message.success('图片上传成功')
            } else {
                this.$message.error(res.msg)
            }
        },
        //删除图片
        clearImg() {
            this.addOrderForm.file = '';
        },
        clearImg1(rowIndex,imgIndex){
            // console.log(rowIndex,imgIndex,"pp");
            if(this.goods_page_config.page>1){
                    const index=rowIndex+(this.goods_page_config.page-1)*this.goods_page_config.page_size
                    this.comment_content[index].image.splice(imgIndex,1,"")
                }else{
                    this.comment_content[rowIndex].image.splice(imgIndex,1,"")
                }
            // console.log(this.comment_content[rowIndex],"lll");
           

        },
        //失焦事件
        saveItem(val) {
            if (val === "" || val === null) {
                 this.$message.warning("请填写相关数据");
                return false;
             }
        },
        //随机生成
        randomGeneration() {
            this.addOrderForm.order_no = '';
            for (let i = 0; i < 12; i++) {
                this.addOrderForm.order_no += Math.floor(Math.random() * 10);
            }
        },
        //确定
        confirmForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let ob = {
                        goods_img: this.addOrderForm.file,
                        goods_title: this.addOrderForm.goods_title,
                        order_no: this.addOrderForm.order_no,
                        unit_price: this.addOrderForm.unit_price,

                        logistics_number: this.addOrderForm.logistics_number,

                        number: this.addOrderForm.number,
                        pay_amount: this.addOrderForm.pay_amount,

                        order_status: 1,

                        status_name: this.addOrderForm.order_status_name,


                        scoring_operation: this.addOrderForm.scoring_operation,
                        logistics: this.addOrderForm.logistics,
                        odd_numbers: this.addOrderForm.odd_numbers,
                        delivery_mode: this.addOrderForm.delivery_mode,
                        delivery_status: this.addOrderForm.delivery_status,
                        reasons_rejection: this.addOrderForm.reasons_rejection,
                        audit_reasons: this.addOrderForm.audit_reasons,
                        score: this.addOrderForm.score,


                        freight: this.addOrderForm.freight,
                        delivery_information: this.addOrderForm.delivery_information,
                        delivery_information_score: this.addOrderForm.delivery_information_score,
                        delivery_mode_score: this.addOrderForm.delivery_mode_score,
                        logistics_number_score: this.addOrderForm.logistics_number_score

                    }
                    if (this.orderTableIndex > -1) {
                        //    编辑
                        this.$set(this.orderManagementContent, this.orderTableIndex, ob)
                    } else {
                        //    新增
                        this.orderManagementContent.push(ob);
                    }
                    this.resetForm()
                    this.orderDialogVisible = false;
                    // console.log(this.orderManagementContent)
                } else {
                    return false;
                }
            });
        },
        goBack() {
            this.$router.push('/practice/index')
        },
        saveOrder() {
            let param = {
                op_name: this.practiceForm.name,
                exam_module_id: this.practiceForm.module_level_three_value,
                category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                assess_time: this.practiceForm.time,
                task_goal: this.practiceForm.taskList,
                task_background: this.practiceForm.taskBackground,
                task_analysis: this.practiceForm.taskAnalysis,
                task_operation: this.practiceForm.taskOperation,
                scoring_criteria: this.practiceForm.scoreList,
                order_management_content: this.orderManagementContent,
                comment_content:this.comment_content
            }
            if (this.$route.query.id) {
                param.id = this.$route.query.id;
            }
            if (this.fileName && this.fileUrl) {
                param.material_file = [{name: this.fileName, url: this.fileUrl}]
            }
            operationProblemSaveOrderManagement(param).then((res) => {
                if(res.code==200){
                    this.$router.push('/practice/index')
                    this.$message.success(res.msg)
                }else{
                    // this.$message.warning(res.msg)
                }
               
               
            }).catch((err) => {
                console.error('err', err);
            });
        },
        //删除
        deleteGoods(index,rows) {
            // console.log(rows);order_no

            // this.orderManagementContent.splice(index, 1);
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
        //   this.comment_content.splice(index, 1);
        this.orderManagementContent=this.orderManagementContent.filter(item=>item.order_no!=rows.order_no)

          this.$message({
            type: "success",
            message: "删除成功!",
          });
         
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
  
        },
        // 重置表单
        resetForm() {
            this.$refs.addOrderForm.resetFields();
            this.addOrderForm = this.$options.data().addOrderForm;
        },
         // 删除该条数据
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
        //   this.comment_content.splice(index, 1);
        this.comment_content=this.comment_content.filter(item=>item.goods_id!=rows.goods_id)
            console.log(rows.goods_id);
        this.selected_goods.goods_val = [];
        this.comment_content.map(item=>{
            this.selected_goods.goods_val.push(item.goods_id);
        }) 

          this.$message({
            type: "success",
            message: "删除成功!",
          });

         console.log( this.comment_content,"删除成功！");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
  
        
    },
    // 添加商品弹框
    closeDialog() {
      this.dialog_val = false;
      this.checked_goods = [];
    },
    getCategorysList() {
      adminCategoryList()
        .then((res) => {
          this.category_list = res.data;
          if (this.category_val) {
            this.getGoodsList();
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    sureGoods() {
      if (this.dialog_title === "添加商品" || this.dialog_title === "新增商品套餐") {
        this.selected_goods.goods_val = [...this.selected_goods.goods_val, ...this.checked_goods];
        this.selected_goods.goods_val = Array.from(new Set(this.selected_goods.goods_val));
        if (this.dialog_title === "添加商品") {
          this.checked_goods.map((id) => {
            let sign = this.goods_table.goods_list.find((data) => data.goods_id === id);
            let goods = this.all_goods_list.find((goods) => goods.id === id);
            // if (!sign) this.goods_table.goods_list.push({ goods_id: id, goods_name: goods.good_title, page_view: void 0, click_through_rate: void 0, conversion_rate: void 0, goods_bind: [] });
            if(!sign) this.comment_content.push(
                {
                    good_title:goods.good_title,
                    goods_id:id, 
                    grade:void 0,
                    content: "", //买家评价内容"right_keywords: "很好#提亮", //正确关键词
                    right_keywords:'',
                    wrong_keywords: "", //错误关键词
                    score: void 0, //分值
                    image: ["","","","",""], //买家评价图片
                    goods_bind:[]
                    
                }
            )
          });
        } 
      }
      
      this.closeDialog();
    },
    selectCategory(val) {
      this.category_val = val?.length ? val[val.length - 1] : void 0;
      this.getGoodsList();
    },

    getGoodsList() {
      let params = {
        category_id: this.category_val,
      };
      adminGoodsList(params)
        .then((res) => {
          this.all_goods_list = res.data;
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    addGoodsMeal(title, data) {
      this.dialog_title = title;
      this.dialog_val = true;
      if (data) this.current_goods_id = data.goods_id;
      if (title === "编辑套餐") {
        let res = this.goods_table.answer_list.filter((item) => item.parent_id !== data.parent_id);
        this.selected_goods.answer_val = res.map((item) => item.goods_id);
        this.current_parent_id = data.parent_id;
      } else {
        this.selected_goods.answer_val = this.goods_table.answer_list.map((item) => item.goods_id);
      }
    },
    goodsPageChange(val) {
      this.goods_page_config.page = val;
    },
    answerPageChange(val) {
      this.answer_page_config.page = val;
    },

    },
    
}
</script>

<style scoped lang="scss">
.order-manage {
    .title-info {
        margin-top: 34px;

        .optimize-title-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .optimize-title01 {
                font-size: 16px;
                color: #333;
                position: relative;
                padding-left: 16px;

                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
        }
    }

    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }

    .goods-img-box {
        width: 80px;
        height: 70px;
        box-sizing: border-box;
        position: relative;

        .src-box {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 1px solid #EEEEEE;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .clear-good-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -9px;
            right: -9px;
            z-index: 1;
            font-size: 18px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #ff0000;
            }
        }

    }

    .goods-uploader {
        ::v-deep.el-upload {
            width: 80px;
            height: 80px;
            background: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;

            .goods-uploader-icon {
                font-size: 32px;
                color: #A0A0A0;
            }
        }
    }

    .null-data {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-top: 20px;

        img {
            width: 80px;
        }
    }

    .dialog-footer {
        text-align: center;
        margin-top: 20px;
    }

}
::v-deep .el-table .cell{
    .imgBox{
        width: 100%;
        // height: 60px;
        display: flex !important;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        .goods-img{
          
            position: relative;
           .src-box-evimg{
            // border: 1px solid #DCDFE6 ;
             border-radius: 2px;
              width: 40px; 
              height: 40px; 
              display: flex; 
             justify-content: center; 
            align-items: center;
            margin-top: 2px;
           }
        
           .clear-good-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            right: -4px;
            z-index: 1;
            font-size: 12px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #ff0000;
            }
        }
        }
        .src-box-evimg{
            border: 1px solid #DCDFE6 ;
             border-radius: 2px;
              width: 40px; 
              height: 40px; 
              display: flex; 
             justify-content: center; 
            align-items: center;
           }
        
    }
}
.evimg{
    display: block;
    width: 40px;
    height: 40px;
}
::v-deep .el-checkbox__label {
    position: absolute;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
</style>
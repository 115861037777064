<template>
  <div>
      <div class="line-item" style="margin-top: 30px">
          <div class="left">数据中心名称</div>
          <div class="right">
              <el-select v-model="ruleForm.data_center_id" placeholder="请选择数据中心名称" filterable style="width: 360px">
                  <el-option v-for="(item, index) in dataCenterList" :key="`dataCenterList_${index}`" :label="item.date_center_tpl_name" :value="item.data_center_tpl_id"></el-option>
              </el-select>
          </div>
      </div>
      <div class="line-item">
          <div class="left">分值</div>
          <div class="right">
              <el-input-number class="number-input" v-model="ruleForm.score" :precision="1" :controls="false" :min="0.1" :max="100.0" style="width: 250px"></el-input-number>
              <span style="font-size: 16px; color: #333; margin-left: 10px">分</span>
          </div>
      </div>

      <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="resetForm">取消</el-button>
      </div>
  </div>
</template>

<script>
  import { dataCentorTplGetDataCenterTpl, operationProblemCommerceProductionData, operationProblemGetOneInfo } from '@/utils/apis.js'
  export default {
      props: {
          practiceForm: {
              type: Object,
              default:() => {}
          }
      },
      data() {
          return {
              ruleForm: {
                  data_center_id: null,
                  score: void 0,
              },
              dataCenterList: [], // 数据中心名称列表
          }
      },
      mounted() {
          this.getDataCenterList()
          if (this.$route.query.id) {
              this.initDetail();
          }
      },
      methods: {
          // 编辑获取详情
          initDetail() {
              let params = {
                  id: this.$route.query.id,
                  type: 'commerce',
              }
              operationProblemGetOneInfo(params).then(res => {
                  this.ruleForm = {
                      data_center_id: res.data.detail.data_center_id,
                      score: res.data.op_main.score,
                  }
              }).catch((err) => {})
          },
          getDataCenterList() {
              dataCentorTplGetDataCenterTpl({paging: 0}).then((res) => {
                  this.dataCenterList = res.data.list
              }).catch((err) => {})
          },
          // 保存试题
          submitForm() {
              if (!this.practiceForm.name) {
                  return this.$message.warning('请填写名称！');
              }
              if (!this.practiceForm.time) {
                  return this.$message.warning('请输入考核时间')
              }
              if (this.practiceForm.classValue.length === 0) {
                  return this.$message.warning('请选择分类！');
              }
              if (this.practiceForm.taskList.length === 0) {
                  return this.$message.warning('请添加任务目标！');
              }
              if (!this.practiceForm.taskBackground) {
                  // return this.$message.warning('请填写任务背景！');
                  return this.$message.warning('请填写操作要求！');
              }
              if (!this.practiceForm.taskAnalysis) {
                  return this.$message.warning('请填写任务分析！');
              }
              if (!this.practiceForm.taskOperation) {
                  return this.$message.warning('请填写任务操作！');
              }
              if (this.practiceForm.scoreList.length === 0) {
                  return this.$message.warning('请添加评分标准！');
              }
              if (!this.ruleForm.data_center_id) {
                  return this.$message.warning('请选择数据中心名称！');
              }
              if (!this.ruleForm.score) {
                  return this.$message.warning('请输入分值！');
              }

              let params = {
                  op_name: this.practiceForm.name,
                  exam_module_id: this.practiceForm.module_level_three_value,
                  category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                  assess_time: this.practiceForm.time,
                  task_goal: this.practiceForm.taskList,
                  task_background: this.practiceForm.taskBackground,
                  task_analysis: this.practiceForm.taskAnalysis,
                  task_operation: this.practiceForm.taskOperation,
                  scoring_criteria: this.practiceForm.scoreList,
                  data_center_id: this.ruleForm.data_center_id,
                  score: this.ruleForm.score,
              }
              if (this.$route.query.id) {
                  params.id = Number(this.$route.query.id);
              }

              operationProblemCommerceProductionData(params).then((res) => {
              if(res.code==200){
                this.$message.success(res.msg)
                this.$router.push('/practice/index')
              }
              }).catch((err) => {})
          },
          // 重置试题
          resetForm() {
              this.ruleForm = {
                  data_center_id: null,
                  score: void 0,
              }
              this.$emit('update:practiceForm', {
                  ...this.practiceForm,
                  name: '',
                  time: '',
                  module_level_one_value: '',
                  module_level_tow_value: '',
                  module_level_three_value: '',
                  classValue: [],
                  taskList: [],
                  taskBackground: '',
                  taskAnalysis: '',
                  taskOperation: '',
                  scoreList: [],
              })
          },
      }
  }
</script>

<style scoped lang="scss">
  .line-item {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .left {
          width: 100px;
          margin-right: 10px;
          color: #333;
          font-size: 16px;
          text-align: right;
      }
  }
</style>
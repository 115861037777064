<template>
    <div class="goods-text-content">
        <div class="goods-item">
            <div class="title hot-goods">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">热门商品</span>
                </div>
                <el-button type="primary" @click="distributionGoods" class="distribution-goods">分配商品</el-button>
            </div>
            <div class="content-item" v-for="(item, index) in goodsData" :key="index">
                <div class="goods-name item">
                    <span class="g-text">商品{{ index +1 }}：{{ item.good_title }}</span>
                </div>
                <div class="item">
                    <span class="text">热门关键词</span>
                    <el-select v-model="item.hot_keywords_id" filterable  placeholder="请选择热门关键词" class="practice-select">
                        <el-option
                                v-for="item in hotKeywordsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="key-word">
                    <!-- <div class="search">
                        <span>设置搜索指数关键词数量：</span>
                        <el-input-number
                            v-model="item.search_index"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 50px;">
                        </el-input-number>
                    </div> -->
                    <div class="ranking">
                        <span>搜索指数排名前</span>
                        <el-input-number
                            v-model="item.search_rank"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 58px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                    <div class="score">
                        <span>分值</span>
                        <el-input-number
                            v-model="item.search_score"
                            :min="0.1"
                            :max="99999"
                            :controls="false"
                            :precision="1"
                            class="name-input"
                            style="width: 120px;margin-right: 10px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                </div>
                <div class="key-word">
                    <!-- <div class="search">
                        <span>设置点击指数关键词数据：</span>
                        <el-input-number
                            v-model="item.click_index"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 50px;">
                        </el-input-number>
                    </div> -->
                    <div class="ranking">
                        <span>点击指数排名前</span>
                        <el-input-number
                            v-model="item.click_rank"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 58px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                    <div class="score">
                        <span>分值</span>
                        <el-input-number
                            v-model="item.click_score"
                            :min="0.1"
                            :max="99999"
                            :controls="false"
                            :precision="1"
                            class="name-input"
                            style="width: 120px;margin-right: 10px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                </div>
                <div class="ctr">
                    <!-- <div class="search">
                        <span>设置点击率关键词数据：</span>
                        <el-input-number
                            v-model="item.click_rate_index"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 66px;">
                        </el-input-number>
                    </div> -->
                    <div class="ranking">
                        <span>点击率排名前</span>
                        <el-input-number
                            v-model="item.click_rate_rank"
                            :min="1"
                            :max="100"
                            :controls="false"
                            :precision="0"
                            class="name-input"
                            style="width: 120px;margin-right: 58px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                    <div class="score">
                        <span>分值</span>
                        <el-input-number
                            v-model="item.click_rate_score"
                            :min="0.1"
                            :max="99999"
                            :controls="false"
                            :precision="1"
                            class="name-input"
                            style="width: 120px;margin-right: 10px;margin-left: 4px;">
                        </el-input-number>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="goods-item">
            <div class="title">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">优化标题</span>
                </div>
            </div>
            <div class="content-item">
                <div class="item">
                    <span class="text">搜索人数</span>
                    <el-input-number
                            v-model="searchPeople"
                            :min="1"
                            :max="99999999999"
                            :controls="false"
                            :precision="0"
                            placeholder="请输入搜索人数"
                            class="name-input">
                    </el-input-number>
                </div>
                <div class="item">
                    <span class="text">点击率（%）</span>
                    <el-input-number
                            v-model="clickRate"
                            :min="0.01"
                            :max="100"
                            :controls="false"
                            :precision="2"
                            placeholder="请输入点击率"
                            class="name-input">
                    </el-input-number>
                </div>
                <div class="item">
                    <span class="text">转化率（%）</span>
                    <el-input-number
                            v-model="conversionRate"
                            :min="0.01"
                            :max="100"
                            :controls="false"
                            :precision="2"
                            placeholder="请输入转化率"
                            class="name-input">
                    </el-input-number>
                </div>
                <div class="has-sore">
                    <div class="item">
                        <span class="text">满分点击量</span>
                        <el-input-number
                                v-model="clickNum"
                                :min="1"
                                :max="99999"
                                :controls="false"
                                :precision="0"
                                placeholder="请输入满分点击量"
                                class="name-input">
                        </el-input-number>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="clickNumScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                        style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="goods-item">
            <div class="title">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">商品上架</span>
                </div>
            </div>
            <div class="content-item">
                <div class="has-sore goods-put">
                    <div class="item">
                        <span class="text">商品上架类目</span>
                        <div class="has-btn">
                            <div class="name-input none-data" v-if="selectedGoodsClass === ''">暂无选择类目</div>
                            <div class="name-input" v-else>{{selectedGoodsClass}}</div>
                            <el-button type="primary" @click="selectGoodsClass" class="distribution-goods">选择类目</el-button>
                        </div>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="goodsPutScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
                <div class="has-sore goods-title">
                    <div class="item">
                        <span class="text">商品标题</span>
                        <el-input v-model="goodsTitle" placeholder="请输入商品标题" class="name-input"></el-input>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="goodsTitleScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
                <div class="has-sore goods-title">
                    <div class="item">
                        <span class="text">销售价（元）</span>
                        <el-input-number
                                v-model="goodsPrice"
                                :min="0.01"
                                :max="99999999999"
                                :controls="false"
                                :precision="2"
                                placeholder="请输入销售价"
                                class="name-input">
                        </el-input-number>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="goodsPriceScore"
                                :min="0.1"
                                :max="9999999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
                <div class="has-sore goods-title">
                    <div class="item">
                        <span class="text">市场价（元）</span>
                        <el-input-number
                                v-model="marketPrice"
                                :min="0.02"
                                :max="99999999999"
                                :controls="false"
                                :precision="2"
                                placeholder="请输入市场价"
                                class="name-input">
                        </el-input-number>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="marketPriceScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
                <div class="has-sore goods-title">
                    <div class="item">
                        <span class="text">库存</span>
                        <el-input-number
                                v-model="stock"
                                :min="1"
                                :max="99999"
                                :controls="false"
                                :precision="0"
                                placeholder="请输入库存"
                                class="name-input">
                        </el-input-number>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="stockScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
                <div class="has-sore goods-title">
                    <div class="item">
                        <span class="text">商品货号</span>
                        <el-input-number
                                v-model="productCode"
                                :min="1"
                                :max="99999999999999999999999"
                                :controls="false"
                                :precision="0"
                                placeholder="请输入商品货号"
                                class="name-input">
                        </el-input-number>
                    </div>
                    <div class="item">
                        <span class="text">分值</span>
                        <el-input-number
                                v-model="productCodeScore"
                                :min="0.1"
                                :max="99999"
                                :controls="false"
                                :precision="1"
                                class="name-input"
                                style="width: 120px;margin-right: 10px;">
                        </el-input-number>分
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="goods-item">
            <div class="title">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">商品基本属性</span>
                </div>
                <el-button type="primary" @click="addGoodsAttributes">添加商品基本属性</el-button>
            </div>
            <el-table :data="goodsAttributesList" size="medium"
                      class="content-table"
                      style="border: 1px solid #E5E5E5;border-bottom: none;"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="attribute" align="center" label="属性"></el-table-column>
                <el-table-column prop="content" align="center" label="答案"></el-table-column>
                <el-table-column prop="score" align="center" label="分值"></el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="editGoodsAttributes(scope.row)">编辑</el-link>
                            <el-link type="danger" :underline="false" @click="delGoodsAttributes(scope.row)">删除</el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div> -->
        <!-- <div class="content-item goods-item">
            <div class="title" style="margin-top: 20px;">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">上传素材</span>
                </div>
            </div>
            <div class="item-content">
                <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                       accept="application/zip, .rar" title=""
                       @change="changeFile($event)">
                <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G</span>
            </div>
        </div> -->
        <!-- <div class="content-item">
            <div class="item-content">
                <div class="upload-file-list">
                    <div class="file-item" v-for="(item, index) in materialList">
                        <i class="iconfont">&#xe60b;</i>
                        <span class="file-name" :title="item.name">{{item.name}}</span>
                        <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                        <i class="iconfont finish-icon" v-show="percent === 100">&#xe654;</i>
                        <i class="iconfont del-icon" v-show="percent === 100" @click="delMaterial(item, index)">&#xe651;</i>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="content-item" style="margin-top: 20px;text-align: center;">
            <el-button type="primary" @click="saveGoodsText">保 存</el-button>
            <el-button @click="cancelSave">取 消</el-button>
        </div>
        <el-dialog title="分配商品" :close-on-click-modal="false" :visible.sync="assignGoodsDialog" width="650px" @close="handleCloseAssignGoodsDialog" custom-class="green-dialog add-task-dialog assign-goods-dialog">
            <div class="assign-search">
                <span class="text">选择分类</span>
                <el-cascader
                        v-model="searchGoodsClassValue"
                        :options="goodsClassList"
                        :props="keyObj"
                        @change="searchGoods"
                        clearable
                        class="practice-cascader">
                </el-cascader>
            </div>
            <el-scrollbar class="upload-file-content">
                <el-checkbox-group v-model="checkGoodsList" class="goods-checkbox-group">
                    <el-checkbox :label="item.id" v-for="(item, key) in GoodsList" class="goods-checkbox" :key="key">{{item.good_title}}</el-checkbox>
                </el-checkbox-group>
                <!--<div class="none-goods">暂无商品</div>-->
            </el-scrollbar>
            <span slot="footer" class="dialog-footer">
		        <el-button @click="handleCloseAssignGoodsDialog">取 消</el-button>
		        <el-button type="primary" @click="saveAssignGoods">确 定</el-button>
		    </span>
        </el-dialog>
        <el-dialog title="选择类目" :close-on-click-modal="false" :visible.sync="selectClassDialog" width="650px" @close="handleCloseSelectClassDialog" custom-class="green-dialog add-task-dialog assign-goods-dialog">
            <div class="class-content">
                <el-cascader-panel :options="goodsClassList" :props="keyObj" v-model="goodsClassValue" class="class-cascader"></el-cascader-panel>
            </div>
            <span slot="footer" class="dialog-footer">
		        <el-button @click="handleCloseSelectClassDialog">取 消</el-button>
		        <el-button type="primary" @click="saveSelectClass">确 定</el-button>
		    </span>
        </el-dialog>
        <el-dialog :title="goodsAttributesTitle" :close-on-click-modal="false" :visible.sync="addGoodsAttributesDialog" width="500px" @close="handleCloseGoodsAttributesDialog" custom-class="green-dialog add-task-dialog">
            <div class="upload-file-content">
                <el-form :model="addGoodsAttributesForm" ref="addGoodsAttributesForm" label-width="36px" class="demo-ruleForm">
                    <el-form-item label="属性" prop="attribute">
                        <el-input v-model="addGoodsAttributesForm.attribute"></el-input>
                    </el-form-item>
                    <el-form-item label="答案" prop="content">
                        <el-input v-model="addGoodsAttributesForm.content"></el-input>
                    </el-form-item>
                    <el-form-item label="配分" prop="score">
                        <el-input-number v-model="addGoodsAttributesForm.score"
                                         :min="0.1"
                                         :max="99999"
                                         :controls="false"
                                         :precision="1"
                                         class="input-number">
                        </el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer" style="text-align: center;">
		        <el-button @click="handleCloseGoodsAttributesDialog">取 消</el-button>
		        <el-button type="primary" @click="saveAddGoodsAttributes">确 定</el-button>
		    </span>
        </el-dialog>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {adminKeyWordListKeyWord, operationProblemSaveShopGoodsManagement, adminGoodListGood, adminGoodGetCategory, uploadMaterial, mergeSlice, operationProblemDelMaterial, operationProblemOpDetail} from '@/utils/apis'
    export default {
        name: "GoodsText",
        props: ['practiceForm'],
        data() {
            return {
                //热门关键词
                hotKeywordsList: [],
                hotKeywordsValue: null,
                //搜索人数
                searchPeople: 1,
                //点击率
                clickRate: 0.01,
                //转化率
                conversionRate: 0.01,
                //满分点击量
                clickNum: 1,
                //满分点击量的分值
                clickNumScore: 0.1,
                //商品上架类目
                selectedGoodsClass: '',
                //商品上架类目的分值
                goodsPutScore: 0.1,
                //商品标题
                goodsTitle: '',
                //商品标题的分值
                goodsTitleScore: 0.1,
                //销售价
                goodsPrice: 0.01,
                //销售价的分值
                goodsPriceScore: 0.1,
                //市场价
                marketPrice: 0.02,
                //市场价的分值
                marketPriceScore: 0.1,
                //库存
                stock: 1,
                //库存的分值
                stockScore: 0.1,
                //商品货号
                productCode: 1,
                //商品货号的分值
                productCodeScore: 0.1,
                //商品基本属性列表
                goodsAttributesList: [],
                //添加商品基本属性弹窗
                addGoodsAttributesDialog: false,
                goodsAttributesTitle: '添加商品基本属性',
                //添加商品基本属性表单
                addGoodsAttributesForm: {
                    id: null,
                    attribute: '',
                    content: '',
                    score: null
                },
                //上传素材
                material_file: [],
                //素材列表
                materialList: [],
                //分配商品弹窗
                assignGoodsDialog: false,
                //商品列表
                GoodsList: [],
                checkGoodsList: [],
                allGoodsList: [],
                //根据商品类目搜索商品
                searchGoodsClassValue: [],
                //选择类目弹窗
                selectClassDialog: false,
                //商品类目列表
                goodsClassList: [],
                goodsClassValue: [],
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                //分片长传
                uploadInstance: null,
                //素材进度条
                percent: 0,
                goodsData: [],
            }
        },
        mounted() {
            this.getHotKeywordsList();
            if (this.$route.query.id) {
                this.getPracticeGoodsTextDetail();
            }
        },
        methods: {
            //获取商品文字信息撰写详情
            getPracticeGoodsTextDetail() {
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.hotKeywordsValue = res.data.hot_keywords_id;
                    this.searchPeople = res.data.search_num;
                    this.clickRate = res.data.click_rate;
                    this.conversionRate = res.data.conversion_rate;
                    this.clickNum = res.data.full_score_hits;
                    this.clickNumScore = res.data.full_score_hits_score;
                    this.goodsClassValue = res.data.goods_category_id;
                    if (this.goodsClassValue) {
                        this.getGoodsClassList();
                    }
                    this.goodsPutScore = res.data.goods_category_score;
                    this.goodsTitle = res.data.goods_title;
                    this.goodsTitleScore = res.data.goods_title_score;
                    this.goodsPrice = res.data.sale_price;
                    this.goodsPriceScore = res.data.sale_price_score;
                    this.marketPrice = res.data.market_price;
                    this.marketPriceScore = res.data.market_price_score;
                    this.stock = res.data.stock;
                    this.stockScore = res.data.stock_score;
                    this.productCode = res.data.goods_code;
                    this.productCodeScore = res.data.goods_code_score;
                    this.goodsAttributesList = res.data.goods_attribute;
                    this.materialList = res.data.material_file;
                    this.material_file = res.data.material_file;
                    this.percent = 100;
                    this.goodsData = res.data.goods_data;
                    this.checkGoodsList = res.data.goods_data.map((item) => item.hot_goods_id);
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取热门关键词
            getHotKeywordsList() {
                adminKeyWordListKeyWord().then((res) => {
                    this.hotKeywordsList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //分配商品
            distributionGoods() {
                this.assignGoodsDialog = true;
                this.getGoodsClassList();
                this.getAdminGoodListGood();
                this.getAllGoodsList();
            },
            //获取商品列表
            getAdminGoodListGood() {
                let param = {}
                if (this.searchGoodsClassValue.length > 0) {
                    param.category_id = this.searchGoodsClassValue[this.searchGoodsClassValue.length - 1];
                }
                adminGoodListGood(param).then((res) => {
                    this.GoodsList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            getAllGoodsList() {
                adminGoodListGood({}).then((res) => {
                    this.allGoodsList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //搜索商品
            searchGoods() {
                this.getAdminGoodListGood();
            },
            //关闭分配商品弹窗
            handleCloseAssignGoodsDialog() {
                this.assignGoodsDialog = false;
            },
            //保存商品分配
            saveAssignGoods() {
                this.goodsData = [];
                for (let x = 0; x < this.checkGoodsList.length; x ++) {
                    let obj = {
                        hot_goods_id: '', good_title: '', hot_keywords_id: '',
                        search_index: '', search_rank: '', search_score: '',
                        click_index: '', click_rank: '', click_score: '',
                        click_rate_index: '', click_rate_rank: '', click_rate_score: '',
                    };
                    for (let i = 0; i < this.allGoodsList.length; i ++) {
                        if (this.checkGoodsList[x] == this.allGoodsList[i].id) {
                            obj.hot_goods_id = this.allGoodsList[i].id;
                            obj.good_title = this.allGoodsList[i].good_title;
                        }
                    };
                    this.goodsData.push(obj);
                };
                this.assignGoodsDialog = false;
            },
            //选择类目
            selectGoodsClass(val) {
                this.selectClassDialog = true;
                if (this.goodsClassList.length === 0) {
                    this.getGoodsClassList();
                }
            },
            //获取商品类目
            getGoodsClassList() {
                adminGoodGetCategory().then((res) => {
                    this.goodsClassList = res.data;
                    if (this.goodsClassValue) {
                        this.getGoodsClassName();
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //关闭选择类目弹窗
            handleCloseSelectClassDialog() {
                this.selectClassDialog = false;
            },
            //保存选择类目
            saveSelectClass() {
                if (this.goodsClassValue.length < 3) {
                    this.$message.warning('请选到第三级类目！');
                    return;
                } else {
                    this.selectClassDialog = false;
                    this.getGoodsClassName();
                }
            },
            //拼接生成商品类目
            getGoodsClassName() {
                let one_name = '';
                let two_name = '';
                let three_name = '';
                this.goodsClassList.forEach(itemOne => {
                    if (itemOne.id === this.goodsClassValue[0]) {
                        one_name = itemOne.name + '/';
                        if (itemOne.children && itemOne.children.length > 0) {
                            itemOne.children.forEach(itemTwo => {
                                if (itemTwo.id === this.goodsClassValue[1]) {
                                    two_name = itemTwo.name + '/';
                                    if (itemTwo.children && itemTwo.children.length > 0) {
                                        itemTwo.children.forEach(itemThree => {
                                            if (itemThree.id === this.goodsClassValue[2]) {
                                                three_name = itemThree.name;
                                                this.selectedGoodsClass = one_name + two_name +three_name;
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                });
            },
            //添加商品基本属性
            addGoodsAttributes() {
                this.addGoodsAttributesDialog = true;
            },
            //关闭添加商品基本属性
            handleCloseGoodsAttributesDialog() {
                this.addGoodsAttributesDialog = false;
                this.addGoodsAttributesForm.id = null;
                this.addGoodsAttributesForm.attribute = '';
                this.addGoodsAttributesForm.content = '';
                this.addGoodsAttributesForm.score = null;
            },
            //编辑商品基本属性
            editGoodsAttributes(row) {
                this.goodsAttributesTitle = '编辑商品基本属性';
                this.addGoodsAttributesDialog = true;
                this.addGoodsAttributesForm.id = row.id;
                this.addGoodsAttributesForm.attribute = row.attribute;
                this.addGoodsAttributesForm.content = row.content;
                this.addGoodsAttributesForm.score = row.score;
            },
            //删除商品基本属性
            delGoodsAttributes(row) {
                this.$confirm(`是否删除，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    this.goodsAttributesList.forEach((item, index) => {
                        if (item.id === row.id) {
                            this.goodsAttributesList.splice(index, 1)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
             //保存商品基本属性
            saveAddGoodsAttributes() {
                if (!this.addGoodsAttributesForm.attribute) {
                    this.$message.warning('请填写属性！');
                    return;
                }
                if (!this.addGoodsAttributesForm.content) {
                    this.$message.warning('请填写答案！');
                    return;
                }
                if (this.addGoodsAttributesForm.id) {
                    this.goodsAttributesList.forEach(item => {
                        if (item.id === this.addGoodsAttributesForm.id) {
                            item.id = this.addGoodsAttributesForm.id;
                            item.attribute = this.addGoodsAttributesForm.attribute;
                            item.content = this.addGoodsAttributesForm.content;
                            item.score = this.addGoodsAttributesForm.score;
                        }
                    })
                } else {
                    this.goodsAttributesList.push({
                        id: this.goodsAttributesList.length + 1,
                        attribute: this.addGoodsAttributesForm.attribute,
                        content: this.addGoodsAttributesForm.content,
                        score: this.addGoodsAttributesForm.score
                    });
                }
                this.addGoodsAttributesDialog = false;
            },
            //上传素材
            changeFile(event) {
                if (event.target.files[0].size > 1024 * 1024 * 1024) {
                    this.$message.warning('文件超过1G');
                    return;
                } else {
                    let chunkSize = 1024 * 1024 * 8;
                    let file = event.target.files[0];
                    this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                    let obj = {
                        name: file.name,
                        url: ''
                    }
                    this.materialList = []
                    this.material_file = [];
                    this.materialList.push(obj);
                    this.material_file.push(obj);
                    let index = 0;
                    this.uploadSlice(index);
                    this.percent = 0
                }

            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.material_file.splice(index, 1);
                            this.materialList.splice(index, 1);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //最后的保存
            saveGoodsText() {
                if (!this.practiceForm.name || this.practiceForm.name === '') {
                    this.$message.warning('请填写名称！');
                    return;
                }
                if (this.practiceForm.name.length < 3 || this.practiceForm.name.length > 50) {
                    this.$message.warning('名称必须大于3个字符且小于50个字符！');
                    return;
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return;
                }
                // if (this.practiceForm.taskList.length === 0) {
                //     this.$message.warning('请添加任务目标！');
                //     return;
                // }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写操作要求！');
                    return;
                }
                // if (!this.practiceForm.taskAnalysis) {
                //     this.$message.warning('请填写任务分析！');
                //     return;
                // }
                // if (!this.practiceForm.taskOperation) {
                //     this.$message.warning('请填写任务操作！');
                //     return;
                // }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return;
                }
                if (this.checkGoodsList.length === 0) {
                    this.$message.warning('请选择热门商品！');
                    return;
                }
                // if (!this.hotKeywordsValue) {
                //     this.$message.warning('请选择热门关键词！');
                //     return;
                // }
                for (let x = 0; x < this.goodsData.length; x ++) {
                    if (this.goodsData[x].hot_keywords_id == '') {
                        this.$message.warning('请选择热门关键词！');
                        return;
                    };
                    if (!this.goodsData[x].search_rank) {
                        this.$message.warning('请设置搜索指数排名！');
                        return;
                    };
                    if (!this.goodsData[x].click_rank) {
                        this.$message.warning('请设置点击指数排名！');
                        return;
                    };
                    if (!this.goodsData[x].click_rate_rank) {
                        this.$message.warning('请设置点击率排名！！');
                        return;
                    };
                };
                //#region
                // if (this.goodsClassValue.length === 0) {
                //     this.$message.warning('请选择商品类目！');
                //     return;
                // }
                // if (!this.goodsTitle) {
                //     this.$message.warning('请输入商品标题！');
                //     return;
                // }
                // if (this.goodsTitle.length < 3 || this.goodsTitle.length > 50) {
                //     this.$message.warning('商品标题必须大于3个字符且小于50个字符！');
                //     return;
                // }
                // if (this.goodsPrice >= this.marketPrice) {
                //     this.$message.warning('市场价必须大于销售价！');
                //     return;
                // }
                // if (this.goodsAttributesList.length === 0) {
                //     this.$message.warning({
                //         message: '请添加商品基本属性！',
                //         offset: 60
                //     });
                //     return;
                // }
                // if (this.material_file.length === 0) {
                //     this.$message.warning('请上传素材！');
                //     return;
                // }
                //#endregion

                let param = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    // task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    // task_analysis: this.practiceForm.taskAnalysis,
                    // task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    //#region
                    // hot_keywords_id: this.hotKeywordsValue,
                    // search_num: this.searchPeople,
                    // click_rate: this.clickRate,
                    // conversion_rate: this.conversionRate,
                    // full_score_hits: this.clickNum,
                    // full_score_hits_score: this.clickNumScore,
                    // goods_category_score: this.goodsPutScore,
                    // goods_title: this.goodsTitle,
                    // goods_title_score: this.goodsTitleScore,
                    // sale_price: this.goodsPrice,
                    // sale_price_score: this.goodsPriceScore,
                    // market_price: this.marketPrice,
                    // market_price_score: this.marketPriceScore,
                    // stock: this.stock,
                    // stock_score: this.stockScore,
                    // goods_code: this.productCode,
                    // goods_code_score: this.productCodeScore,
                    // goods_attribute: this.goodsAttributesList,
                    // material_file: this.material_file,
                    //#endregion
                    goods_data: this.goodsData
                }
                // if (this.goodsClassValue.length > 0) {
                //     param.goods_category_id = this.goodsClassValue[this.goodsClassValue.length - 1];
                // }
                // if (this.checkGoodsList.length > 0) {
                //     param.hot_goods_id = this.checkGoodsList;
                // }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                }
                operationProblemSaveShopGoodsManagement(param).then((res) => {
                   if(res.code==200){
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/practice/index'
                    })
                   }
                }).catch((err) => {
                    console.log(err);
                });
            },
            //取消保存
            cancelSave() {
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-text-content {
        margin: 20px 0;
        .goods-item {
            display: flex;
            flex-direction: column;
            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 4px;
                    height: 16px;
                    background-color: #2DC079;
                    margin-right: 6px;
                    border-radius: 2px;
                }
            }
            .hot-goods {
                justify-content: flex-start;
                .distribution-goods {
                    margin-left: 20px;
                }
            }
            .content-item {
                .goods-name {
                    width: 450px;
                    height: 40px;
                    margin-left: 20px;
                    line-height: 40px;
                    border-radius: 2px;
                    background: rgba(28, 176, 100, .3);
                    .g-text {
                        display: inline-block;
                        width: 358px;
                        // height: 22px;
                        margin-left: 12px;
                        font-size: 16px;
                        color: #333333;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
                .key-word {
                    display: flex;
                    height: 40px;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    color: #333333;
                    // border: 1px solid red;
                }
                .ctr {
                    display: flex;
                    height: 40px;
                    margin-left: 36px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    color: #333333;
                }
                .item {
                    margin-bottom: 16px;
                    .text {
                        display: inline-block;
                        width: 100px;
                        text-align: right;
                        font-size: 16px;
                        color: #333333;
                        margin-right: 10px;
                    }
                    .name-input {
                        width: 360px;
                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                            text-align: left;
                        }
                    }
                    .practice-select {
                        width: 360px;
                        margin-right: 10px;
                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                        }
                    }
                    .practice-cascader {
                        width: 420px;
                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                        }
                    }
                }
                .has-sore {
                    display: flex;
                    .has-btn {
                        display: flex;
                        align-items: center;
                        .name-input {
                            border: 1px solid #DCDFE6;
                            color: #606266;
                            height: 40px;
                            line-height: 40px;
                            box-sizing: border-box;
                            padding: 0 15px;
                            margin-right: 16px;
                        }
                        .none-data {
                            color: #b1b4c1d6;
                        }
                    }
                }
                .goods-put {
                    .item {
                        display: flex;
                        align-items: center;
                    }
                }
                .goods-title {
                    .name-input {
                        width: 474px;
                    }
                }
            }
            .upload-btn {
                position: relative;
                display: flex;
                align-items: center;
                .btn {
                    width: 88px;
                    height: 38px;
                    background: #E7F6EF;
                    border: 1px solid #2DC079;
                    border-radius: 4px;
                    line-height: 38px;
                    text-align: center;
                    color: #2DC079;
                }
                input {
                    width: 88px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    opacity: 0;
                }
                .text {
                    color: #999999;
                    font-size: 14px;
                    margin-left: 15px;
                }
            }
        }
    }
    .upload-file-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .file-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
            color: #333333;
            font-size: 14px;
            margin: 0 20px 15px 0;

            &:hover {
                cursor: pointer;
                background-color: #F5F7FA;

                .del-icon {
                    display: block;
                }

                .finish-icon {
                    display: none;
                }
            }

            .file-name {
                display: inline-block;
                width: 1%;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-progress {
                margin-left: 20px;
                width: 160px;
            }

            i {
                color: #2461EF;
                margin: 0 10px 0 0;
            }

            .finish-icon {
                color: #2DC079;
                margin: 0 0 0 10px;
            }

            .del-icon {
                display: none;
                color: #FF0000;
                margin: 0 0 0 10px;
            }
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        font-size: 14px;
        color: #999;
        margin-left: 10px;
    }
    .add-task-dialog {
        .demo-ruleForm {
            .el-form-item {
                display: flex;
                align-items: center;
                ::v-deep .el-form-item__label {
                    margin-bottom: 0;
                }
                ::v-deep .el-form-item__content {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px !important;
                    .el-input-number {
                        width: 100%;
                    }
                    .el-input-number.is-without-controls .el-input__inner {
                        text-align: left;
                    }
                }
            }
        }
        .dialog-footer {
            text-align: center;
        }
    }
    .assign-goods-dialog {
        .assign-search {
            margin-bottom: 15px;
            .practice-cascader {
                width: 273px;
                margin-left: 10px;
                ::v-deep .el-input__inner {
                    border-radius: 2px;
                }
            }
        }
        .upload-file-content {
            height: 287px;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
            .goods-checkbox-group {
                position: relative;
                height: 100%;
                padding: 12px;
                box-sizing: border-box;
                .goods-checkbox {
                    width: 172px;
                    padding-bottom: 10px;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    ::v-deep .el-checkbox__label {
                        position: absolute;
                        width: 158px;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                }

            }
        }
        .class-content {
            height: 287px;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            .class-cascader {
                height: 100%;
                border: none;
            }
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
    }
    .none-goods {

    }
</style>

<template>
    <div class="procurement-management">
        <div>
            <div class="title-info">
                <div class="item number">
                    <span class="text">搜索人数</span>
                    <el-input-number v-model="procurementForm.search_num" :min="1000"  :max="15000" :precision="0" :controls="false"
                                     placeholder="请输入搜索人数" style="width: 420px;"></el-input-number>
                </div>
                <div class="item number" style="display: flex; line-height: 40px">
                    <span class="text">波动范围</span>
                    <el-input-number v-model="procurementForm.search_num_wave_lower" :min="-99"  :max="99"  :controls="false" style="width: 100px;"></el-input-number>
                    <div style="width: 20px; text-align: center;">~</div>
                    <el-input-number v-model="procurementForm.search_num_wave_upper" :min="-98"  :max="100"  :controls="false" style="width: 100px;"></el-input-number>
                </div>
                <div class="item number">
                    <span class="text">推广金额</span>
                    <el-input-number v-model="procurementForm.generalize_money" :min="0.01"  :max="99999999.99"  :controls="false"
                                     placeholder="请输入推广金额" style="width: 420px;"></el-input-number>
                </div>
                <div class="item">
                    <span class="text">商品类目</span>
                    <el-cascader
                            style="width:420px"
                            v-model="procurementForm.category_id"
                            :options="goodsClassList"
                            :props="keyObjClass"
                            @change="handleChangeGoodsClass">
                    </el-cascader>
                </div>
                <div class="item number">
                    <span class="text">满分利润</span>
                    <el-input-number v-model="procurementForm.full_mark_profit" :min="1" :max="99999" :precision="0" :controls="false"
                                     placeholder="请输入满分利润" style="width: 420px; margin-right: 10px"></el-input-number>
                    分值
                    <el-input-number
                            v-model="procurementForm.score"
                            :precision="1"
                            :min="0.1"
                            :max="100"
                            :controls="false"
                            style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
            </div>
            <div class="title-info">
                <div class="optimize-title">选品数据</div>
                <div class="item">
                    <div>主图</div>
                    <div class="goods-img-box" v-if="procurementForm.file">
                        <div class="src-box">
                            <img :src="procurementForm.file" alt="">
                        </div>
                        <i  @click="clearImg()" class="el-icon-error clear-good-img"></i>
                    </div>
                    <el-upload class="logo-uploader"
                               v-else
                               action="tranPurchase/upload"
                               :headers="headersParams"
                               name="file"
                               accept=".jpg, .jpeg, .png"
                               :show-file-list="false"
                               :on-success="handleCoverSuccess"
                               :before-upload="beforeCoverUpload">
                        <i class="el-icon-plus goods-uploader-icon"></i>
                    </el-upload>
                    <span class="school-prompt">建议尺寸：800*800；格式为.jpg或.jpeg或.png</span>
                </div>
                <div class="item">
                    <el-form :model="stepOne" ref="stepOne" class="step-form" :hide-required-asterisk="true">
                        <div class="form-title">子品牌数量</div>
                        <div class="form-content flex">
                            <el-form-item
                                    v-for="(brandItem, brandIndex) in stepOne.sub_brands_data"
                                    :label="brandItem.brandtype === 1 ? '一线品牌' : brandItem.brandtype === 2 ? '二线品牌' : '三线品牌'"
                                    :key="`sub_brands_data_${brandIndex}`"
                                    :prop="'sub_brands_data.' + brandIndex + '.subbrands'"
                                    :rules="{required: true, type: 'number', message: '须为1-26区间的数字', trigger: 'blur'}">
                                <el-input-number v-model="brandItem.subbrands" :min="1" :max="26" :precision="0"
                                                 :controls="false" style="width:200px" placeholder="1-26"></el-input-number>
                            </el-form-item>
                        </div>
                        <!--品牌人数比例-->
                        <div class="form-title">
                            品牌人数比例
                            <span class="form-title-prompt">(品牌人数比例总和是100%)</span>
                            <el-input size="medium" placeholder="总和" style="width:200px; margin-left: 10px" disabled :value="totalScore"></el-input>
                            <span class="symbol-style" style="color: #666">%</span>
                        </div>
                        <div class="form-content flex">
                            <el-form-item
                                    v-for="(peopleItem, peopleIndex) in stepOne.brands_num_data"
                                    :label="peopleItem.brandtype === 1 ? '一线品牌' : peopleItem.brandtype === 2 ? '二线品牌' : '三线品牌'"
                                    :key="`brands_num_data_${peopleIndex}`"
                                    :prop="'brands_num_data.' + peopleIndex + '.brandproportion'"
                                    :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                <el-input-number v-model="peopleItem.brandproportion" :min="0" :max="100" :precision="0"
                                                 :ref="'brands_num_data' + peopleIndex + 'brandproportion'"
                                                 :controls="false" style="width: 100px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                        </div>
                        <!--商品信息-->
                        <div class="form-title">商品信息</div>
                        <div class="form-content goods-info-wrapper">
                            <div class="item item-line1">
                                <div style="width: 70px; height: 16px; margin: 30px 10px 20px 0"></div>
                                <div class="line-title">成本价</div>
                                <div class="line-title mb40">波动范围</div>
                                <div class="line-title">市场价</div>
                                <div class="line-title mb40">波动范围</div>
                                <div class="line-title">点击率</div>
                                <div class="line-title mb40">波动范围</div>
                                <div class="line-title">转化率</div>
                                <div class="line-title">波动范围</div>
                            </div>
                            <div class="item item-line2" v-for="(goodItem, goodIndex) in stepOne.goods_data" :key="`goods_data_${goodIndex}`">
                                <div class="line-title line-title-first">
                                    <span v-if="goodItem.brandtype === 1">一线品牌</span>
                                    <span v-else-if="goodItem.brandtype === 2">二线品牌</span>
                                    <span v-else-if="goodItem.brandtype === 3">三线品牌</span>
                                </div>
                                <!-- 成本价-->
                                <el-form-item class="line-item"
                                              :prop="'goods_data.' + goodIndex + '.costprice'"
                                              :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                    <el-input-number v-model="goodItem.costprice" :min="0.01" :max="999999999.99" :precision="2" :ref="'goods_data' + goodIndex + 'costprice'"
                                                     :controls="false" style="width: 160px" placeholder=""></el-input-number>
                                </el-form-item>
                                <!--成本价 波动范围-->
                                <div class="content-item flex mb18">
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.coslower'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.coslower" :min="-99" :max="99" :precision="0" :ref="'goods_data' + goodIndex + 'coslower'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                    <span class="wavy-line">~</span>
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.cosupper'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.cosupper" :min="-98" :max="100" :precision="0" :ref="'goods_data' + goodIndex + 'cosupper'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                </div>
                                <!--市场价-->
                                <el-form-item class="line-item"
                                              :prop="'goods_data.' + goodIndex + '.marketvalue'"
                                              :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                    <el-input-number v-model="goodItem.marketvalue" :min="0.01" :max="999999999.99" :precision="2" :ref="'goods_data' + goodIndex + 'marketvalue'"
                                                     :controls="false" style="width: 160px" placeholder=""></el-input-number>
                                </el-form-item>
                                <!--市场价波动范围-->
                                <div class="content-item flex mb18">
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.marketlower'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.marketlower" :min="-99" :precision="0" :ref="'goods_data' + goodIndex + 'marketlower'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                    <span class="wavy-line">~</span>
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.marketupper'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.marketupper" :min="-98" :precision="0" :ref="'goods_data' + goodIndex + 'marketupper'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                </div>
                                <!--点击率-->
                                <el-form-item class="line-item"
                                              :prop="'goods_data.' + goodIndex + '.click'"
                                              :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                    <el-input-number v-model="goodItem.click" :min="0.01" :max="100" :precision="2" :ref="'goods_data' + goodIndex + 'click'"
                                                     :controls="false" style="width: 160px" placeholder=""></el-input-number>
                                    <span class="symbol-style">%</span>
                                </el-form-item>
                                <!--点击率波动范围-->
                                <div class="content-item flex mb18">
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.clilower'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.clilower" :min="-999" :max="998" :precision="0" :ref="'goods_data' + goodIndex + 'clilower'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                    <span class="wavy-line">~</span>
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.cliupper'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.cliupper" :min="-998" :max="1000" :precision="0" :ref="'goods_data' + goodIndex + 'cliupper'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                </div>
                                <!--转化率-->
                                <el-form-item class="line-item"
                                              :prop="'goods_data.' + goodIndex + '.conversion'"
                                              :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                    <el-input-number v-model="goodItem.conversion" :min="0.01" :max="100" :precision="2" :ref="'goods_data' + goodIndex + 'conversion'"
                                                     :controls="false" style="width: 160px" placeholder=""></el-input-number>
                                    <span class="symbol-style">%</span>
                                </el-form-item>
                                <!--转化率波动范围-->
                                <div class="content-item flex">
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.conlower'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.conlower" :min="-999" :max="998" :precision="0" :ref="'goods_data' + goodIndex + 'conlower'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                    <span class="wavy-line">~</span>
                                    <el-form-item :prop="'goods_data.' + goodIndex + '.conupper'"
                                                  :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
                                        <el-input-number v-model="goodItem.conupper" :min="-998" :max="1000" :precision="0" :ref="'goods_data' + goodIndex + 'conupper'"
                                                         :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                        <span class="symbol-style">%</span>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="cancel">取消</el-button>
            <el-button size="medium" type="primary" @click="saveProcurement()">保存</el-button>

        </div>
    </div>
</template>

<script>
    import {adminGoodGetCategory,tranPurchaseAdminPurchaseCreate,operationProblemOpDetail} from '@/utils/apis'
    export default {
        name: "ProcurementManagement",
        props: ['practiceForm'],
        data(){
            return{
                procurementForm:{
                    search_num:void 0,
                    generalize_money:void 0,
                    category_id:'',
                    full_mark_profit:void 0,
                    score:void 0,
                    file:'',
                    search_num_wave_lower: void 0,
                    search_num_wave_upper: void 0,
                },

                goodsClassList: [],//商品类目列表
                keyObjClass: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                stepOne: {
                    sub_brands_data: [ // 子品牌数据
                        {
                            // title: '一线品牌',
                            subbrands: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            subbrands: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            subbrands: void 0,
                            brandtype: 3,
                        },
                    ],
                    brands_num_data: [ // 品牌人数比例
                        {
                            // title: '一线品牌',
                            brandproportion: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            brandproportion: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            brandproportion: void 0,
                            brandtype: 3,
                        },
                    ],
                    goods_data: [ // 商品信息数据
                        {
                            // title: '一线品牌',
                            brandtype: 1,
                            costprice: void 0,//成本价
                            coslower: void 0,//成本价波动范围
                            cosupper: void 0,//成本价波动范围第二个
                            marketvalue: void 0,//市场价
                            marketlower: void 0,//市场价波动范围
                            marketupper: void 0,//市场价波动范围第二个
                            click: void 0,//点击率
                            clilower: void 0,//点击率波动范围
                            cliupper: void 0,//点击率波动范围第二个
                            conversion: void 0,//转化率
                            conlower: void 0,//转化率波动范围
                            conupper: void 0,//转化率波动范围第二个
                        },
                        {
                            // title: '二线品牌',
                            brandtype: 2,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                        {
                            // title: '三线品牌',
                            brandtype: 3,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                    ],
                },
                headersParams:{
                    Authorization: localStorage.getItem('adminToken') || ''
                },
            }
        },
        computed: {
            totalScore() {
                let total = 0
                if (Array.isArray(this.stepOne.brands_num_data)) {
                    this.stepOne.brands_num_data.forEach(item => {
                        if (isNaN(Number(item.brandproportion))) {
                            total += 0
                        } else {
                            total += Number(item.brandproportion)
                        }
                    })
                }
                return total
            }
        },
        mounted() {
            this.getGoodsClassList();
            if (this.$route.query.id){
                this.getOperationProblemOpDetail()
            }
        },
        methods:{
            //获取详情
            getOperationProblemOpDetail(){
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.procurementForm.search_num = res.data.search_num;
                    this.procurementForm.search_num_wave_lower = res.data.search_num_wave_lower;
                    this.procurementForm.search_num_wave_upper = res.data.search_num_wave_upper;
                    this.procurementForm.generalize_money = res.data.generalize_money;
                    this.procurementForm.category_id = res.data.goods_class_id;
                    this.procurementForm.full_mark_profit = res.data.full_mark_profit;
                    this.procurementForm.score = res.data.score;
                    this.procurementForm.file = res.data.master_img;
                    this.stepOne.sub_brands_data = res.data.branch_brand_num;
                    this.stepOne.brands_num_data = res.data.brand_num_rate;
                    this.stepOne.goods_data = res.data.goods_info;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取商品类目
            getGoodsClassList() {
                adminGoodGetCategory().then((res) => {
                    console.log(res,'111')
                    this.goodsClassList = res.data;
                }).catch((err) => {
                    console.log(err);
                })
            },
            handleChangeGoodsClass(){
                this.getGoodsClassList();
            },
            // start 上传图片
            beforeCoverUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 800;
                    const height = 800;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为800px x 800px');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            // 上传图片
            handleCoverSuccess(res, file){
                if (res.code === 200) {
                    // this.headerSrc = this.hostUrl + res.data; // 有域名
                    this.procurementForm.file = res.data.src; //无域名
                    this.$message.success('头像上传成功')
                } else {
                    this.$message.error('头像上传失败，请稍后再试~')
                }
            },
            //删除图片
            clearImg(){
                this.procurementForm.file = '';
            },
            // 保存
            saveProcurement(){
                // this.verificationData()
                this.$refs['stepOne'].validate((valid) => {
                    if (valid) {
                        if (!(Math.abs(this.procurementForm.search_num_wave_lower) >= 0) || !(Math.abs(this.procurementForm.search_num_wave_upper) >= 0)) return this.$message.warning("波动范围不能为空");
                        if (Math.abs(this.procurementForm.search_num_wave_lower) >= 0 && Math.abs(this.procurementForm.search_num_wave_upper) >= 0 && this.procurementForm.search_num_wave_lower >= this.procurementForm.search_num_wave_upper) return this.$message.warning("波动范围低值应小于高值");
                        let brandsData = this.stepOne.brands_num_data;
                        brandsData.forEach((item, i) => {
                            if (this.totalScore > 100) {
                                this.$refs[`brands_num_data${i}brandproportion`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`brands_num_data${i}brandproportion`][0].$el.classList.remove("isError");
                            }
                        });
                        for (let i = 0; i < brandsData.length; i++) {
                            if (this.totalScore > 100) {
                                return this.$message.warning("品牌人数比例总和是100%");
                            }
                        }
                        let goodDataTmp = this.stepOne.goods_data;
                        goodDataTmp.forEach((item, i) => {
                            // 成本价波动范围
                            if (Number(item.coslower) >= Number(item.cosupper)) {
                                this.$refs[`goods_data${i}cosupper`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`goods_data${i}cosupper`][0].$el.classList.remove("isError");
                            }
                            // 市场价波动范围
                            if (Number(item.marketlower) >= Number(item.marketupper)) {
                                this.$refs[`goods_data${i}marketupper`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`goods_data${i}marketupper`][0].$el.classList.remove("isError");
                            }
                            // 点击率波动范围
                            if (Number(item.clilower) >= Number(item.cliupper)) {
                                this.$refs[`goods_data${i}cliupper`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`goods_data${i}cliupper`][0].$el.classList.remove("isError");
                            }
                            // 转化率波动范围
                            if (Number(item.conlower) >= Number(item.conupper)) {
                                this.$refs[`goods_data${i}conupper`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`goods_data${i}conupper`][0].$el.classList.remove("isError");
                            }
                            // 市场价应高于成本价
                            if (Number(item.costprice) > Number(item.marketvalue)) {
                                this.$refs[`goods_data${i}marketvalue`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`goods_data${i}marketvalue`][0].$el.classList.remove("isError");
                            }
                        });
                        for (let i = 0; i < goodDataTmp.length; i++) {
                            // 成本价波动范围
                            if (Number(goodDataTmp[i].coslower) >= Number(goodDataTmp[i].cosupper)){
                                return this.$message.warning("波动范围低值应小于高值");
                            }
                            // 市场价波动范围
                            if (Number(goodDataTmp[i].marketlower) >= Number(goodDataTmp[i].marketupper)){
                                return this.$message.warning("波动范围低值应小于高值");
                            }
                            // 点击率波动范围
                            if (Number(goodDataTmp[i].clilower) >= Number(goodDataTmp[i].cliupper)){
                                return this.$message.warning("波动范围低值应小于高值");
                            }
                            // 转化率波动范围
                            if (Number(goodDataTmp[i].conlower) >= Number(goodDataTmp[i].conupper)){
                                return this.$message.warning("波动范围低值应小于高值");
                            }
                            // 市场价应高于成本价
                            if (Number(goodDataTmp[i].costprice) > Number(goodDataTmp[i].marketvalue)){
                                return this.$message.warning("成本价应小于市场价");
                            }
                        }
                        let param = {
                            op_name: this.practiceForm.name,
                            exam_module_id: this.practiceForm.module_level_three_value,
                            category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                            assess_time: this.practiceForm.time,
                            task_goal: this.practiceForm.taskList,
                            task_background: this.practiceForm.taskBackground,
                            task_analysis: this.practiceForm.taskAnalysis,
                            task_operation: this.practiceForm.taskOperation,
                            scoring_criteria: this.practiceForm.scoreList,
                            search_num:this.procurementForm.search_num,
                            search_num_wave_lower:this.procurementForm.search_num_wave_lower,
                            search_num_wave_upper:this.procurementForm.search_num_wave_upper,
                            generalize_money:this.procurementForm.generalize_money,
                            goods_class_id:this.procurementForm.category_id[this.procurementForm.category_id.length - 1],
                            full_mark_profit:this.procurementForm.full_mark_profit,
                            score:this.procurementForm.score,
                            master_img:this.procurementForm.file,
                            branch_brand_num:this.stepOne.sub_brands_data,
                            brand_num_rate:this.stepOne.brands_num_data,
                            goods_info:this.stepOne.goods_data,
                        };
                        if (this.$route.query.id) {
                            param.id = this.$route.query.id;
                        }
                        tranPurchaseAdminPurchaseCreate(param)
                            .then((res) => {
                              if(res.code==200){
                                this.$message.success(res.msg);
                                this.$router.push("/practice/index");
                              }
                            }).catch((err) => {
                               console.error("err", err);
                       });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            //取消
            cancel(){
                this.$router.push({
                    path: '/practice/index'
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .procurement-management{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 30px;
        .title-info {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
            }
        }
        .number{
            ::v-deep .el-input-number {
                .el-input__inner {
                    text-align: left;
                }
            }
        }
        .goods-img-box{
            width: 200px;
            height: 200px;
            box-sizing: border-box;
            position: relative;
            margin: 10px 0;
            .src-box{
                width: 100%;
                height: 100%;
                overflow: hidden;
                border: 1px solid #EEEEEE;
                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .clear-good-img {
                background: #fff;
                border-radius: 50%;
                position: absolute;
                top: -9px;
                right: -9px;
                z-index: 1;
                font-size: 18px;
                color: #aaa;
                cursor: pointer;
                transition: all .3s;
                &:hover {
                    color: #ff0000;
                }
            }

        }
        .logo-uploader {
            width: 200px;
            height: 200px;
            border: 1px solid #ddd;
            background: #DFDFE5;
            margin: 10px 0;

            ::v-deep .el-upload {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .goods-uploader-icon {
                font-size: 32px;
                color: #A0A0A0;
            }
        }
        .school-prompt{
            color: #999;
            font-size: 14px;
        }
        ::v-deep .step-form {
            /*padding-left: 30px;*/
            .office-title {
                margin-bottom: 40px;
                .el-form-item__label {
                    font-size: 16px;
                    font-weight: 500;
                    color: #13131B;
                }
            }
            .form-title {
                margin-top: 20px;
                font-size: 16px;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-bottom: 12px;
            }
            .form-title-prompt {
                font-size: 14px;
                color: #999;
                margin-left: 10px;
            }

            .form-content {
                /*padding-left: 45px;*/
                & > .el-form-item {
                    margin-right: 110px;
                    width: 200px;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                &.goods-info-wrapper {
                    display: flex;
                    .item {
                        .line-title {
                            line-height: 41px;
                            width: 70px;
                            margin-bottom: 22px;
                            text-align: right;
                            position: relative;
                            margin-right: 10px;
                            &.line-title-first {
                                width: 160px;
                                line-height: 1;
                                font-size: 16px;
                                color: #13131B;
                                height: 16px;
                                text-align: center;
                                margin: 30px 60px 20px 0;
                            }
                        }
                    }
                }
            }
            .symbol-style {
                margin-left: 6px;
            }
            .wavy-line {
                line-height: 40px;
                margin: 0 7px 0 6px;
            }
            .mb40 {
                margin-bottom: 40px !important;
            }
            .mb18 {
                margin-bottom: 18px !important;

            }
        }
        .dialog-footer {
            margin-top: 20px;
            text-align: center;
        }
    }
</style>
<template>
    <div class="home-make">
        <div class="make-header">
            <div class="header-item" v-if="practiceForm.module_level_three_value === 12">
                <span>店招</span>
    <!--            <el-input-number v-model="score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分-->
                <el-input-number class="number-input" v-model="ruleForm.dz_score" :precision="1" :controls="false"
                                 :min="0.1" :max="99.0" style="width: 140px; margin: 0 10px 0 12px"></el-input-number>分
                <span>电脑海报</span>
                <el-input-number class="number-input" v-model="ruleForm.poster_score" :precision="1" :controls="false"
                                 :min="0.1" :max="99.0" style="width: 140px; margin: 0 10px 0 12px"></el-input-number>分
            </div>

            <div class="header-item" style="margin: 20px 0" v-if="practiceForm.module_level_three_value === 30 || practiceForm.module_level_three_value === 85 ||  practiceForm.module_level_three_value === 59">
                <!-- <span>分值</span>
                <el-input-number class="number-input" v-model="ruleForm.score" :precision="1" :controls="false"
                                 :min="0.1" :max="100.0" style="width: 140px; margin: 0 10px 0 12px"></el-input-number>分 -->
<!--                <el-input-number class="number-input" v-model="ruleForm.store_class_score" :precision="1" :controls="false"-->
<!--                                 :min="0.1" :max="99.0" style="width: 140px; margin: 0 10px 0 12px"></el-input-number>分-->
<!--                <span>商品</span>-->
<!--                <el-input-number class="number-input" v-model="ruleForm.goods_score" :precision="1" :controls="false"-->
<!--                                 :min="0.1" :max="99.0" style="width: 140px; margin: 0 10px 0 12px"></el-input-number>分-->

                <span>店铺LOGO、店招</span>
                <el-input-number class="number-input" v-model="ruleForm.dz_score" :precision="1" :controls="false" :min="0" :max="100.0" style="width: 100px;"></el-input-number>
                <span style="margin-right: 40px;">分</span>
                <span>Banner</span>
                <el-input-number class="number-input" v-model="ruleForm.poster_score" :precision="1" :controls="false" :min="0" :max="100.0" style="width: 100px;"></el-input-number>
                <span style="margin-right: 40px;">分</span>
                <span>商品主图</span>
                <el-input-number class="number-input" v-model="ruleForm.goods_img_score" :precision="1" :controls="false" :min="0" :max="100.0" style="width: 100px;"></el-input-number>
                <span style="margin-right: 40px;">分</span>
                <span>商品详情页</span>
                <el-input-number class="number-input" v-model="ruleForm.goods_details_score" :precision="1" :controls="false" :min="0" :max="100.0" style="width: 100px;"></el-input-number>
                <span style="margin-right: 40px;">分</span>
                <span>商品短视频</span>
                <el-input-number class="number-input" v-model="ruleForm.goods_short_video_score" :precision="1" :controls="false" :min="0" :max="100.0" style="width: 100px;"></el-input-number>
                <span style="margin-right: 40px;">分</span>

            </div>
        </div>
        <div class="content-item" style="margin-top: 38px">
            <div class="content-title">上传素材</div>
            <div class="item-content">
                <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                       accept="application/zip, .rar" title=""
                       @change="changeFile($event)">
                <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G</span>
            </div>
        </div>
        <div class="content-item">
            <div class="item-content">
                <div class="upload-file-list">
                    <div class="file-item" v-for="(item, index) in materialList">
                        <i class="iconfont">&#xe60b;</i>
                        <span class="file-name" :title="item.name">{{item.name}}</span>
                        <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                        <i class="iconfont finish-icon" v-show="percent == 100">&#xe654;</i>
                        <i class="iconfont del-icon" @click="delMaterial(item, index)" v-show="percent == 100">&#xe651;</i>
                    </div>
                    <download-btn :status="isFinish" :list="materialList"></download-btn>
                </div>
            </div>
        </div>
        <div class="footer-btn" style="margin-top: 40px;text-align: center;">
            <el-button type="primary" @click="saveMake">保 存</el-button>
            <el-button @click="cancelSave">取 消</el-button>
        </div>
    </div>
</template>

<script>
    import {operationProblemDelMaterial, uploadMaterial, operationProblemSaveDzAndPoster, mergeSlice} from "@/utils/apis";
    import Upload from "@/utils/slice_upload";
    import downloadBtn from "../../../../util/downloadBtn.vue";
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            },
            editData: {
                type: Object,
                default:() => {}
            },
        },
        components: {
            downloadBtn
        },
        computed: {
            isFinish() {
                return Boolean(this.materialList.length && this.materialList[0].url);
            }
        },
        data(){
            return {
                // score: void 0,
                ruleForm: {
                    dz_score: void 0,
                    poster_score: void 0,
                    // store_class_score: void 0,
                    // goods_score: void 0,
                    score: void 0,
                },

                //素材列表
                material_file:[],
                materialList: [],
                percent: 0,
                uploadInstance: null,
            }
        },
        mounted() {
            if(this.$route.query.id){
                console.log({ editData: this.editData });
                // this.score = this.editData.score;
                this.ruleForm.dz_score = this.editData.dz_score === null ? void 0 : this.editData.dz_score
                this.ruleForm.poster_score = this.editData.poster_score === null ? void 0 : this.editData.poster_score
                this.ruleForm.goods_img_score = this.editData.goods_img_score === null ? void 0 : this.editData.goods_img_score
                this.ruleForm.goods_details_score = this.editData.goods_details_score === null ? void 0 : this.editData.goods_details_score
                this.ruleForm.goods_short_video_score = this.editData.goods_short_video_score === null ? void 0 : this.editData.goods_short_video_score
                // this.ruleForm.store_class_score = this.editData.store_class_score
                // this.ruleForm.goods_score = this.editData.goods_score
                this.ruleForm.score = this.editData.score
                this.material_file = this.editData.material_file;
                this.materialList = this.editData.material_file;
                this.percent = 100
            }
        },
        methods:{
            // 上传素材
            changeFile(event) {
                let chunkSize = 10 * 1024 * 1024;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.materialList = []
                this.material_file = [];
                this.materialList.push(obj);
                this.material_file.push(obj);
                let index = 0;
                this.uploadSlice(index);
                this.percent = 0
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.material_file.splice(index, 1);
                            this.materialList.splice(index, 1);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            // 保存
            saveMake(){
                if (!this.practiceForm.name || this.practiceForm.name === '') {
                    this.$message.warning('请填写名称！');
                    return;
                }
                if (this.practiceForm.name.length < 3 || this.practiceForm.name.length > 50) {
                    this.$message.warning('名称必须大于3个字符且小于50个字符！');
                    return;
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return;
                }
                if (!this.practiceForm.time) {
                    this.$message.warning('请填写考核时间！');
                    return;
                }
                // if (this.practiceForm.taskList.length === 0) {
                //     this.$message.warning('请添加任务目标！');
                //     return;
                // }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写操作要求！');
                    return;
                }
                // if (!this.practiceForm.taskAnalysis) {
                //     this.$message.warning('请填写任务分析！');
                //     return;
                // }
                // if (!this.practiceForm.taskOperation) {
                //     this.$message.warning('请填写任务操作！');
                //     return;
                // }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return;
                }
                // if(!this.score){
                //     this.$message.warning('请填写分值！');
                //     return;
                // }

                if (this.practiceForm.module_level_three_value === 30 || this.practiceForm.module_level_three_value === 59 || this.practiceForm.module_level_three_value === 85) {
                    // if(!this.ruleForm.score){
                    //     this.$message.warning('请填写分值！');
                    //     return;
                    // }
                    // if(this.ruleForm.score !== 100){
                    //     this.$message.warning('分值为100！');
                    //     return;
                    // }
                    // if(!this.ruleForm.store_class_score){
                    //     this.$message.warning('请填写店铺分类分值！');
                    //     return;
                    // }
                    // if(!this.ruleForm.goods_score){
                    //     this.$message.warning('请填写商品分值！');
                    //     return;
                    // }
                    // if (this.ruleForm.dz_score + this.ruleForm.poster_score + this.ruleForm.store_class_score + this.ruleForm.goods_score !== 100) {
                    //     this.$message.warning('店招分值+海报分值+店铺分类分值+商品分值应为100！');
                    //     return;
                    // }
                    // if(!this.ruleForm.dz_score){
                    //     this.$message.warning('请填写店铺LOGO、店招分值！');
                    //     return;
                    // }
                    // if(!this.ruleForm.poster_score){
                    //     this.$message.warning('请填写Banner分值！');
                    //     return;
                    // }
                    // if(!this.ruleForm.goods_img_score){
                    //     this.$message.warning('请填写商品主图分值！');
                    //     return;
                    // }
                    // if(!this.ruleForm.goods_details_score){
                    //     this.$message.warning('请填写商品详情页分值！');
                    //     return;
                    // }
                    // if(!this.ruleForm.goods_short_video_score){
                    //     this.$message.warning('请填写商品短视频分值！');
                    //     return;
                    // }
                    // if (this.ruleForm.dz_score + this.ruleForm.poster_score + this.ruleForm.goods_img_score + this.ruleForm.goods_details_score + this.ruleForm.goods_short_video_score !== 100) {
                    //     this.$message.warning('分值相加必须等于100！');
                    //     return;
                    // }
                } else {
                    if(!this.ruleForm.dz_score){
                        this.$message.warning('请填写店招分值！');
                        return;
                    }
                    if(!this.ruleForm.poster_score){
                        this.$message.warning('请填写电脑海报分值！');
                        return;
                    }
                    if (this.ruleForm.dz_score + this.ruleForm.poster_score !== 100) {
                        this.$message.warning('店招分值+海报分值应为100！');
                        return;
                    }
                }
                if (this.material_file.length === 0) {
                    this.$message.warning('请上传素材！');
                    return;
                }
                let params = {
                    op_name: this.practiceForm.name,
                    // exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    // task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    // task_analysis: this.practiceForm.taskAnalysis,
                    // task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    // score:this.score,
                    dz_score: this.ruleForm.dz_score,
                    poster_score: this.ruleForm.poster_score,
                    // store_class_score: this.ruleForm.store_class_score,
                    // goods_score: this.ruleForm.goods_score,
                    // score: this.ruleForm.score,
                    dz_score: this.ruleForm.dz_score,
                    poster_score: this.ruleForm.poster_score,
                    goods_img_score: this.ruleForm.goods_img_score,
                    goods_details_score: this.ruleForm.goods_details_score,
                    goods_short_video_score: this.ruleForm.goods_short_video_score,
                    material_file:this.material_file,
                }
                if(this.$route.query.id){
                    params.id = this.$route.query.id
                }
                if (this.practiceForm.module_level_three_value === 30 || this.practiceForm.module_level_three_value === 59 || this.practiceForm.module_level_three_value === 85) {
                    params.level = 3
                }
                operationProblemSaveDzAndPoster(params).then((res)=>{
                if(res.code==200){
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/practice/index'
                    })
                }
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            //取消保存
            cancelSave() {
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .home-make{
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        .make-header{
            .header-item {
                display: flex;
                align-items: center;
                font-size: 16px;
                & > span {
                    margin: 0 10px;
                }
            }
        }
    }
    .content-item {
        .content-title {
            position: relative;
            padding-left: 10px;
            margin-bottom: 22px;
            &:before {
                content: '';
                width: 4px;
                height: 16px;
                background-color: #2DC079;
                border-radius: 2px;
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
        .item-content {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .item-title {
                width: 120px;
                text-align: right;
                margin-right: 10px;
            }
            .divide {
                width: 24px;
                height: 2px;
                background: #D2D2D2;
                margin: 0 18px;
            }
            .file-upload-btn {
                display: none;
            }
            .upload-text {
                margin-left: 11px;
                font-size: 14px;
                color: #999999;
            }
            .upload-file-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                align-items: center;
                .file-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #333333;
                    font-size: 14px;
                    &:hover {
                        cursor: pointer;
                        background-color: #F5F7FA;
                        .del-icon {
                            display: block;
                        }
                        .finish-icon {
                            display: none;
                        }
                    }
                    .file-name {
                        display: inline-block;
                        width: 1%;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .el-progress {
                        margin-left: 20px;
                        width: 160px;
                    }
                    i {
                        color: #2461EF;
                        margin: 0 10px 0 0;
                    }
                    .finish-icon {
                        color: #2DC079;
                        margin: 0 0 0 10px;
                    }
                    .del-icon {
                        display: none;
                        color: #FF0000;
                        margin: 0 0 0 10px;
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="goods-release">
        <div class="goods-release-content">
            <div class="key-item">
                <div class="item">
                    <span class="text">热门关键词</span>
                    <el-select v-model="goodsReleaseForm.keyValue" placeholder="请选择热门关键词" clearable
                               style="width: 360px;">
                        <el-option v-for="keyItem in keyList" :key="keyItem.id" :label="keyItem.name"
                                   :value="keyItem.id"></el-option>
                    </el-select>
                </div>
            </div>
            <!--S 优化标题-->
            <div class="title-info">
                <div class="optimize-title">优化标题</div>
                <div class="item">
                    <span class="text">搜索人数</span>
                    <el-input-number v-model="goodsReleaseForm.search" :min="1"  :max="99999" :precision="0" :controls="false"
                                     placeholder="请输入搜索人数" style="width: 360px;"></el-input-number>
                </div>
                <div class="item">
                    <span class="text">点击率（%）</span>
                    <el-input-number v-model="goodsReleaseForm.rate" :min="0.01"  :max="99999" :precision="2" :controls="false"
                                     placeholder="请输入点击率" style="width: 360px;"></el-input-number>
                </div>
                <div class="item">
                    <span class="text">转化率（%）</span>
                    <el-input-number v-model="goodsReleaseForm.conversion" :min="0.01"  :max="99999" :precision="2" :controls="false"
                                     placeholder="请输入转化率" style="width: 360px;"></el-input-number>
                </div>
                <div class="item">
                    <span class="text">满分点击量</span>
                    <el-input-number v-model="goodsReleaseForm.hits" :min="1" :max="99999" :precision="0" :controls="false"
                     placeholder="请输入满分点击量" style="width: 360px; margin-right: 10px"></el-input-number>
                    分值
                    <el-input-number
                        v-model="goodsReleaseForm.hits_score"
                        :precision="1"
                        :min="0.1"
                        :max="100"
                        :controls="false"
                        style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
            </div>
            <!--E 优化标题-->
            <!--S 商品上架-->
            <div class="title-info">
                <div class="optimize-title">商品上架</div>
                <div class="item">
                    <span class="text">商品上架类目</span>
                    <el-input v-model="goodsReleaseForm.category" placeholder="请选择类目" readonly style="width: 354px; margin-right:20px"></el-input>
                    <el-button type="primary" style="margin-right:40px" @click="selectCategoryBtn()">选择类目</el-button>
                    分值
                    <el-input-number v-model="goodsReleaseForm.category_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">商品标题</span>
                    <el-input v-model="goodsReleaseForm.goods_title" placeholder="请输入商品标题" style="width: 474px; margin-right: 40px"></el-input>
                    分值
                    <el-input-number v-model="goodsReleaseForm.goods_title_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">销售价（元）</span>
                    <el-input-number  v-model="goodsReleaseForm.selling_price" :min="0.02" :max="99999" :controls="false" :precision="2" placeholder="请输入销售价"
                              style="width: 474px; margin-right: 40px"></el-input-number>
                    分值
                    <el-input-number v-model="goodsReleaseForm.sale_price_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">市场价（元）</span>
                    <el-input-number v-model="goodsReleaseForm.market_price"  :min="0.01" :max="99999" :controls="false" :precision="2" placeholder="请输入市场价" style="width: 474px; margin-right: 40px"></el-input-number>
                    分值
                    <el-input-number v-model="goodsReleaseForm.market_price_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">库存</span>
                    <el-input-number v-model="goodsReleaseForm.stock"  :min="1" :max="99999" :controls="false" :precision="0" placeholder="请输入库存" style="width: 474px; margin-right: 40px"></el-input-number>
                    分值
                    <el-input-number v-model="goodsReleaseForm.stock_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
                <div class="item">
                    <span class="text">商品货号</span>
                    <el-input v-model="goodsReleaseForm.goods_number"  :controls="false" placeholder="请输入商品货号" style="width: 474px; margin-right: 40px"></el-input>
                    分值
                    <el-input-number v-model="goodsReleaseForm.goods_number_score"
                         :precision="1"
                         :min="0.1"
                         :max="100"
                         :controls="false"
                         style="width: 120px;margin: 0 10px">
                    </el-input-number>
                    分
                </div>
            </div>
            <!--E 商品上架-->
            <!--S 商品基本属性-->
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">商品基本属性</div>
                    <el-button type="primary" @click="goodsBtn()">添加商品基本属性</el-button>
                </div>
                <el-table :data="goodsTable" style="width: 100%; flex: 1" size="medium" border
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                    <el-table-column prop="attribute" label="属性"></el-table-column>
                    <el-table-column prop="content" label="答案" align="center"></el-table-column>
                    <el-table-column prop="score" label="分值" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link type="success" :underline="false" @click="editGoods(scope.row,scope.$index)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="deleteGoods(scope.$index)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--E 商品基本属性-->
            <!--S 运费模板-->
            <div class="title-info">
                <div class="optimize-title">运费模板</div>
                <div class="item">
                    <span class="text">模板名称</span>
                    <el-input v-model="goodsReleaseForm.freight_template_title" placeholder="请输入模板名称" style="width: 550px;"></el-input>
                </div>
                <div class="item">
                    <span class="text">是否包邮</span>
                    <el-radio-group v-model="goodsReleaseForm.free_radio" @change="changeFreeRadio()">
                        <el-radio :label="0">买家承担运费</el-radio>
                        <el-radio :label="1">卖家承担运费</el-radio>
                    </el-radio-group>
                    <span style="margin:0 10px 0 30px">分值</span>
                    <el-input-number placeholder="请输入分值" v-model="goodsReleaseForm.is_exempt_postage_score" :precision="0" :max="100" style="width: 120px;" :controls="false"></el-input-number>
                    <span style="margin-left: 5px;">分</span>
                </div>
                <div class="item">
                    <span class="text">计费规则</span>
                    <el-radio-group v-model="goodsReleaseForm.charging_radio" @change="chargingRadio()">
                        <el-radio :label="0">按件数</el-radio>
                        <el-radio :label="1">按重量</el-radio>
                    </el-radio-group>
                    <span style="margin:0 10px 0 30px">分值</span>
                    <el-input-number placeholder="请输入分值" v-model="goodsReleaseForm.accounting_rules_score" :precision="0" :max="100" style="width: 120px;" :controls="false"></el-input-number>
                    <span style="margin-left: 5px;">分</span>
                </div>
                <div class="item">
                    <div class="freight-title">运费设置</div>
                    <div class="freight-box">
                        <div class="freight-top">
                            <span>国内默认运费</span>
                            <el-input-number v-model="goodsReleaseForm.piece" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}内，
                            <el-input-number v-model="goodsReleaseForm.element" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元，
                            <span>每增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_num" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}，
                            <span>增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_price" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元，
                            <span style="margin:0 10px 0 30px">分值</span>
                    <el-input-number placeholder="请输入分值" v-model="goodsReleaseForm.default_shipping_score" :precision="0" :max="100" style="width: 120px;" :controls="false"></el-input-number>
                    <span style="margin-left: 5px;">分</span>
                        </div>
                        <el-table :data="freightTable" style="width: 100%; flex: 1" size="medium" border
                                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                                  :cell-style="{fontSize: '12px',color: '#333',height: '70px'}"
                                  class="customTable">
                            <el-table-column prop="transport" label="运送到" width="520px">
                                <template slot-scope="scope">
                                    <div class="region-item">
                                        <div style="flex: 1">{{scope.row.city}}</div>
                                        <span @click="editRegion(scope.row.city,scope.$index)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_thing" label="（首价）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_thing" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_cost" label="（首费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_cost" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_thing" label="（续件）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_thing" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_cost" label="（续费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_cost" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="link-list">
                                        <el-link type="danger" :underline="false" @click="deleteFreight(scope.$index)">删除</el-link>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="add-freight" @click="addRegion()">+为指定地区城市设置运费</div>
                    </div>
                    <!-- <div class="freight-score">
                        <span>运送设置分值</span>
                        <el-input-number v-model="goodsReleaseForm.freight_score" :min="0" :precision="0" :controls="false" style="width: 100px; margin: 0 10px"></el-input-number>
                        分
                    </div> -->
                </div>
            </div>
            <!--E 运费模板-->
            <div class="title-info">
                <div class="optimize-title">上传素材</div>
                <div class="item">
                    <div class="upload-btn">
                        <div class="btn">点击上传</div>
                        <input type="file" accept="application/zip, .rar" title="" @change="uploadFile($event)" ref="uploadFile">
                        <span class="text">文件支持格式为zip和rar，文件大小不超过1G</span>
                    </div>
                    <div class="upload-file-list">
                        <div class="file-item" v-for="(item, index) in materialList">
                            <i class="iconfont">&#xe60b;</i>
                            <span class="file-name" :title="item.name">{{item.name}}</span>
                            <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                            <i class="iconfont finish-icon" v-show="percent === 100">&#xe654;</i>
                            <i class="iconfont del-icon" @click="delMaterial(item, index)" v-show="percent == 100">&#xe651;</i>
                        </div>
                        <DownloadBtn :status="isFinish" :list="materialList"></DownloadBtn>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="goBack">取消</el-button>
            <el-button size="medium" type="primary" @click="saveGoods()">保存</el-button>
        </div>
        <!--选择类目-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" title="选择类目" custom-class="green-dialog" :visible.sync="categoryDialogVisible"  width="650px">
            <div class="class-content">
                <el-cascader-panel :options="goodsClassList" :props="keyObj" v-model="goodsClassValue" class="class-cascader"></el-cascader-panel>
            </div>
            <div class="dialog-footer">
                <el-button @click="categoryDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="confirmCategoryBtn()">确 定</el-button>
            </div>
        </el-dialog>
        <!--新增商品基本属性-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="goodsAttributesTitle"
                   custom-class="green-dialog02" :visible.sync="attributesDialogVisible" width="500px" @close="resetForm()">
            <el-form :model="attributesForm" ref="attributesForm" label-position="right" label-width="108px">
                <el-form-item label="属性"  prop="attribute">
                    <el-input v-model="attributesForm.attribute" ref="nameInput" autocomplete="off" style="width:340px"
                              placeholder="请输入属性"></el-input>
                </el-form-item>
                <el-form-item label="答案" prop="content">
                    <el-input v-model="attributesForm.content" ref="nameInput" autocomplete="off" style="width:340px"
                              placeholder="请输入答案"></el-input>
                </el-form-item>
                <el-form-item label="分值"  prop="score">
                    <el-input-number v-model="attributesForm.score" :min="0.1" :max="100" :precision="1" :controls="false"  placeholder="请输入分值" style="width:340px"></el-input-number>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="attributesDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="goodsAttributeBtn()">确 定</el-button>
            </div>
        </el-dialog>
        <!--地区弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="addRegionTitle"
                   custom-class="green-dialog" :visible.sync="addressDialogVisible" width="650px">
            <el-scrollbar class="pop-up">
                <div class="area-box">
                    <el-checkbox v-model="checkedAllChina" @change="checkedAllChinaBtn">国内</el-checkbox>
                    <div class="area-list">
                        <div v-for="(item,index) in areaArr" :key="index" class="checkbox-label">
                            <el-checkbox v-model="item.checked" @change="checked=>areaChange(checked,index)">
                                {{item.name}}
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
            <div class="dialog-footer">
                <el-button style="margin-left: 30px" type="primary" @click="addCityBtn()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {adminKeyWordListKeyWord, adminGoodGetCategory,mergeSlice,uploadMaterial,operationProblemDelMaterial, operationProblemSaveShopGoodsManagement,operationProblemOpDetail} from '@/utils/apis'
    import { areaList } from '@/utils/area'
    import Upload from '@/utils/slice_upload';
    // import {sliceUpload} from "../../../../../utils/apis";
    import downloadBtn from '../../../../util/downloadBtn.vue';
    export default {
        components: {
            DownloadBtn: downloadBtn
        },  
        name: "GoodsRelease",
        props: ['practiceForm'],
        computed: {
            isFinish() {
                return Boolean(this.materialList.length) && this.materialList[0].url.length > 0;
            }
        },
        data() {
            return {
                // headerObj: {
                //     Authorization: localStorage.getItem('adminToken') || ''
                // },
                keyList: [],//关键词
                goodsReleaseForm: {
                    keyValue: '',//热门关键词
                    search: void 0,//搜索人数
                    rate: void 0,//点击率
                    conversion: void 0,//转化率
                    hits: void 0,//满分点击量
                    hits_score: void 0,//分值
                    category: '',//选择类目
                    category_score: void 0,//选择类目分值设置
                    goods_title: '',//商品标题
                    goods_title_score: void 0,//商品标题分值设置
                    selling_price: void 0,//销售价
                    sale_price_score: void 0,//销售价分值设置
                    market_price: void 0,//市场价
                    market_price_score: void 0,//市场价分值设置
                    stock: void 0,//库存
                    stock_score: void 0,//库存分值设置
                    goods_number: '',//商品货号
                    goods_number_score: void 0,//商品货号分值设置
                    freight_template_title: '',//模板名称
                    free_radio: 0,//是否包邮
                    charging_radio: 0,//计费规则
                    piece: void 0,//
                    element: void 0,
                    add_num: void 0,//
                    add_price: void 0,
                    // freight_score: void 0,
                    ruleUnit: '件',//规则单位
                    is_exempt_postage_score:void 0,//是否包邮分值
                    accounting_rules_score:void 0,//计费规则分值
                    default_shipping_score:void 0,//默认运费分值
                },
                //运费设置
                freightTable: [],
                freightTableIndex:-1,
                //商品属性
                goodsTable: [],
                goodsTableIndex:[],
                fileList: [],//上传素材
                fileListUrl:[],
                //上传素材
                uploadMaterialList: [],
                //素材列表
                materialList: [],
                // fileListUrl:localStorage.getItem('fileListUrl') || '',
                categoryDialogVisible: false,
                //商品类目列表
                goodsClassList: [],
                goodsClassValue: [],
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                goodsAttributesTitle: '',//新增商品属性
                //商品属性表单
                attributesForm: {
                    attribute: '',
                    content: '',
                    score: '',
                },
                formLabelWidth: '80px',
                attributesDialogVisible: false,//商品属性弹窗
                // 地区
                addRegionTitle: '',//地区弹窗标题
                addressDialogVisible: false,
                checkAll: false,
                checkedCities: [],
                // cityOptions: [],
                areaArr: [],
                isIndeterminate: true,//区显示隐藏
                checkedAllChina: false,//国内变量
                uploadInstance:null,
                percent:0,

            }
        },
        mounted() {
            this.getListKeyWord()
            //循环地区列表
            for (let i in areaList) {
                let ob = {
                    checked: false,
                    name: areaList[i].name
                }
                this.areaArr.push(ob)
            }
            if (this.$route.query.id) {
                this.getPracticeGoodsDetail();
            }
        },
        methods: {
            //商品发布详情
            getPracticeGoodsDetail() {
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.goodsReleaseForm.keyValue = res.data.hot_keywords_id;
                    this.goodsReleaseForm.search = res.data.search_num;
                    this.goodsReleaseForm.rate = res.data.click_rate;
                    this.goodsReleaseForm.conversion = res.data.conversion_rate;
                    this.goodsReleaseForm.hits = res.data.full_score_hits;
                    this.goodsReleaseForm.hits_score = res.data.full_score_hits_score;
                    this.goodsClassValue = res.data.goods_category_id;
                    if (this.goodsClassValue) {
                        this.getGoodsClassList();
                    }
                    this.goodsReleaseForm.category_score = res.data.goods_category_score;
                    this.goodsReleaseForm.goods_title = res.data.goods_title;
                    this.goodsReleaseForm.goods_title_score = res.data.goods_title_score;
                    this.goodsReleaseForm.selling_price = res.data.sale_price;
                    this.goodsReleaseForm.sale_price_score = res.data.sale_price_score;
                    this.goodsReleaseForm.market_price = res.data.market_price;
                    this.goodsReleaseForm.market_price_score = res.data.market_price_score;
                    this.goodsReleaseForm.stock = res.data.stock;
                    this.goodsReleaseForm.stock_score= res.data.stock_score;
                    this.goodsReleaseForm.goods_number = res.data.goods_code;
                    this.goodsReleaseForm.goods_number_score = res.data.goods_code_score;
                    this.goodsTable = res.data.goods_attribute;
                    this.goodsReleaseForm.freight_template_title = res.data.freight_template_title;
                    this.goodsReleaseForm.free_radio = res.data.is_exempt_postage;
                    this.goodsReleaseForm.charging_radio = res.data.accounting_rules;
                    this.goodsReleaseForm.piece = res.data.default_num;
                    this.goodsReleaseForm.element = res.data.default_price;
                    this.goodsReleaseForm.add_num = res.data.add_num;
                    this.goodsReleaseForm.add_price = res.data.add_price;
                    this.freightTable = res.data.freight_setting;
                    // this.goodsReleaseForm.freight_score = res.data.freight_score;
                    this.materialList = res.data.material_file;
                    this.uploadMaterialList = res.data.material_file;
                    this.percent = 100;
                    this.goodsReleaseForm.is_exempt_postage_score=res.data.is_exempt_postage_score;
                    this.goodsReleaseForm.accounting_rules_score=res.data.accounting_rules_score;
                    this.goodsReleaseForm.default_shipping_score=res.data.default_shipping_score;

                }).catch((err) => {
                    console.log(err);
                })
            },
            // 获取关键词列表
            getListKeyWord() {
                adminKeyWordListKeyWord().then((res) => {
                    this.keyList = res.data
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //选择类目
            selectCategoryBtn(val) {
                this.categoryDialogVisible = true;
                if (this.goodsClassList.length === 0) {
                    this.getGoodsClassList();
                }
            },
            //获取商品类目
            getGoodsClassList() {
                adminGoodGetCategory().then((res) => {
                    this.goodsClassList = res.data;
                    if (this.goodsClassValue) {
                        this.getGoodsClassName();
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //保存选择类目
            confirmCategoryBtn() {
                if (this.goodsClassValue.length < 3) {
                    this.$message.warning('请选到第三级类目！');
                    return;
                } else {
                    this.categoryDialogVisible = false;
                    this.getGoodsClassName();
                }
            },
            //拼接生成商品类目
            getGoodsClassName() {
                let one_name = '';
                let two_name = '';
                let three_name = '';
                this.goodsClassList.forEach(itemOne => {
                    if (itemOne.id === this.goodsClassValue[0]) {
                        one_name = itemOne.name + '/';
                        if (itemOne.children && itemOne.children.length > 0) {
                            itemOne.children.forEach(itemTwo => {
                                if (itemTwo.id === this.goodsClassValue[1]) {
                                    two_name = itemTwo.name + '/';
                                    if (itemTwo.children && itemTwo.children.length > 0) {
                                        itemTwo.children.forEach(itemThree => {
                                            if (itemThree.id === this.goodsClassValue[2]) {
                                                three_name = itemThree.name;
                                                this.goodsReleaseForm.category = one_name + two_name +three_name;
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                });
            },
            //添加商品基本属性
            goodsBtn() {
                this.attributesDialogVisible = true
                this.goodsAttributesTitle = '添加商品基本属性'
                this.goodsTableIndex = -1;
            },
            //编辑商品基本属性
            editGoods(row,index) {
                this.attributesDialogVisible = true
                this.goodsAttributesTitle = '编辑商品基本属性'
                this.attributesForm.attribute= row.attribute;
                this.attributesForm.content=row.content;
                this.attributesForm.score= row.score;
                this.goodsTableIndex = index;
            },
            // 商品基本属性确定事件
            goodsAttributeBtn() {
                if(this.attributesForm.attribute!==''&&this.attributesForm.content!==''&&this.attributesForm.score!==''){
                    let ob = {
                        attribute:this.attributesForm.attribute,
                        content:this.attributesForm.content,
                        score:this.attributesForm.score
                    }
                    if (this.goodsTableIndex>-1){
                        //    编辑
                        this.$set(this.goodsTable,this.goodsTableIndex,ob)
                    }else{
                        //    新增
                        this.goodsTable.push(ob);
                    }
                    this.attributesDialogVisible = false
                    this.resetForm()

                }else {
                    this.$message({
                        message: ('请输入基本属性值'),
                        type: 'warning',
                    })
                }
            },
            //删除商品基本属性
            deleteGoods(index) {
                this.goodsTable.splice(index, 1);
            },
            //是否包邮点击事件
            changeFreeRadio() {
                if (this.goodsReleaseForm.free_radio === 1) {
                    this.$confirm(`选择“卖家承担运费”后，所有区域的运费将设置为0元 且原运费设置无法恢复`, '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                    }).then(() => {
                        this.$message({
                            message: ('切换成功'),
                            type: 'success',
                        })
                    }).catch((cancel) => {
                        this.goodsReleaseForm.free_radio = 0;
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });
                    });
                }
            },
            //计费规则
            chargingRadio() {
                this.$confirm(`确定切换计费规则吗？，切换后，当前所设置的运费信息将被清空`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    this.$message({
                        message: ('切换成功'),
                        type: 'success',
                    })
                    if (this.goodsReleaseForm.charging_radio===0){
                        this.goodsReleaseForm.ruleUnit = '件'
                    }else {
                        this.goodsReleaseForm.ruleUnit = 'kg'
                    }
                }).catch(() => {
                    if (this.goodsReleaseForm.charging_radio===0){
                        this.goodsReleaseForm.charging_radio = 1;
                    }else {
                        this.goodsReleaseForm.charging_radio = 0;
                    }
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            //上传素材
            uploadFile(event) {
                let chunkSize = 1024 * 1024;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.materialList = []
                this.uploadMaterialList = [];
                this.materialList.push(obj);
                this.uploadMaterialList.push(obj);
                let index = 0;
                this.uploadSlice(index);
                this.$refs.uploadFile.value = null
                this.percent = 0
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.uploadMaterialList[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.uploadMaterialList[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        this.$message.success('删除成功');
                        this.materialList.splice(index, 1);
                        this.uploadMaterialList.splice(index, 1);
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //地区选择
            addRegion() {
                this.addRegionTitle = '选择地区'
                this.addressDialogVisible = true
            },
            // 编辑地区
            editRegion(city, index) {
                this.freightTableIndex = index;
                this.addRegionTitle = '编辑地区'
                this.addressDialogVisible = true;
                let arr = city.split(" ");
                for (let i in this.areaArr) {
                    for (let k in arr) {
                        if (arr[k] === this.areaArr[i].name) {
                            this.areaArr[i].checked = true;
                        }
                    }
                }
                let isAll = true;
                for(let i in this.areaArr){
                    if(!this.areaArr[i].checked){
                        isAll =false;
                        break;
                    }
                }
                this.checkedAllChina = isAll
            },
            // 全国
            checkedAllChinaBtn(val) {
                this.checkedAllChina = val;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = this.checkedAllChina;
                }
            },
            areaChange(e, index) {
                this.areaArr[index].checked = e;
                let isAll = true;
                for (let i in this.areaArr) {
                    if (!this.areaArr[i].checked) {
                        isAll = false;
                        break;
                    }
                }
                this.checkedAllChina = isAll;
            },
            // 地区确定事件
            addCityBtn() {
                let arr = [];
                for (let i in this.areaArr) {
                    if (this.areaArr[i].checked) {
                        arr = arr.concat(this.areaArr[i].name);
                    }
                }
                if (this.freightTableIndex > -1) {
                    this.freightTable[this.freightTableIndex].city = arr.join(' ');
                } else {
                    let ob = {
                        city: arr.join(' '),
                        first_thing: void 0,
                        first_cost: void 0,
                        add_thing: void 0,
                        add_cost: void 0,
                    }
                    this.freightTable.push(ob)
                }
                this.addressDialogVisible = false;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = false;
                }
                this.checkedAllChina = false;
                this.freightTableIndex = -1;
            },
            //运单删除
            deleteFreight(index) {
                this.freightTable.splice(index,1);
            },
            //重置表单
            resetForm() {
                this.$refs.attributesForm.resetFields();
                this.attributesForm = this.$options.data().attributesForm;
            },
            goBack(){
                this.$router.push('/practice/index')
            },
            //保存事件
            saveGoods() {
                let res = this.verificationData()
                if(!res){
                    return
                }
                let param = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    hot_keywords_id: this.goodsReleaseForm.keyValue,
                    search_num: this.goodsReleaseForm.search,
                    click_rate: this.goodsReleaseForm.rate,
                    conversion_rate: this.goodsReleaseForm.conversion,
                    full_score_hits: this.goodsReleaseForm.hits,
                    full_score_hits_score: this.goodsReleaseForm.hits_score,
                    // goods_category_id: this.category_id[2],
                    goods_category_score: this.goodsReleaseForm.category_score,
                    goods_title: this.goodsReleaseForm.goods_title,
                    goods_title_score: this.goodsReleaseForm.goods_title_score,
                    sale_price: this.goodsReleaseForm.selling_price,
                    sale_price_score: this.goodsReleaseForm.sale_price_score,
                    market_price: this.goodsReleaseForm.market_price,
                    market_price_score: this.goodsReleaseForm.market_price_score,
                    stock: this.goodsReleaseForm.stock,
                    stock_score: this.goodsReleaseForm.stock_score,
                    goods_code: this.goodsReleaseForm.goods_number,
                    goods_code_score: this.goodsReleaseForm.goods_number_score,
                    goods_attribute: this.goodsTable,
                    freight_template_title: this.goodsReleaseForm.freight_template_title,
                    is_exempt_postage: this.goodsReleaseForm.free_radio,
                    accounting_rules: this.goodsReleaseForm.charging_radio,
                    default_num: this.goodsReleaseForm.piece,
                    default_price: this.goodsReleaseForm.element,
                    add_num: this.goodsReleaseForm.add_num,
                    add_price: this.goodsReleaseForm.add_price,
                    freight_setting: this.freightTable,
                    // freight_score: this.goodsReleaseForm.freight_score,
                    material_file: this.uploadMaterialList,
                    is_exempt_postage_score:this.goodsReleaseForm.is_exempt_postage_score,
                    accounting_rules_score:this.goodsReleaseForm.accounting_rules_score,
                    default_shipping_score:this.goodsReleaseForm.default_shipping_score

                }
                if (this.goodsClassValue.length > 0) {
                    param.goods_category_id = this.goodsClassValue[this.goodsClassValue.length - 1];
                }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                }
                operationProblemSaveShopGoodsManagement(param).then((res) => {
                    if(res.code==200){
                        this.$message.success(res.msg)
                        this.$router.push('/practice/index')
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //数据验证
            verificationData(){
                if (!this.practiceForm.name) {
                    this.$message.warning('请填写名称！');
                    return false
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return false
                }
                // if (this.practiceForm.taskList.length === 0) {
                //     this.$message.warning('请添加任务目标！');
                //     return false
                // }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写操作要求！');
                    return false
                }
                // if (!this.practiceForm.taskAnalysis) {
                //     this.$message.warning('请填写任务分析！');
                //     return false
                // }
                // if (!this.practiceForm.taskOperation) {
                //     this.$message.warning('请填写任务操作！');
                //     return false
                // }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return false
                }
                if(!this.goodsReleaseForm.keyValue){
                    this.$message({type: 'warning', message: '请选择关键词！'});
                    return false
                }
                if(!this.goodsReleaseForm.search){
                    this.$message({type: 'warning', message: '请输入搜索人数！'});
                    return false
                }
                if(!this.goodsReleaseForm.rate){
                    this.$message({type: 'warning', message: '请输入点击率！'});
                    return false
                }
                if(!this.goodsReleaseForm.conversion){
                    this.$message({type: 'warning', message: '请输入转化率！'});
                    return false
                }
                if(!this.goodsReleaseForm.hits){
                    this.$message({type: 'warning', message: '请输入满分点击量！'});
                    return false
                }
                if(!this.goodsReleaseForm.hits_score){
                    this.$message({type: 'warning', message: '请输入满分点击量分值！'});
                    return false
                }
                if (this.goodsClassValue.length === 0) {
                    this.$message.warning('请选择商品类目！');
                    return false
                }
                if(!this.goodsReleaseForm.category_score){
                    this.$message({type: 'warning', message: '请输入商品上架类目分值！'});
                    return false
                }
                if(!this.goodsReleaseForm.goods_title){
                    this.$message({type: 'warning', message: '请输入商品标题！'});
                    return false
                }
                if(!this.goodsReleaseForm.goods_title_score){
                    this.$message({type: 'warning', message: '请输入商品标题分值！'});
                    return false
                }
                if(!this.goodsReleaseForm.selling_price){
                    this.$message({type: 'warning', message: '请输入销售价！'});
                    return false
                }
                if(!this.goodsReleaseForm.sale_price_score){
                    this.$message({type: 'warning', message: '请输入销售价分值！'});
                    return false
                }
                if(!this.goodsReleaseForm. market_price){
                    this.$message({type: 'warning', message: '请输入市场价！'});
                    return false
                }
                if(!this.goodsReleaseForm.market_price_score){
                    this.$message({type: 'warning', message: '请输入市场价分值！'});
                    return false
                }
                if(!this.goodsReleaseForm.stock){
                    this.$message({type: 'warning', message: '请输入库存！'});
                    return false
                }
                if(!this.goodsReleaseForm.stock_score){
                    this.$message({type: 'warning', message: '请输入库存分值！'});
                    return false
                }
                if(!this.goodsReleaseForm.goods_number){
                    this.$message({type: 'warning', message: '请输入商品货号！'});
                    return false
                }
                if(!this.goodsReleaseForm.goods_number_score){
                    this.$message({type: 'warning', message: '请输入商品货号分值！'});
                    return false
                }
                if(this.goodsTable.length == 0){
                    this.$message({type: 'warning', message: '请添加商品基本属性！'});
                    return false
                }
                if(!this.goodsReleaseForm.freight_template_title){
                    this.$message({type: 'warning', message: '请输入模板名称！'});
                    return false
                }
                if(!this.goodsReleaseForm.piece){
                    this.$message({type: 'warning', message: '请输入默认件数！'});
                    return false
                }
                if(this.goodsReleaseForm.element === undefined){
                    this.$message({type: 'warning', message: '请输入默认价格！'});
                    return false
                }

                if(!this.goodsReleaseForm.add_num){
                    this.$message({type: 'warning', message: '请输入增加数量！'});
                    return false
                }
                if(this.goodsReleaseForm.add_price === undefined){
                    this.$message({type: 'warning', message: '请输入增加价格！'});
                    return false
                }
                if (this.goodsReleaseForm.free_radio === 0) {
                    //买家承担运费，运费价格不能为0
                    if (parseInt(this.goodsReleaseForm.element) === 0) {
                      this.$message({type: 'warning', message: '请输入默认价格！'});
                        return false;
                    }
                    if (parseInt(this.goodsReleaseForm.add_price) === 0) {
                        this.$message.warning("请输入增加价格");
                        return false;
                    }
                }
                if(!this.freightTable){
                    this.$message({type: 'warning', message: '请输入运费设置！'});
                    return false
                }
                // if(!this.goodsReleaseForm.freight_score){
                //     this.$message({type: 'warning', message: '请输入运送设置分值！'});
                //     return false
                // }
                if(this.materialList.length == 0){
                    this.$message.warning('请上传素材！');
                    return false
                }

                if(this.goodsReleaseForm.is_exempt_postage_score===undefined){
                    this.$message({type:'warning',message:'请输入包邮分值'})
                    return false
                }
                if(this.goodsReleaseForm.accounting_rules_score===undefined){
                    this.$message({type:'warning',message:'请输入计费规则分值'})
                    return false
                }
                if(this.goodsReleaseForm.default_shipping_score===undefined){
                    this.$message({type:'warning',message:'请输入默认运费分值'})
                    return false
                }

                return true
            },

        }
    }
</script>

<style scoped lang="scss">
    .goods-release {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 30px;
        .goods-release-content {
            .key-item {
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
                .freight-box {
                    border: 1px solid #eee;
                    .freight-top {
                        background: #F6F6F6;
                        padding: 20px 47px;
                    }
                    .region-item {
                        display: flex;
                        span {
                            display: inline-block;
                            width: 40px;
                            color: #2461EF;
                            padding-left: 20px;
                            align-self: center;
                            cursor: pointer;
                        }
                    }
                    .add-freight {
                        cursor: pointer;
                        padding: 20px;
                        color: #2461EF;
                    }
                }
                .freight-title {
                    margin-bottom: 15px;
                }
                .freight-score {
                    margin-top: 12px;
                }
                .material-upload-area {
                    display: flex;
                    align-items: center;
                    .el-upload-tip {
                        color: #999;
                        padding-left: 10px;
                    }
                    ::v-deep .el-upload-list {
                        display: flex;
                        align-items: center;
                    }
                }
                ::v-deep .el-upload-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
                ::v-deep .el-upload-list__item {
                    width: 300px;
                    padding-left: 10px;
                }
            }
            .title-info {
                margin-top: 34px;
                .optimize-title {
                    font-size: 16px;
                    color: #333;
                    position: relative;
                    padding: 0 0 0 16px;
                    margin-bottom: 20px;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 16px;
                        background: #2DC079;
                        left: 0;
                        top: 4px;
                    }
                }
                .optimize-title-top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    .optimize-title01 {
                        font-size: 16px;
                        color: #333;
                        position: relative;
                        padding-left: 16px;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 4px;
                            height: 16px;
                            background: #2DC079;
                            left: 0;
                            top: 4px;
                        }
                    }
                }
                .upload-btn {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .btn {
                        width: 88px;
                        height: 38px;
                        background: #E7F6EF;
                        border: 1px solid #2DC079;
                        border-radius: 4px;
                        line-height: 38px;
                        text-align: center;
                        color: #2DC079;
                    }
                    input {
                        width: 88px;
                        height: 40px;
                        position: absolute;
                        top: 0;
                        cursor: pointer;
                        opacity: 0;
                    }
                    .text {
                        color: #999999;
                        font-size: 14px;
                        margin-left: 15px;
                    }
                }
                .upload-file-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    align-items: center;
                    .file-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 40px;
                        padding: 10px;
                        box-sizing: border-box;
                        color: #333333;
                        font-size: 14px;
                        &:hover {
                            cursor: pointer;
                            background-color: #F5F7FA;
                            .del-icon {
                                display: block;
                            }
                            .finish-icon {
                                display: none;
                            }
                        }
                        .file-name {
                            display: inline-block;
                            width: 1%;
                            flex: 1;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        i {
                            color: #2461EF;
                            margin: 0 10px;
                        }
                        .finish-icon {
                            color: #2DC079;
                            margin:0 10px;
                        }
                        .del-icon {
                            display: none;
                            color: #FF0000;
                            margin: 0 10px;
                        }
                        .el-progress {
                            margin-left: 20px;
                            width: 160px;
                        }
                    }
                }
            }
            ::v-deep.customTable th:first-child {
                border-left: none;
            }
            ::v-deep.customTable td:first-child {
                border-left: none;
            }
        }
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
        .goods-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .allot-class, .allot-class-two {
            &.allot-class-two .el-cascader-menu {
                width: 50%;
                &:last-of-type {
                    border-right: none;
                }
            }
            &.allot-class .el-cascader-menu {
                width: calc(100% / 3);
                &:nth-child(3) {
                    border-right: none;
                }
            }
            .el-cascader-menu {
                border-right: 1px solid #E4E7ED;
                .el-cascader-menu__wrap {
                    height: 286px;
                    .el-cascader-menu__list {
                        .el-cascader-node {
                            &:hover {
                                background: #E7F6EF;
                            }
                        }
                    }
                }
            }
        }
        .pop-up{
            height:300px;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .area-box {
                display: flex;
                .area-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 45px;
                    .checkbox-label {
                        width: 104px;
                        margin-bottom: 10px;
                        i {
                            cursor: pointer;
                        }
                    }
                }
                .checkbox-group {
                    margin: 10px 0;
                    ::v-deep .el-checkbox-group {
                        display: flex;
                        flex-wrap: wrap;
                        /*width: 100px;*/
                        /*background-color: #fff;*/
                        /*box-shadow: 2px 2px 2px 3px #eee;*/
                        padding-left: 10px;
                    }
                    /*::v-deep .el-checkbox {*/
                    /*    width: 58px;*/
                    /*}*/
                }
            }
        }
        .class-content {
            height: 287px;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            .class-cascader {
                height: 100%;
                border: none;
            }
        }
        .dialog-footer {
            margin-top: 20px;
            text-align: center;
        }
    }
</style>
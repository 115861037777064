<template>
  <div>
    <div class="line-item" style="margin-top: 30px">
      <el-button
        style="background: rgba(245, 110, 110, 0.5); color: #ffffff"
        @click="deleteData"
        >删除</el-button
      >
      <el-upload
        action="ecommerceDataReport/importStatisticalData"
        :headers="headerParam"
        :on-success="handleCoverSuccess"
        :on-error="imageError"
        :before-upload="beforImg"
        :show-file-list="false"
      >
        <el-button type="primary" style="margin: 0 10px">导入数据</el-button>
      </el-upload>
      <el-button type="primary" @click="exportPlacement">导出数据</el-button>
      <el-button type="primary" @click="download(0)">下载模板</el-button>
      <!-- <div class="left">数据中心名称</div>
            <div class="right">
                <el-select v-model="ruleForm.data_center_id" placeholder="请选择数据中心名称" filterable style="width: 360px">
                    <el-option v-for="(item, index) in dataCenterList" :key="`dataCenterList_${index}`" :label="item.date_center_tpl_name" :value="item.data_center_tpl_id"></el-option>
                </el-select>
            </div> -->
    </div>
    <div class="line-item">
      <el-table
        ref="multipleTable"
        :data="pageOrderList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '60px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ width: '120px', height: '40px' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="order_date" align="center" label="订单日期">
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input
              v-model="scope.row.order_date"
              :ref="`placementTable_order_date_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.order_date)"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="sell_amout"
          align="center"
          width="110"
          label="销售额（元）"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.sell_amout"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              :ref="`placementTable_sell_amout_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.sell_amout)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_num"
          align="center"
          width="110"
          label="订单数量"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.order_num"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              :ref="`placementTable_order_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.order_num)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_inc_num"
          align="center"
          width="110"
          label="用户增长"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.user_inc_num"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              :ref="`placementTable_user_inc_num_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.user_inc_num)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="average_order_price"
          align="center"
          label="平均订单金额"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.average_order_price"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="2"
              :ref="`placementTable_average_order_price_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.average_order_price)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="conversion_rate"
          align="center"
          label="转化率（%）"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.conversion_rate"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              :ref="`placementTable_conversion_rate_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.conversion_rate)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="refund_rate" align="center" label="退货率（%）">
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.refund_rate"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              :ref="`placementTable_refund_rate_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.refund_rate)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="consumer_satisfaction_rate"
          align="center"
          label="客户满意度"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.consumer_satisfaction_rate"
              :min="0"
              :max="5"
              :controls="false"
              :precision="1"
              :ref="`placementTable_consumer_satisfaction_rate_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.consumer_satisfaction_rate)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="advertising_investment"
          align="center"
          label="广告投入（元）"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.advertising_investment"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              :ref="`placementTable_advertising_investment_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.advertising_investment)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="roi" align="center" label="ROI">
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.roi"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              :ref="`placementTable_roi_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.roi)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="purchasing_cost"
          align="center"
          label="采购成本（元）"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.order_date }} -->
            <el-input-number
              v-model="scope.row.purchasing_cost"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              :ref="`placementTable_purchasing_cost_${scope.$index}`"
              style="width: 100%"
              class="input-error"
              @blur="saveItem(scope.row.purchasing_cost)"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              type="danger"
              :underline="false"
              @click.native.prevent="deleteRow(scope.$index, orderList)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <template slot="empty">
        <el-empty description="暂时没有数据" :image-size="200"></el-empty>
      </template>
    </div>
    <div class="pagination">
      <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="orderPage"
      :page-size="orderLimit"
      layout="prev, pager, next, jumper"
      :total="orderList.length || 0">
    </el-pagination>
    </div>
    <div class="addbutton">
      <el-button type="primary" @click="addEcommerceData">添加数据</el-button>
    </div>
    <div>
      <div class="title">
        <span class="left-icon"></span>
        <span class="text">销售趋势分析图</span>
      </div>
      <div>
        <el-input-number
          class="number-input"
          v-model="analysis_data.sale_trend_analysis_score"
          :precision="1"
          :controls="false"
          :min="0.5"
          :max="100.0"
          style="width: 250px"
        ></el-input-number>
        <span style="font-size: 14px; color: #333; margin-left: 10px">分</span>
      </div>
      <div class="uploadDown">
        <span class="upload">上传答案</span>
        <span class="download" @click="download(1)">下载模板</span>
      </div>
      <div class="upload-btn">
        <el-upload
          action="ecommerceDataReport/uploadAnalysisData"
          :data="{ type: 1 }"
          :headers="headerParam"
          :on-success="handleCoverSuccessAnswer1"
          :on-error="imageError"
          :before-upload="beforImg"
          :show-file-list="false"
        >
          <el-button type="primary" plain>点击上传</el-button>
        </el-upload>

        <span class="text">{{this.analysis_data.sale_trend_analysis_file.name?this.analysis_data.sale_trend_analysis_file.name:'文件限制100MB以内，支持扩展名：xls、xlsx'}}</span>
      </div>
    </div>
    <div>
      <div class="title">
        <span class="left-icon"></span>
        <span class="text">订单数量与用户增长对比分析图</span>
      </div>
      <div>
        <el-input-number
          class="number-input"
          v-model="analysis_data.order_num_comparison_analysis_score"
          :precision="1"
          :controls="false"
          :min="0.5"
          :max="100.0"
          style="width: 250px"
        ></el-input-number>
        <span style="font-size: 14px; color: #333; margin-left: 10px">分</span>
      </div>
      <div class="uploadDown">
        <span class="upload">上传答案</span>
        <span class="download" @click="download(2)">下载模板</span>
      </div>
      <div class="upload-btn">
        <el-upload
          action="ecommerceDataReport/uploadAnalysisData"
          :data="{ type: 2 }"
          :headers="headerParam"
          :on-success="handleCoverSuccessAnswer2"
          :on-error="imageError"
          :before-upload="beforImg"
          :show-file-list="false"
        >
          <el-button type="primary" plain>点击上传</el-button>
        </el-upload>

        <span class="text">{{this.analysis_data.order_num_comparison_analysis_file.name?this.analysis_data.order_num_comparison_analysis_file.name:'文件限制100MB以内，支持扩展名：xls、xlsx'}}</span>
      </div>
    </div>
    <div>
      <div class="title">
        <span class="left-icon"></span>
        <span class="text">销售额与订单数量对比分析图</span>
      </div>
      <div>
        <el-input-number
          class="number-input"
          v-model="analysis_data.sell_amout_comparison_analysis_score"
          :precision="1"
          :controls="false"
          :min="0.5"
          :max="100.0"
          style="width: 250px"
        ></el-input-number>
        <span style="font-size: 14px; color: #333; margin-left: 10px">分</span>
      </div>
      <div class="uploadDown">
        <span class="upload">上传答案</span>
        <span class="download" @click="download(3)">下载模板</span>
      </div>
      <div class="upload-btn">
        <el-upload
          action="ecommerceDataReport/uploadAnalysisData"
          :data="{ type: 3 }"
          :headers="headerParam"
          :on-success="handleCoverSuccessAnswer3"
          :on-error="imageError"
          :before-upload="beforImg"
          :show-file-list="false"
        >
          <el-button type="primary" plain>点击上传</el-button>
        </el-upload>

        <span class="text">{{ this.analysis_data.sell_amout_comparison_analysis_file.name? this.analysis_data.sell_amout_comparison_analysis_file.name:'文件限制100MB以内，支持扩展名：xls、xlsx'}}</span>
      </div>
    </div>

    <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
      <el-button type="primary" @click="submitForm">保存</el-button>
      <el-button @click="resetForm">取消</el-button>
    </div>
    <!-- 添加数据的弹框 -->
    <el-dialog
      :title="addScoreTitle"
      :close-on-click-modal="false"
      :visible.sync="addDataDialog"
      width="730px"
      @close="handleCloseScoreDialog"
      custom-class="green-dialog add-task-dialog"
      class="demo-form-inline"
    >
      <div class="upload-file-content">
        <el-form
          :inline="true"
          :rules="addDatarules"
          :model="addDataForm"
          ref="addDataFormRef"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="订单日期" prop="index">
            <el-date-picker
              v-model="addDataForm.order_date"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="销售额" prop="sell_amout">
            <el-input-number
              v-model="addDataForm.sell_amout"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item label="订单数量" prop="order_num">
            <el-input-number
              v-model="addDataForm.order_num"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="用户增长" prop="user_inc_num">
            <el-input-number
              v-model="addDataForm.user_inc_num"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item label="平均订单金额" prop="average_order_price">
            <el-input-number
              v-model="addDataForm.average_order_price"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="2"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="订单转化率" prop="conversion_rate">
            <el-input-number
              v-model="addDataForm.conversion_rate"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="退货率" prop="refund_rate">
            <el-input-number
              v-model="addDataForm.refund_rate"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="客户满意度" prop="consumer_satisfaction_rate">
            <el-input-number
              v-model="addDataForm.consumer_satisfaction_rate"
              :min="0"
              :max="5"
              :controls="false"
              :precision="1"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="广告投入" prop="advertising_investment">
            <el-input-number
              v-model="addDataForm.advertising_investment"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="采购成本" prop="purchasing_cost">
            <el-input-number
              v-model="addDataForm.purchasing_cost"
              :min="0"
              :max="999999999"
              :controls="false"
              :precision="0"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="ROI" prop="roi">
            <el-input-number
              v-model="addDataForm.roi"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              class="input-number"
            >
            </el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="handleCloseScoreDialog">取 消</el-button>
        <el-button type="primary" @click="submitAddData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  dataCentorTplGetDataCenterTpl,
  operationProblemCommerceProductionData,
  operationProblemGetOneInfo,
  operationProblemOpDetail,
  ecommerceDataReportAdminSave,
  ecommerceDataReportImportStatisticalData,
  ecommerceDataReportExportStatisticalData,
} from "@/utils/apis.js";
import axios from "axios";
export default {
  props: {
    practiceForm: {
      type: Object,
      default: () => {},
    },
  },
  watch:{
    orderPage(newValue,oldValue){
      this.getPageOrderList()
    }
  },
  data() {
    return {
      fileList: [],
      headerParam: {
        Authorization: localStorage.getItem("adminToken") || "",
      },
      hosturl: localStorage.getItem("hosturl"),
      // ruleForm: {
      //     data_center_id: null,
      //     score: void 0,
      // },
      // dataCenterList: [], // 数据中心名称列表
      table: [
        {
          label: "",
          value: "",
          type: "",
        },
      ],
      // 导入数据文件
      excelFile: {},
      placement_excel: "",
      //评分标准
      analysis_data: {
        sale_trend_analysis_file: {
          url: "",
          name: "",
        }, //销售趋势分析图-文件
        sale_trend_analysis_score: 0, //销售趋势分析图-分值
        order_num_comparison_analysis_file: {
          url: "",
          name: "",
        }, //订单数量与用户增长对比分析图-文件
        order_num_comparison_analysis_score: 0, //订单数量与用户增长对比分析图-分值
        sell_amout_comparison_analysis_file: {
          url: "",
          name: "",
        }, //销售额与订单数量对比分析图-文件
        sell_amout_comparison_analysis_score: 0, //销售额与订单数量对比分析图-分值
      },
      orderList: [], //电子商务数据表格数据
      multipleSelection: [], //选中要删除或者导出的数组

      addDataDialog: false, //添加数据弹窗
      addScoreTitle: "添加数据",
      addDataForm: {
        order_date: "",
        sell_amout: void 0,
        order_num: void 0,
        user_inc_num: void 0,
        average_order_price: void 0,
        conversion_rate: void 0,
        refund_rate: void 0,
        consumer_satisfaction_rate: void 0,
        advertising_investment: void 0,
        purchasing_cost: void 0,
        roi: void 0,
      }, //添加评分标准表单
      addDatarules: {}, //表单的校验规则
      orderPage: 1,
      orderLimit: 10,
      pageOrderList: [],
      
    };
  },
  mounted() {
    // this.getDataCenterList()
    // console.log("this.$route.query.id,", this.$route.query.id);
    if (this.$route.query.id) {
      this.initDetail();
      // this.getPageOrderList()
    }
  },
  methods: {
    // 导入数据
    handleCoverSuccess(response) {
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // response.data.forEach((item) => {
        //   this.orderList.push(item);
        // });
        this.orderList = response.data;
        
        this.getPageOrderList()
        

        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },
    // 分页获取数据
    getPageOrderList() {
      this.pageOrderList = this.orderList.slice(
        (this.orderPage - 1) * this.orderLimit,
        this.orderPage * this.orderLimit
       
      );
    },
    handleCurrentChange(val){
      this.orderPage=val
      this.getPageOrderList()
    },
    beforImg(file) {
      file;
    },
    imageError(err) {
      // console.log(err);
    },
    // 编辑获取详情
    initDetail() {
      let params = {
        id: this.$route.query.id,
      };
      operationProblemOpDetail(params)
        .then((res) => {
          // console.log(res, "编辑");
          this.orderList = res.data.statistical_data;
          this.analysis_data = res.data.analysis_data;
          this.getPageOrderList()
        })
        .catch((err) => {});
    },
    // getDataCenterList() {
    //     dataCentorTplGetDataCenterTpl({paging: 0}).then((res) => {
    //         this.dataCenterList = res.data.list
    //     }).catch((err) => {})
    // },

    // 是否多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    // 删除选中的数据
    deleteData() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请先选中要删除的数据！");
      } else {
        this.$confirm("此操作将永久删除选中的数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.multipleSelection.forEach((item) => {
              let index = this.orderList.findIndex(
                (item1) =>
                  item.order_date === item1.order_date &&
                  item.sell_amout === item1.sell_amout &&
                  item.order_num === item1.order_num &&
                  item.user_inc_num === item1.user_inc_num &&
                  item.average_order_price === item1.average_order_price &&
                  item.conversion_rate === item1.conversion_rate &&
                  item.refund_rate === item1.refund_rate &&
                  item.consumer_satisfaction_rate ===
                    item1.consumer_satisfaction_rate &&
                  item.advertising_investment ===
                    item1.advertising_investment &&
                  item.purchasing_cost === item1.purchasing_cost &&
                  item.roi === item1.roi
              );
              // console.log(index);
              this.orderList.splice(index, 1);
              if(this.pageOrderList.length==0 && this.orderPage>1){
                this.orderPage=this.orderPage-1
              }else{
                this.getPageOrderList()
              }
            
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 导出数据
    exportPlacement() {
      if (!this.$route.query.id) {
        this.$message.warning("请先保存试题！");
      } else {
        let params = {
          id: this.$route.query.id,
        };
        let _this = this;
        axios({
          method: "get",
          params: params,
          url: "ecommerceDataReport/exportStatisticalData",
          responseType: "blob",
          headers: { Authorization: _this.teacherToken },
        }).then((res) => {
          let filename = "电子商务数据报表.xlsx";
          let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        });
      }
    },
    // 删除该条数据
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          rows.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          if(this.pageOrderList.length==0 && this.orderPage>1){
            this.orderPage=this.orderPage-1
          
          }else{
            this.getPageOrderList()
          }
         
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加数据
    addEcommerceData() {
      this.addDataDialog = true;
    },
    // 上传答案
    handleCoverSuccessAnswer1(response) {
      //销售趋势分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.sale_trend_analysis_file.url = response.data.url;
        this.analysis_data.sale_trend_analysis_file.name = response.data.name;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },

    handleCoverSuccessAnswer2(response) {
      //订单数量与用户增长对比分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.order_num_comparison_analysis_file.url =
          response.data.url;
        this.analysis_data.order_num_comparison_analysis_file.name =
          response.data.name;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },
    handleCoverSuccessAnswer3(response) {
      //销售额与订单数量对比分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.sell_amout_comparison_analysis_file.url =
          response.data.url;
        this.analysis_data.sell_amout_comparison_analysis_file.name =
          response.data.name;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },
    // 下载模板
    download(type) {
      let params = {
        type: type,
      };
      let _this = this;
      axios({
        method: "get",
        params: params,
        url: "ecommerceDataReport/downloadTemplate",
        responseType: "blob",
        headers: { Authorization: _this.teacherToken },
      })
        .then((res) => {
          let filename = "";
          switch (type) {
            case 0:
              filename = "电子商务数据报表模板.xlsx";
              break;
            case 1:
              filename = "销售趋势分析图模板.xlsx";
              break;
            case 2:
              filename = "订单数量与用户增长对比分析图模板.xlsx";
              break;
            case 3:
              filename = "销售额与订单数量对比分析图模板.xlsx";
              break;
          }
          let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    uploadFile(event) {},
    handleBeforeUpload(file) {
      const maxSize = 100 * 1024 * 1024; // 10MB，根据你的需求设置最大文件大小
      if (file.size > maxSize) {
        ElMessage({
          message: `文件大小超过了${maxSize / (1024 * 1024)}MB的限制`,
          type: "warning",
        });
        return false;
      }
    },
    //关闭评分标准弹窗
    handleCloseScoreDialog() {
      this.addDataDialog = false;
      this.resetAddDataForm();
    },
    resetAddDataForm() {
      this.addDataForm = {
        order_date: "",
        sell_amout: void 0,
        order_num: void 0,
        user_inc_num: void 0,
        average_order_price: void 0,
        conversion_rate: void 0,
        refund_rate: void 0,
        consumer_satisfaction_rate: void 0,
        advertising_investment: void 0,
        purchasing_cost: void 0,
        roi: void 0,
      };
    },
    //保存添加的数据
    submitAddData() {
      if (!this.addDataForm.order_date) {
        return this.$message.warning("请填写订单日期！");
      }
      if (!this.addDataForm.sell_amout) {
        return this.$message.warning("请填写销售额！");
      }
      if (!this.addDataForm.order_num) {
        return this.$message.warning("请填写订单数量！");
      }
      if (!this.addDataForm.user_inc_num) {
        return this.$message.warning("请填写用户增长！");
      }
      if (!this.addDataForm.average_order_price) {
        return this.$message.warning("请填写平均订单金额！");
      }
      if (!this.addDataForm.conversion_rate) {
        return this.$message.warning("请填写订单转化率！");
      }
      if (!this.addDataForm.conversion_rate) {
        return this.$message.warning("请填写！");
      }
      if (!this.addDataForm.refund_rate) {
        return this.$message.warning("请填写退货率！");
      }
      if (!this.addDataForm.consumer_satisfaction_rate) {
        return this.$message.warning("请填写客户满意度！");
      }
      if (!this.addDataForm.advertising_investment) {
        return this.$message.warning("请填写广告投入！");
      }
      if (!this.addDataForm.purchasing_cost) {
        return this.$message.warning("请填写采购成本！");
      }
      if (!this.addDataForm.roi) {
        return this.$message.warning("请填写ROI！");
      }
      this.orderList.push(this.addDataForm);
      this.getPageOrderList()
      this.addDataDialog = false;
      this.resetAddDataForm();
    },

    // 保存试题
    submitForm() {
      if (!this.practiceForm.name) {
        return this.$message.warning("请填写名称！");
      }
      if (!this.practiceForm.time) {
        return this.$message.warning("请输入考核时间");
      }
      if (this.practiceForm.classValue.length === 0) {
        return this.$message.warning("请选择分类！");
      }
      if (this.practiceForm.taskList.length === 0) {
        return this.$message.warning("请添加任务目标！");
      }
      if (!this.practiceForm.taskBackground) {
        // return this.$message.warning('请填写任务背景！');
        return this.$message.warning("请填写操作要求！");
      }
      if (!this.practiceForm.taskAnalysis) {
        return this.$message.warning("请填写任务分析！");
      }
      if (!this.practiceForm.taskOperation) {
        return this.$message.warning("请填写任务操作！");
      }
      if (this.practiceForm.scoreList.length === 0) {
        return this.$message.warning("请添加评分标准！");
      }
      // 导入数据开始
      if (!this.orderList.length) {
        return this.$message.warning("请添加电子商务数据！");
      }
      if (!this.analysis_data.sale_trend_analysis_score) {
        return this.$message.warning("请填写销售趋势分析分值！");
      }
      if (!this.analysis_data.sale_trend_analysis_file.url) {
        return this.$message.warning("请上传销售趋势分析文件！");
      }
      if (!this.analysis_data.order_num_comparison_analysis_score) {
        return this.$message.warning("请填写销售趋势分析分值！");
      }
      if (!this.analysis_data.order_num_comparison_analysis_file.url) {
        return this.$message.warning("请上传订单数量与用户增长对比分析文件！");
      }
      if (!this.analysis_data.sell_amout_comparison_analysis_score) {
        return this.$message.warning("请填写销售趋势分析分值！");
      }
      if (!this.analysis_data.sell_amout_comparison_analysis_file.url) {
        return this.$message.warning("请上传销售额与订单数量对比分析图！");
      }

      // if (!this.ruleForm.data_center_id) {
      //     return this.$message.warning('请选择数据中心名称！');
      // }
      // if (!this.ruleForm.score) {
      //     return this.$message.warning('请输入分值！');
      // }

      let params = {
        op_name: this.practiceForm.name,
        exam_module_id: this.practiceForm.module_level_three_value,
        category_id:
          this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
        assess_time: this.practiceForm.time,
        task_goal: this.practiceForm.taskList,
        task_background: this.practiceForm.taskBackground,
        task_analysis: this.practiceForm.taskAnalysis,
        task_operation: this.practiceForm.taskOperation,
        scoring_criteria: this.practiceForm.scoreList,
        analysis_data: this.analysis_data,
        statistical_data: this.orderList,
        // data_center_id: this.ruleForm.data_center_id,
        // score: this.ruleForm.score,
      };
      if (this.$route.query.id) {
        params.id = Number(this.$route.query.id);
      }
      // console.log(params, "params");

      // operationProblemCommerceProductionData(params).then((res) => {
      //     this.$message.success(res.msg)
      //     this.$router.push('/practice/index')
      // }).catch((err) => {})
      // ecommerceDataReportAdminSave(params).then(res=>{
      //     console.log(res,'save');
      // })
      ecommerceDataReportAdminSave(params)
        .then((res) => {
          // console.log(res);
          if(res.code==200){
            this.$message.success(res.msg);
          this.$router.push("/practice/index");
        }
          // }else{
          //   this.$message.success(res.msg);
          // }
         
        })
        .catch((err) => {});
    },
    //失焦事件
    saveItem(val) {
      if (val === "" || val === null) {
        this.$message.warning("请填写相关数据");
        return false;
      }
    },
    // 重置试题
    resetForm() {
      // this.ruleForm = {
      //     data_center_id: null,
      //     score: void 0,
      // }
      this.$emit("update:practiceForm", {
        ...this.practiceForm,
        name: "",
        time: "",
        module_level_one_value: "",
        module_level_tow_value: "",
        module_level_three_value: "",
        classValue: [],
        taskList: [],
        taskBackground: "",
        taskAnalysis: "",
        taskOperation: "",
        scoreList: [],
      });
    },
    handleCurrentChange(val){
      this.orderPage=val
      this.getPageOrderList()
    }
  },
};
</script>

<style scoped lang="scss">
.line-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .left {
    width: 100px;
    margin-right: 10px;
    color: #333;
    font-size: 16px;
    text-align: right;
  }
}
.el-table {
  border: 1px solid #e5e5e5;
}
.addbutton {
  margin: 20px 0;
}
.title {
  margin-bottom: 15px;
  font-size: 16px;

  .left-icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background-color: #2dc079;
    margin-right: 6px;
    border-radius: 2px;
  }
}
.uploadDown {
  margin-top: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  .download {
    color: #2461ef;
    margin-left: 40px;
  }
}
.upload-btn {
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  align-items: center;
  .btn {
    width: 88px;
    height: 38px;
    background: #e7f6ef;
    border: 1px solid #2dc079;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    color: #2dc079;
  }
  input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
  .text {
    color: #999999;
    font-size: 14px;
    margin-left: 15px;
  }
  ::v-deep .input-error {
    &.isError {
      .el-input__inner {
        border-color: #fd4446;
      }
    }
  }
}
.el-date-editor.el-input {
  width: 180px !important;
}
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
::v-deep .imgBox{
  width: 300px;
  display: flex;
}

</style>
<template>
    <div class="promote-content">
           <div class="content-item">
                <div class="content-title">基本信息</div>
                    <div class="item-content">
                        <div class="item-title"><el-button type="primary" style="margin-left: 130px" @click="addVideo">添加视频</el-button></div>
                    </div>
                    <div class="video" v-for="(item,index) in video_list" :key="index">
                
                        <div class="item-content">
                        <div class="item-title">上传视频</div>
                       
                        <div class="video_k" @click="videoFun(index,item)" style="position: relative;">
                            <i  class="el-icon-plus avatar-uploader-icon"></i>
                            <!-- 进度条 -->
                            <img v-if="item.imgShow ==1" :src="item.cover" class="videoImg">
                            <span v-if="item.imgShow ==1" style="position: absolute;width: 100px;left: 27px;color: #fff;top: 47px;">重新选择</span>
                            <el-progress v-if="id == undefined" :percentage="item.videoPercent"></el-progress>
                            <el-progress v-if="id != undefined && item?.showPercent == true" :percentage="item.videoPercent"></el-progress>
                        </div>

                         <input class="file-upload-btn" ref="videoFile" :id="'videoFile'+index" type="file" git
                          accept="video/mp4, .mov" title=""
                          @change="changeVideoFile($event,item,index)"
                      >
                        
                        <div style="margin-left: 30px">
                            <span class="input-text">分值</span>
                            <el-input class="item-input mini" ref="video_input" v-model="item.score"
                                placeholder="请输入分值" v-one-number></el-input>
                            <span class="input-text">分</span>
                        </div>
                          <div class="remove-button">
                              <el-button type="danger" icon="el-icon-delete" @click="remove(index)"></el-button>
                          </div>
                    </div>
                     
                </div>
                <div class="item-content" style="margin-top: 50px;">
                    <div class="item-title">标题</div>
                    <el-input class="item-input max"  v-model="videoFormData.label"
                           placeholder="请输入标题(0-200个字)" ></el-input>
                    <div style="margin-left: 30px">
                        <span class="input-text">分值</span>
                        <el-input class="item-input mini"  v-model="videoFormData.labelScore"
                            placeholder="请输入分值" ></el-input>
                        <span class="input-text">分</span>
                    </div> 
                </div>
                <div class="item-content" style="align-items: baseline">
                    <div class="item-title">描述</div>
                    <el-input class="item-input max" type="textarea"  :rows="8" v-model="videoFormData.describe"
                              placeholder="请输入5-1000个字的描述" ></el-input>
                    <div style="margin-left: 30px">
                        <span class="input-text">分值</span>
                        <el-input class="item-input mini"  v-model="videoFormData.describeScore"
                            placeholder="请输入分值" ></el-input>
                        <span class="input-text">分</span>
                    </div> 
                </div>
                 <div class="item-content">
                    <div class="item-title">本文目标人群</div>
                      <el-select  v-model="videoFormData.crowd"  placeholder="请选择" style="width: 375px" clearable >
                        <el-option v-for="item in crowdListEn" :key="item.value" :label="item.label" :value="item.value" v-if="module_id == 64"></el-option>
                        <el-option v-for="item in crowdList" :key="item.value" :label="item.label" :value="item.value" v-if="module_id != 64"></el-option>
                    </el-select>
                    <div style="margin-left: 30px">
                        <span class="input-text">分值</span>
                        <el-input class="item-input mini"  v-model="videoFormData.crowdScore"
                            placeholder="请输入分值" ></el-input>
                        <span class="input-text">分</span>
                    </div> 
                </div>
           </div>
        <!-- <div class="content-item">
            <div class="item-content">
                <div class="item-title">软文推广分值</div>
                <el-input class="item-input medium" ref="article_input" v-model="formData.article_promote_score"
                          placeholder="请输入分值" v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">短视频推广分值</div>
                <el-input class="item-input medium" ref="video_input" v-model="formData.video_promote_score"
                          placeholder="请输入分值" v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
        </div>
        <div class="content-item">
            <div class="content-title">用户分析</div>
            <div class="item-content">
                <div class="item-title">年龄范围</div>
                <el-input class="item-input mini" ref="user_analysis_age_begin"
                          v-model="formData.user_analysis_age_begin"></el-input>
                <div class="divide"></div>
                <el-input class="item-input mini" ref="user_analysis_age_end"
                          v-model="formData.user_analysis_age_end"></el-input>
                <span class="input-text front">分值</span>
                <el-input class="item-input tiny" ref="user_analysis_age_score"
                          v-model="formData.user_analysis_age_score" v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">地域分布</div>
                <el-input class="item-input max" ref="user_analysis_region_distribution"
                          v-model="formData.user_analysis_region_distribution"
                          placeholder="多个地域用“#”分开"></el-input>
                <span class="input-text front">分值</span>
                <el-input class="item-input tiny" ref="user_analysis_region_distribution_score"
                          v-model="formData.user_analysis_region_distribution_score"
                          v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">性别比例</div>
                <span class="input-text sex">男</span>
                <el-input class="item-input sex" @input="GenderMale" ref="user_analysis_man_ratio"
                          v-model="formData.user_analysis_man_ratio"
                          v-one-number></el-input>
                <span class="input-text sex">女</span>
                <el-input class="item-input sex" @input="GenderFemale" ref="user_analysis_woman_ratio"
                          v-model="formData.user_analysis_woman_ratio"
                          v-one-number></el-input>
                <span class="input-text front">分值</span>
                <el-input class="item-input tiny" ref="user_analysis_gender_score"
                          v-model="formData.user_analysis_gender_score" v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">款式喜好</div>
                <el-input class="item-input max" ref="user_analysis_style_preference"
                          v-model="formData.user_analysis_style_preference"
                          placeholder="多个款式用“#”分开"></el-input>
                <span class="input-text front">分值</span>
                <el-input class="item-input tiny" ref="user_analysis_style_preference_score"
                          v-model="formData.user_analysis_style_preference_score"
                          v-one-number></el-input>
                <span class="input-text">分</span>
            </div>
        </div>
        <div class="content-item">
            <div class="content-title">数据分析</div>
            <div class="item-content">
                <div class="item-title">性别比例</div>
                <span class="input-text sex">男</span>
                <el-input class="item-input sex" ref="data_analysis_man_ratio" @input="GenderMan"
                          v-model="formData.data_analysis_man_ratio"
                          v-one-number></el-input>
                <span class="input-text sex">女</span>
                <el-input class="item-input sex" ref="data_analysis_woman_ratio" @input="GenderWoman"
                          v-model="formData.data_analysis_woman_ratio"
                          v-one-number></el-input>
            </div>
            <div class="item-content" v-for="(item,index) in formData.data_analysis_age" :key="index">
                <div class="item-title" v-if="index === 0">年龄范围</div>
                <div class="item-title" v-else></div>
                <el-input class="item-input mini" :ref="`age_begin_${index}`"
                          v-model="item.age_begin"></el-input>
                <div class="divide"></div>
                <el-input class="item-input mini" :ref="`age_end_${index}`" v-model="item.age_end"></el-input>
                <span class="input-text front">比例</span>
                <el-input class="item-input tiny" :ref="`data_analysis_age_ratio_${index}`"
                          @change="sumAll(formData.data_analysis_age,index)"
                          v-model="item.ratio" v-one-number></el-input>
                <span class="input-text">%</span>
            </div>
            <div class="item-content" v-for="(item,index) in formData.data_analysis_region_distribution">
                <div class="item-title" v-if="index === 0">地区分布</div>
                <div class="item-title" v-else></div>
                <el-input class="item-input max" :ref="`province_${index}`" v-model="item.province"
                          placeholder="请输入省份"></el-input>
                <span class="input-text front">比例</span>
                <el-input class="item-input tiny" :ref="`data_analysis_region_distribution_ratio_${index}`"
                          @change="sumAll(formData.data_analysis_region_distribution,index)"
                          v-model="item.ratio" v-one-number></el-input>
                <span class="input-text">%</span>
            </div>
            <div class="item-content" v-for="(item,index) in formData.data_analysis_style_preference">
                <div class="item-title" v-if="index === 0">款式偏好</div>
                <div class="item-title" v-else></div>
                <el-input class="item-input max" :ref="`style_${index}`" v-model="item.style"
                          placeholder="请输入款式"></el-input>
                <span class="input-text front">比例</span>
                <el-input class="item-input tiny" :ref="`data_analysis_style_preference_ratio_${index}`"
                          @change="sumAll(formData.data_analysis_style_preference,index)"
                          v-model="item.ratio"
                          v-one-number></el-input>
                <span class="input-text">%</span>
            </div>
        </div>
         -->
        
        <div class="content-item">
            <div class="content-title">上传素材</div>
            <div class="item-content">
                <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                       accept="application/zip, .rar" title=""
                       @change="changeFile($event)">
                <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G</span>
            </div>
        </div>
        <div class="content-item">
            <div class="item-content">
                <div class="upload-file-list">
                    <div class="file-item" v-for="(item, index) in materialList">
                        <i class="iconfont">&#xe60b;</i>
                        <span class="file-name" :title="item.name">{{item.name}}</span>
                        <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                        <i class="iconfont finish-icon" v-show="percent == 100">&#xe654;</i>
                        <i class="iconfont del-icon" @click="delMaterial(item, index)" v-show="percent == 100">&#xe651;</i>
                    </div>
                    <download-btn :status="isFinish" :list="materialList"></download-btn>
                </div>
            </div>
        </div>
        <div class="footer-wrapper">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="reset">取消</el-button>
        </div>
    </div>
</template>

<script>
    import oneNumber from "../../../../directive/index";
    import Upload from '@/utils/slice_upload';
    import downloadBtn from "../../../util/downloadBtn.vue";
    import {
        uploadMaterial,
        uploadVideo,
        SaveArticlePromote,
        operationProblemDelMaterial,
        mergeSlice,
        operationProblemOpDetail
    } from '@/utils/apis'

    export default {
        name: "softPromote",
        directives: {
            oneNumber
        },
        props: ['practiceForm', 'module_id'],
        components: {
            downloadBtn
        },
        computed: {
            isFinish() {
                return Boolean(this.materialList.length) && this.materialList[0].url.length
            }
        },
        data() {
            return {
                videoForm:{
                    Video:'',
                    videoUploadPercent:'',
                    videoUploadId:'',
                },
                videoFlag:false,
                val:'',
                mask: require("../../../../assets/default.png"),
                video_list:[
                    {   
                        imgShow:0,
                        cover:require('../../../../assets/default.png'),
                        url:'',
                        score:'',
                        videoPercent:0
                    },
                    {   
                         imgShow:0,
                        cover:require('../../../../assets/default.png'),
                        url:'',
                        score:'',
                        videoPercent:0
                    }, 
                 
                ],
                sign:'',
                video_score:[],
                videoFormData:{
                    label:'',
                    labelScore:'',
                    describe:'',
                    describeScore:'',
                    crowd:null,
                    crowdScore:''
                },
                crowdList:[
                    {
                        value:0,
                        label:'手机'
                    },
                    {
                        value:1,
                        label:'美搭'
                    },
                     {
                        value:2,
                        label:'型男'
                    },
                     {
                        value:3,
                        label:'数码'
                    },
                     {
                        value:4,
                        label:'美容'
                    },
                     {
                        value:5,
                        label:'母婴'
                    },
                     {
                        value:6,
                        label:'旅行'
                    },
                     {
                        value:7,
                        label:'居家'
                    },
                    {
                        value:8,
                        label:'鞋靴'
                    },
                     {
                        value:9,
                        label:'家用电器'
                    },
                     {
                        value:10,
                        label:'电脑办公'
                    },
                ],
                crowdListEn:[
                    {
                        value:0,
                        label:'Mobile phone'
                    },
                    {
                        value:1,
                        label:'Beauty matching'
                    },
                     {
                        value:2,
                        label:'Stylish men'
                    },
                     {
                        value:3,
                        label:'Digital'
                    },
                     {
                        value:4,
                        label:'Cosmetology'
                    },
                     {
                        value:5,
                        label:'Maternal and infant'
                    },
                     {
                        value:6,
                        label:'Travel'
                    },
                     {
                        value:7,
                        label:'Stay at home'
                    },
                    {
                        value:8,
                        label:'Shoes and boots'
                    },
                     {
                        value:9,
                        label:'Household appliances'
                    },
                     {
                        value:10,
                        label:'Computer office'
                    },
                ],
                video_promote_score:[],
                video_item:'',
                formData: {
                    // user_analysis_man_ratio: '',
                    // user_analysis_woman_ratio: '',
                    // data_analysis_man_ratio: '',
                    // data_analysis_woman_ratio: '',
                    // data_analysis_age: [],
                    // data_analysis_region_distribution: [],
                    // data_analysis_style_preference: [],
                    material_file: [],
                    // article_promote_score: "",
                    video_promote_score: "",
                    // user_analysis_age_begin: "",
                    // user_analysis_age_end: "",
                    // user_analysis_age_score: "",
                    // user_analysis_gender_score: "",
                    // user_analysis_region_distribution: "",
                    // user_analysis_region_distribution_score: "",
                    // user_analysis_style_preference: "",
                    // user_analysis_style_preference_score: "",
                },
                //素材列表
                materialList: [],
                percent: 0,
                videoPercent:0,
                id: "",
                uploadInstance: null,
                uploadVideo:null,
            }
        },
        created() {
            // this.fillData();
            this.id = this.$route.query.id;
            if (this.id !== undefined) {
                this.getSoftPromotion()
            }
        },


        methods: {
            remove(index) {
                this.$confirm('确定删除该组件么？删除组件，则会把已上传的视频一起删除','删除上传组件',{
                  cancelButtonText: '取消',
                  confirmButtonText: '删除'
                }).then(() => {
                  this.video_list.splice(index, 1);
                }).catch(err => {
                  console.log(err);
                })
            },
            getSoftPromotion() {
                let param = {
                    id: this.id
                }
                operationProblemOpDetail(param).then((res) => {
                    res.data.video_promote_score.map((item) => {
                        item.showPercent = false
                    })
                    this.videoFormData.label = res.data.title_promote_score.title
                    this.videoFormData.labelScore = res.data.title_promote_score.score
                    this.videoFormData.describe = res.data.description_promote_score.description	
                    this.videoFormData.describeScore = res.data.description_promote_score.score	
                    this.videoFormData.crowd = res.data.people_promote_score.people
                    this.videoFormData.crowdScore = res.data.people_promote_score.score
                    this.video_list = res.data.video_promote_score
                    this.video_list.forEach(item => {
                        this.$set(item,'imgShow',1)
                        this.$set(item,'videoPercent',0)   
                    })
                    
                    for(let i=0;i<res.data.video_promote_score.length;i++){
                        this.video_score.push({score:'',url:''})
                         this.video_score[i].url = res.data.video_promote_score[i].url
                    }
           


                    this.materialList = res.data.material_file;
                    this.formData.material_file = res.data.material_file;
                    // this.formData.user_analysis_man_ratio = res.data.user_analysis_man_ratio;
                    // this.formData.user_analysis_woman_ratio = res.data.user_analysis_woman_ratio;
                    // this.formData.data_analysis_man_ratio = res.data.data_analysis_man_ratio;
                    // this.formData.data_analysis_woman_ratio = res.data.data_analysis_woman_ratio;
                    //  this.formData.data_analysis_age = res.data.data_analysis_age;
                    // this.formData.data_analysis_region_distribution = res.data.data_analysis_region_distribution;
                    // this.formData.data_analysis_style_preference = res.data.data_analysis_style_preference;
                    // this.formData.article_promote_score = res.data.article_promote_score;
                    // this.formData.video_promote_score = res.data.video_promote_score;
                    // this.formData.user_analysis_age_begin = res.data.user_analysis_age_begin;
                    // this.formData.user_analysis_age_end = res.data.user_analysis_age_end;
                    // this.formData.user_analysis_age_score = res.data.user_analysis_age_score;
                    // this.formData.user_analysis_gender_score = res.data.user_analysis_gender_score;
                    // this.formData.user_analysis_region_distribution = res.data.user_analysis_region_distribution;
                    // this.formData.user_analysis_region_distribution_score = res.data.user_analysis_region_distribution_score;
                    // this.formData.user_analysis_style_preference = res.data.user_analysis_style_preference;
                    // this.formData.user_analysis_style_preference_score = res.data.user_analysis_style_preference_score;
                    this.percent = 100;
                }).catch((err) => {
                    console.log(err);
                })
            },

                //添加视频
                addVideo(){
                    let obj = {
                        imgurl:require('../../../../assets/default.png'),
                        url:'',
                        score:'',
                        videoPercent:0,
                        showPercent: false
                    }
                    this.video_list.push(obj)

                },



            fillData() {
                for (let i = 0; i < 6; i++) {
                    let obj = {
                        age_begin: '',
                        age_end: '',
                        ratio: ''
                    }
                    this.formData.data_analysis_age.push(obj)
                }
                for (let i = 0; i < 10; i++) {
                    let obj = {
                        province: '',
                        ratio: ''
                    }
                    this.formData.data_analysis_region_distribution.push(obj)
                }
                for (let i = 0; i < 10; i++) {
                    let obj = {
                        style: '',
                        ratio: ''
                    }
                    this.formData.data_analysis_style_preference.push(obj)
                }
            },
            //视频切片上传
            changeVideoFile(event,item,i){
                item.showPercent = true

                let videoSize = 1024 * 1024 * 8;
                let file = event.target.files[0];
                this.uploadVideo = new Upload(file, videoSize, uploadVideo);
                let index = 0;
                this.video_item = item
                this.uploadSliceVideo(index,item, i);
                this.$refs.videoFile[i].value = null
                item.videoPercent = 0
                this.sign = i
            },
            uploadSliceVideo(index,item, i){
                let num = this.uploadVideo.total_block_num;
                  if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadVideo.file_tmp,
                        slice_size: this.uploadVideo.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            item.videoPercent = 100;
                            // let obj = {
                            //     url: res.data.src,
                            //     score:'',
                            //     id:this.sign
                            // }
                            this.video_list[i].cover = this.mask;
                            this.video_list[i].imgShow = 1;
                            this.video_list[i].url = res.data.src;
                            // if (this.video_score[i]) {
                            //     this.video_score.splice(i, 1, obj)
                            // } else {
                            //      this.video_score.push(obj)
                            //     //  console.log( this.video_score,'uuioiuiou')
                            //     // for(let i=0;i< this.video_score.length;i++){
                            //     //     if(this.video_score[i].url!==''||this.video_score[i].url!==undefined){
                            //     //         this.video_score.push(obj)
                            //     //     }
                            //     // }
                            //     console.log(this.video_score,'sadasd')
                               
                               
                            // }
                            item.imgShow = 1
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadVideo.uploadSliceVideo(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.video_item.videoPercent = Math.ceil((index / num) * 100);
                            this.uploadSliceVideo(++index, item, i);
                        } else {
                            item.videoPercent = 100;
                               let obj = {
                                url: res.data.src,
                                score:''
                            }
                            this.video_list[i].imgShow = 1;
                            this.video_list[i].cover = this.mask;
                            this.video_list[i].url = res.data.src;
                            // if (this.video_score[i]) {
                            //     this.video_score.splice(i, 1, obj)
                            // } else {
                            //     this.video_score.push(obj)
                            // }
                            item.imgShow  = 1
                            this.$message.success(res.msg);
                        }
                    }
                });
                  
            },  
          
            submit() {
                let isErrNum = 0;
                if(this.videoFormData.label.length>200){
                    this.$message.error('标题字数最大0-200个字符')
                    return
                }
                 if(this.videoFormData.describe.length<5||this.videoFormData.describe.length>1000){
                    this.$message.error('描述为5-1000个字')
                    return
                }
               
                // console.log(video_promote_score,'ship')
                // if (!this.formData.article_promote_score) {
                //     this.$refs.article_input.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.article_input.$el.classList.remove('isError');
                // }
                // if (!this.formData.video_promote_score) {
                //     this.$refs.video_input.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.video_input.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_age_begin) {
                //     this.$refs.user_analysis_age_begin.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_age_begin.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_age_end) {
                //     this.$refs.user_analysis_age_end.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_age_end.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_age_score) {
                //     this.$refs.user_analysis_age_score.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_age_score.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_region_distribution) {
                //     this.$refs.user_analysis_region_distribution.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_region_distribution.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_region_distribution_score) {
                //     this.$refs.user_analysis_region_distribution_score.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_region_distribution_score.$el.classList.remove('isError');
                // }
                // if (this.formData.user_analysis_man_ratio == '') {
                //     this.$refs.user_analysis_man_ratio.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_man_ratio.$el.classList.remove('isError');
                // }
                // if (this.formData.user_analysis_woman_ratio == '') {
                //     this.$refs.user_analysis_woman_ratio.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_woman_ratio.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_gender_score) {
                //     this.$refs.user_analysis_gender_score.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_gender_score.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_style_preference) {
                //     this.$refs.user_analysis_style_preference.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_style_preference.$el.classList.remove('isError');
                // }
                // if (!this.formData.user_analysis_style_preference_score) {
                //     this.$refs.user_analysis_style_preference_score.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.user_analysis_style_preference_score.$el.classList.remove('isError');
                // }
                // if (this.formData.data_analysis_man_ratio == '') {
                //     this.$refs.data_analysis_man_ratio.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.data_analysis_man_ratio.$el.classList.remove('isError');
                // }
                // if (this.formData.data_analysis_woman_ratio == '') {
                //     this.$refs.data_analysis_woman_ratio.$el.classList.add('isError');
                //     isErrNum = 1;
                // } else {
                //     this.$refs.data_analysis_woman_ratio.$el.classList.remove('isError');
                // }
                // this.formData.data_analysis_age.forEach((item, index) => {
                //     if (item.age_begin == '') {
                //         this.$refs[`age_begin_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`age_begin_${index}`][0].$el.classList.remove('isError');
                //     }
                //     if (item.age_end == '') {
                //         this.$refs[`age_end_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`age_end_${index}`][0].$el.classList.remove('isError');
                //     }
                //     if (item.ratio == '') {
                //         this.$refs[`data_analysis_age_ratio_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`data_analysis_age_ratio_${index}`][0].$el.classList.remove('isError');
                //     }
                // })
                // this.formData.data_analysis_region_distribution.forEach((item, index) => {
                //     if (item.province == '') {
                //         this.$refs[`province_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`province_${index}`][0].$el.classList.remove('isError');
                //     }
                //     if (item.ratio == '') {
                //         this.$refs[`data_analysis_region_distribution_ratio_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`data_analysis_region_distribution_ratio_${index}`][0].$el.classList.remove('isError');
                //     }
                // })
                // this.formData.data_analysis_style_preference.forEach((item, index) => {
                //     if (item.style == '') {
                //         this.$refs[`style_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`style_${index}`][0].$el.classList.remove('isError');
                //     }
                //     if (item.ratio == '') {
                //         this.$refs[`data_analysis_style_preference_ratio_${index}`][0].$el.classList.add('isError');
                //         isErrNum = 1;
                //     } else {
                //         this.$refs[`data_analysis_style_preference_ratio_${index}`][0].$el.classList.remove('isError');
                //     }
                // })
                if (this.formData.material_file.length === 0) {
                    // this.$message.warning('请上传素材！');
                    isErrNum = 1;
                }
                if (!this.practiceForm.name) {
                    // this.$message.warning('请填写名称！');
                    isErrNum = 1;
                }
                if (this.practiceForm.classValue.length === 0) {
                    // this.$message.warning('请选择分类！');
                    isErrNum = 1;
                }
                // if (this.practiceForm.taskList.length === 0) {
                //     // this.$message.warning('请添加任务目标！');
                //     isErrNum = 1;
                // }
                if (!this.practiceForm.taskBackground) {
                    // this.$message.warning('请填写操作要求！');
                    isErrNum = 1;
                }
               
            
                // if (!this.practiceForm.taskAnalysis) {
                //     // this.$message.warning('请填写任务分析！');
                //     isErrNum = 1;
                // }
                // if (!this.practiceForm.taskOperation) {
                //     // this.$message.warning('请填写任务操作！');
                //     isErrNum = 1;
                // }
                if (this.practiceForm.scoreList.length === 0) {
                    // this.$message.warning('请添加评分标准！');
                    isErrNum = 1;
                }
                if(this.videoFormData.label ==''){
                     isErrNum = 1;
                }
                if(this.videoFormData.labelScore ==''){
                     isErrNum = 1;
                }
                if(this.videoFormData.describe ==''){
                     isErrNum = 1;
                }
                 if(this.videoFormData.describeScore ==''){
                     isErrNum = 1;
                }
                 if(this.videoFormData.crowd == null){
                     isErrNum = 1;
                }
                if(this.videoFormData.crowdScore ==''){
                     isErrNum = 1;
                }


                if (isErrNum === 0) {
                    if(this.video_list.length !=0){
                            for(let i=0;i<this.video_list.length;i++){
                                // this.video_promote_score[i].score = this.video_list[i].score
                                // this.video_promote_score[i].cover = this.video_list[i].cover
                                // this.video_promote_score[i].url = this.video_list[i].url
                                this.video_promote_score[i] = {
                                    score :  this.video_list[i].score,
                                    cover :  this.video_list[i].cover,
                                    url :  this.video_list[i].url,
                                }
                            }
                        }
                    if(this.video_promote_score.length ==0){
                        this.video_promote_score = []
                            this.$message.warning('每项为必填！')
                            return
                    }
                    this.formData.op_name = this.practiceForm.name
                    this.formData.exam_module_id = this.practiceForm.module_level_three_value
                    this.formData.assess_time = this.practiceForm.time
                    // this.formData.task_goal = this.practiceForm.taskList
                    this.formData.task_background = this.practiceForm.taskBackground
                    // this.formData.task_analysis = this.practiceForm.taskAnalysis
                    // this.formData.task_operation = this.practiceForm.taskOperation
                    this.formData.scoring_criteria = this.practiceForm.scoreList
                    this.formData.category_id = this.practiceForm.classValue[this.practiceForm.classValue.length - 1]
                    if (this.practiceForm.id != null) {
                        this.formData.id = this.practiceForm.id
                    }
                    this.formData.title_promote_score = {
                        title:this.videoFormData.label,
                        score:this.videoFormData.labelScore,
                    }
                    this.formData.description_promote_score = {
                        description:this.videoFormData.describe,
                        score:this.videoFormData.describeScore
                    }
                    this.formData.people_promote_score = {
                        people:this.videoFormData.crowd,
                        score:this.videoFormData.crowdScore
                    }
                    this.formData.video_promote_score = this.video_promote_score
                    SaveArticlePromote(this.formData).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.reset();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$message.warning('每项为必填！')
                }
            },
            reset() {
                this.formData.user_analysis_man_ratio = '';
                this.formData.user_analysis_woman_ratio = '';
                this.formData.data_analysis_man_ratio = '';
                this.formData.data_analysis_woman_ratio = '';
                // this.formData.data_analysis_age = '';
                this.formData.data_analysis_region_distribution = '';
                this.formData.data_analysis_style_preference = '';
                this.formData.material_file = '';
                this.$router.push({
                    path: '/practice/index'
                })
            },
            GenderMale() {
                if (this.formData.user_analysis_man_ratio > 100) {
                    this.formData.user_analysis_man_ratio = 100
                }
                this.formData.user_analysis_woman_ratio = (100 - this.formData.user_analysis_man_ratio).toFixed(1)
            },
            GenderFemale() {
                if (this.formData.user_analysis_woman_ratio > 100) {
                    this.formData.user_analysis_woman_ratio = 100
                }
                this.formData.user_analysis_man_ratio = (100 - this.formData.user_analysis_woman_ratio).toFixed(1)
            },
            GenderMan() {
                if (this.formData.data_analysis_man_ratio > 100) {
                    this.formData.data_analysis_man_ratio = 100
                }
                this.formData.data_analysis_woman_ratio = (100 - this.formData.data_analysis_man_ratio).toFixed(1)

            },
            GenderWoman() {
                if (this.formData.data_analysis_woman_ratio > 100) {
                    this.formData.data_analysis_woman_ratio = 100
                }
                this.formData.data_analysis_man_ratio = (100 - this.formData.data_analysis_woman_ratio).toFixed(1)
            },
            sumAll(arr, index) {
                if (arr[index].ratio > 100) {
                    arr[index].ratio = 100
                }
                let len = arr.length
                let sum = 0, value = 0;
                for (let i = 0; i < len; i++) {
                    if (arr[i].ratio != '') {
                        sum += Number(arr[i].ratio);
                        value++;
                    }
                    if (arr[index].ratio == 100) {
                        if (i !== index) {
                            arr[i].ratio = 0
                        }
                    }
                }
                if (sum > 100) {
                    arr[index].ratio = 0
                    this.$message.warning('范围比例不能超过100！')
                }
                if (value == len - 1 && sum < 100) {
                    for (let i = 0; i < len; i++) {
                        if (arr[i].ratio == '') {
                            arr[i].ratio = (100 - sum)
                        }
                    }
                }
                if (value == len && sum < 100) {
                    sum = sum - arr[index].ratio;
                    arr[index].ratio = (100 - sum)
                }
            },

            videoFun(val,item){

                this.val = val
                document.getElementById('videoFile'+val).click()
            },


         
            //文件上传
            changeFile(event) {
                let chunkSize = 1024 * 1024 * 8;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.materialList = []
                this.formData.material_file = [];
                this.materialList.push(obj);
                this.formData.material_file.push(obj);
                let index = 0;
                this.uploadSlice(index);
                this.$refs.uploadFile.value = null
                this.percent = 0
            },
            uploadSlice(index) {
 
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.formData.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.formData.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.formData.material_file.splice(index, 1);
                            this.materialList.splice(index, 1);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .promote-content {
        margin-top: 20px;
        font-size: 16px;
        color: #333333;

        .item-input {
            &.max {
                width: 375px;
            }

            &.medium {
                width: 250px;
            }

            &.mini {
                width: 150px;
            }

            &.sex {
                width: 130px;
            }

            &.tiny {
                width: 120px;
            }
        }

        .input-text {
            margin: 0 10px;

            &.sex {
                margin: 0 17px;
            }

            &.front {
                margin: 0 12px 0 22px;
            }
        }

        .content-item {

            .content-title {
                position: relative;
                padding-left: 10px;
                margin-bottom: 22px;

                &:before {
                    content: '';
                    width: 4px;
                    height: 16px;
                    background-color: #2DC079;
                    border-radius: 2px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }

            .item-content {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .item-title {
                    width: 120px;
                    text-align: right;
                    margin-right: 10px;
                }

                .divide {
                    width: 24px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 18px;
                }

                .file-upload-btn {
                      display: none;
                }

                .upload-text {
                    margin-left: 11px;
                    font-size: 14px;
                    color: #999999;
                }

                .upload-file-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    align-items: center;

                    .file-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 40px;
                        padding: 10px;
                        box-sizing: border-box;
                        color: #333333;
                        font-size: 14px;

                        &:hover {
                            cursor: pointer;
                            background-color: #F5F7FA;

                            .del-icon {
                                display: block;
                            }

                            .finish-icon {
                                display: none;
                            }
                        }

                        .file-name {
                            display: inline-block;
                            width: 1%;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .el-progress {
                            margin-left: 20px;
                            width: 160px;
                        }

                        i {
                            color: #2461EF;
                            margin: 0 10px 0 0;
                        }

                        .finish-icon {
                            color: #2DC079;
                            margin: 0 0 0 10px;
                        }

                        .del-icon {
                            display: none;
                            color: #FF0000;
                            margin: 0 0 0 10px;
                        }
                    }
                }
            }

        }
        .video_k{
             border: 1px dashed #d9d9d9 !important;
            border-radius: 6px;
             width: 120px;
            height: 120px;
            display: block;
        }
        .videoImg{
               border-radius: 6px;
             width: 120px;
            height: 120px;
            position: absolute;
            left: 0;
        }

       ::v-deep .video .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9 !important;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background-color: #fafafa;
        }
        ::v-deep .video .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        ::v-deep .video .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 120px;
            height: 120px;
            line-height: 122px;
            text-align: center;
        }
        ::v-deep .video .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }

        .footer-wrapper {
            text-align: center;
            margin-top: 50px;
        }
    }
</style>
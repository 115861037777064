<template>
    <div>社会化客户服务</div>
</template>

<script>
    export default {
        name: "SocialCustomerService"
    }
</script>

<style scoped>

</style>
<template>
<div class="shopRegisterSet">
  <el-form :model="shopForm" :rules="shopFormRules" ref="shopForm" label-width="180px">
    <el-form-item label="联系人姓名">
      <el-col :span="7">
        <el-form-item prop="contact_name">
          <el-input style="width: 380px;" v-model="shopForm.contact_name" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="contact_name_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.contact_name_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="联系人手机">
      <el-col :span="7">
        <el-form-item prop="contact_phone">
          <el-input placeholder="请输入联系人手机" style="width: 380px;" v-model="shopForm.contact_phone"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="contact_phone_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.contact_phone_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="联系人电子邮箱">
      <el-col :span="7">
        <el-form-item prop="contact_email">
          <el-input placeholder="请输入联系人电子邮箱" style="width: 380px;" v-model="shopForm.contact_email"></el-input>
          <div style="line-height: 1;font-size: 12px;color: #999999;margin-top: 10px">入驻过程中用于接收京东审核结果、开店账号密码信息，请务必仔细确认。</div>
          <div style="line-height: 1;font-size: 12px;color: #999999;margin-top: 6px">建议使用QQ、网易邮箱、189、自主企业邮箱可能无法正常收取邮件。</div>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="contact_email_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.contact_email_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="对接京东招商人员" prop="is_abutting_joint_JD">
      <el-radio-group v-model="shopForm.is_abutting_joint_JD">
        <el-radio :label="0">无</el-radio>
        <el-radio :label="1">有</el-radio>
      </el-radio-group>
      <div style="line-height: 1;font-size: 12px;color: #999999">如果没有联系过京东招商人员请选择“无”。</div>
    </el-form-item>
    <el-form-item label="姓名" v-if="shopForm.is_abutting_joint_JD === 1">
      <el-col :span="7">
        <el-form-item prop="name">
          <el-input placeholder="请输入姓名" style="width: 380px;" v-model="shopForm.name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="name_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.name_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="推荐码">
      <el-col :span="7">
        <el-form-item prop="recommend_code">
          <el-input placeholder="请输入推荐码" style="width: 380px;" v-model.number="shopForm.recommend_code"></el-input>
          <div style="line-height: 1;font-size: 12px;color: #999999;margin-top: 10px">请录入推荐商家的商家ID，由数字组成；如果没有受到邀请，则不用填写。</div>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="recommend_code_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.recommend_code_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="证件类型">
      <el-col :span="7">
        <el-form-item prop="document_type">
          <el-select v-model="shopForm.document_type" style="width: 380px;" placeholder="请选择证件类型">
            <el-option label="多证合一营业执照（统一社会信用代码）" :value="0" :key="0"></el-option>
            <el-option label="多证合一营业执照（非统一社会信用代码）" :value="1" :key="1"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="document_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.document_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="营业执照电子版">
      <el-col :span="7">
        <span style="font-size: 18px">上传营业执照电子版</span>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="businessLicense_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.businessLicense_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="公司名称">
      <el-col :span="7">
        <el-form-item prop="corporate_name">
          <el-input placeholder="如有（），请在输入法为中文状态下输入" style="width: 380px;" v-model="shopForm.corporate_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="corporate_name_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.corporate_name_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="统一社会信用代码">
      <el-col :span="7">
        <el-form-item prop="unified_social_credit_code">
          <el-input placeholder="15位的数字" style="width: 380px;" v-model.number="shopForm.unified_social_credit_code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="unified_social_credit_code_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.unified_social_credit_code_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="营业执照所在地" class="select-address">
      <el-col :span="7">
        <el-form-item prop="business_license_location_province">
          <el-select style="width: 116px;" v-model="shopForm.business_license_location_province" @change="selectBusinessProvince($event,1)" placeholder="选择省">
            <el-option
                v-for="item in businessProvince"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="business_license_location_city">
          <el-select style="width: 116px;margin: 0 10px" v-model="shopForm.business_license_location_city" @change="selectBusinessProvince($event,2)" placeholder="选择市">
            <el-option
                v-for="item in businessCity"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="business_license_location_area">
          <el-select style="width: 116px;margin: 0 10px" v-model="shopForm.business_license_location_area" @change="selectBusinessProvince($event,3)" placeholder="选择区">
            <el-option
                v-for="item in businessArea"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="business_license_location_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.business_license_location_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="营业执照详细地址">
      <el-col :span="7">
        <el-form-item prop="business_license_address">
          <el-input placeholder="请输入营业执照详细地址" style="width: 380px;" v-model="shopForm.business_license_address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="business_license_address_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.business_license_address_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="成立日期">
      <el-col :span="7">
        <el-form-item prop="incorporation_date">
          <el-date-picker
              prefix-icon="el-icon-date"
              style="width: 380px;"
              v-model="shopForm.incorporation_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="incorporation_date_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.incorporation_date_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="营业日期" class="select-address">
      <el-col :span="7" v-if="shopForm.business_date_type !== 1">
        <el-form-item prop="business_date_begin">
          <el-date-picker
              @change="selectBusinessDate"
              prefix-icon="el-icon-date"
              style="width: 148px;"
              v-model="shopForm.business_date_begin"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item> <span style="margin: 0 6px">-</span>
        <el-form-item prop="business_date_end">
          <el-date-picker
              @change="selectBusinessDate"
              prefix-icon="el-icon-date"
              style="width: 148px;"
              v-model="shopForm.business_date_end"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
        <span style="margin-left: 12px"><el-checkbox v-model="isLongTerm" @change="selectLongTerm">长期</el-checkbox></span>
      </el-col>
      <el-col :span="7" v-else>
        <el-form-item prop="business_date_begin">
          <el-date-picker
              @change="selectBusinessDate"
              prefix-icon="el-icon-date"
              style="width: 312px;"
              v-model="shopForm.business_date_begin"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <span style="margin-left: 12px"><el-checkbox v-model="isLongTerm" @change="selectLongTerm">长期</el-checkbox></span>
      </el-col>
      <el-col :span="7">
        <el-form-item prop="business_date_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.business_date_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="注册资本（万元）">
      <el-col :span="7">
        <el-form-item prop="registered_capital">
          <el-input-number placeholder="请输入注册资本" style="width: 380px;" v-model="shopForm.registered_capital" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="registered_capital_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.registered_capital_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="经营范围">
      <el-col :span="7">
        <el-form-item prop="business_scope">
          <el-input type="textarea" placeholder="请输入经营范围" style="width: 380px;" v-model="shopForm.business_scope"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="business_scope_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.business_scope_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="法定代表人证件类型">
      <el-col :span="7">
        <el-form-item prop="legal_representative_certificate_type">
          <el-select v-model="shopForm.legal_representative_certificate_type" style="width: 380px;" placeholder="请选择">
            <el-option v-for="item in legalRepresentative" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="legal_representative_certificate_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.legal_representative_certificate_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="法人证件电子版">
      <el-col :span="7">
        <span style="font-size: 18px">上传法人证件电子版</span>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="legal_person_certificate">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.legal_person_certificate" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="法定代表人姓名">
      <el-col :span="7">
        <el-form-item prop="legal_representative_name">
          <el-input placeholder="请输入法定代表人姓名" style="width: 380px;" v-model="shopForm.legal_representative_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="legal_representative_name_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.legal_representative_name_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="法定代表人证件号">
      <el-col :span="7">
        <el-form-item prop="legal_representative_certificates_num">
          <el-input placeholder="请输入法定代表人证件号" style="width: 380px;" v-model="shopForm.legal_representative_certificates_num"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="legal_representative_certificates_num_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.legal_representative_certificates_num_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="有效期" class="select-address">
      <el-col :span="7" v-if="shopForm.alidity_period_type !== 1">
        <el-form-item prop="alidity_period_begin">
          <el-date-picker
              @change="selectAlidityPeriod"
              prefix-icon="el-icon-date"
              style="width: 148px;"
              v-model="shopForm.alidity_period_begin"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item> <span style="margin: 0 6px">-</span>
        <el-form-item prop="alidity_period_end">
          <el-date-picker
              @change="selectAlidityPeriod"
              prefix-icon="el-icon-date"
              style="width: 148px;"
              v-model="shopForm.alidity_period_end"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
        <span style="margin-left: 12px"><el-checkbox v-model="isLongTermRepresentative" @change="selectLongTermRepresentative">长期</el-checkbox></span>
      </el-col>
      <el-col :span="7" v-else>
        <el-form-item prop="alidity_period_begin">
          <el-date-picker
              prefix-icon="el-icon-date"
              style="width: 312px;"
              v-model="shopForm.alidity_period_begin"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <span style="margin-left: 12px"><el-checkbox v-model="isLongTermRepresentative" @change="selectLongTermRepresentative">长期</el-checkbox></span>
      </el-col>
      <el-col :span="7">
        <el-form-item prop="alidity_period_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.alidity_period_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="纳税人类型">
      <el-col :span="7">
        <el-form-item prop="taxpayers_type">
          <el-select v-model="shopForm.taxpayers_type" style="width: 380px;" placeholder="请选择">
            <el-option v-for="item in taxpayersList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="taxpayers_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.taxpayers_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="纳税人识别号">
      <el-col :span="7">
        <el-form-item prop="taxpayers_identity_num">
          <el-input placeholder="15位的数字" style="width: 380px;" v-model.number="shopForm.taxpayers_identity_num"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="taxpayers_identity_num_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.taxpayers_identity_num_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="纳税人类型税码">
      <el-col :span="7">
        <el-form-item prop="yax_type">
          <el-select v-model="shopForm.yax_type" style="width: 380px;" placeholder="请选择">
            <el-option v-for="item in yaxList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="yax_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.yax_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="税务登记电子版">
      <el-col :span="7">
        <span style="font-size: 18px">上传税务登记电子版</span>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="tax_registration_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.tax_registration_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="一般纳税人资格证电子版">
      <el-col :span="7">
        <span style="font-size: 18px">上传一般纳税人资格证电子版</span>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="taxpayer_qualification_certificate_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.taxpayer_qualification_certificate_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="对公结算银行账号">
      <el-col :span="7">
        <el-form-item prop="corporate_settlement_bank_account">
          <el-input placeholder="请输入对公结算银行账号" style="width: 380px;" v-model="shopForm.corporate_settlement_bank_account"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="corporate_settlement_bank_account_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.corporate_settlement_bank_account_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="支行联行号">
      <el-col :span="7">
        <el-form-item prop="branch_bank_account">
          <el-input placeholder="请输入支行联行号" style="width: 380px;" v-model="shopForm.branch_bank_account"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="branch_bank_account_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.branch_bank_account_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="支行名称">
      <el-col :span="7">
        <el-form-item prop="branch_bank_name">
          <el-input placeholder="请输入支行名称" style="width: 380px;" v-model="shopForm.branch_bank_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="branch_bank_name_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.branch_bank_name_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="开户银行支行所在地" class="select-address">
      <el-col :span="7">
        <el-form-item prop="open_account_bank_province">
          <el-select style="width: 116px;" v-model="shopForm.open_account_bank_province" @change="selectOpenAccountBank($event,1)" placeholder="选择省">
            <el-option
                v-for="item in openAccountBankProvince"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="open_account_bank_cit">
          <el-select style="width: 116px;margin: 0 10px" v-model="shopForm.open_account_bank_cit" @change="selectOpenAccountBank($event,2)" placeholder="选择市">
            <el-option
                v-for="item in openAccountBankCity"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="open_account_bank_area">
          <el-select style="width: 116px;margin: 0 10px" v-model="shopForm.open_account_bank_area" @change="selectOpenAccountBank($event,3)" placeholder="选择区">
            <el-option
                v-for="item in openAccountBankArea"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="open_account_bank_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.open_account_bank_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="公司类型">
      <el-col :span="7">
        <el-form-item prop="company_type">
          <el-select v-model="shopForm.company_type" style="width: 380px;" placeholder="请选择公司类型">
            <el-option v-for="item in companyTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="company_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.company_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="公司网站">
      <el-col :span="7">
        <el-form-item prop="company_web">
          <el-input placeholder="非必填" style="width: 380px;" v-model="shopForm.company_web"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="company_web_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.company_web_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="年销售额（万元）">
      <el-col :span="7">
        <el-form-item prop="annual_sales">
          <el-input placeholder="仅输入不得超过9位数的正整数" style="width: 380px;" v-model.number="shopForm.annual_sales"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="annual_sales_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.annual_sales_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="商品数量">
      <el-col :span="7">
        <el-form-item prop="goods_num">
          <el-select v-model="shopForm.goods_num" style="width: 380px;" placeholder="请选择商品数量">
            <el-option v-for="item in goodsNumList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="goods_num_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.goods_num_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="平均客单价">
      <el-col :span="7">
        <el-form-item prop="avg_price">
          <el-select v-model="shopForm.avg_price" style="width: 380px;" placeholder="请选择平均客单价">
            <el-option v-for="item in avgPriceList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="avg_price_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.avg_price_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="仓库情况">
      <el-col :span="7">
        <el-form-item prop="warehouse_situation">
          <el-select v-model="shopForm.warehouse_situation" style="width: 380px;" placeholder="请选择仓库情况">
            <el-option v-for="item in warehouseSituationList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="warehouse_situation_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.warehouse_situation_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="仓库地址">
      <el-col :span="7">
        <el-form-item prop="warehouse_address">
          <el-input placeholder="非必填" style="width: 380px;" v-model="shopForm.warehouse_address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="warehouse_address_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.warehouse_address_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="常用物流">
      <el-col :span="7">
        <el-form-item prop="common_logistics">
          <el-input placeholder="可填写多个，用#分隔，如“#申通 #圆通”，最大50个字" style="width: 380px;" v-model="shopForm.common_logistics"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="common_logistics_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.common_logistics_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="ERP类型">
      <el-col :span="7">
        <el-form-item prop="ERP_type">
          <el-select v-model="shopForm.ERP_type" style="width: 380px;" placeholder="请选择ERP类型">
            <el-option v-for="item in ERPTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="ERP_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.ERP_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="代运营公司">
      <el-col :span="7">
        <el-form-item prop="agent_operating_company">
          <el-input placeholder="非必填" style="width: 380px;" v-model="shopForm.agent_operating_company"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="agent_operating_company_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.agent_operating_company_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="店铺类型">
      <el-col :span="7">
        <el-form-item prop="store_type">
          <el-radio-group v-model="shopForm.store_type" @change="selectStoreType">
            <el-radio :label="0">旗舰店</el-radio>
            <el-radio :label="1">专营店</el-radio>
            <el-radio :label="2">专卖店</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="store_type_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.store_type_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="主营类目">
      <el-col :span="7">
        <el-form-item prop="good_category_id">
<!--          <el-input :disabled="true" placeholder="暂无选择类目" style="width: 300px;" :value="selectCategoryName"></el-input>-->
          <el-cascader
              style="width: 300px;"
              :props="categoryProps"
              :disabled="true"
              v-model="shopForm.good_category_id"
              :options="goodCategoryList"></el-cascader>
<!--          <el-cascader-panel v-model="shopForm.good_category_id" :disabled="true" :props="categoryProps" :options="goodCategoryList"></el-cascader-panel>-->
          <el-button type="primary" style="padding: 12px 8px;margin-left: 8px" @click="isShowCategory = true">选择类目</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="good_category_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.good_category_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="公司简称">
      <el-col :span="7">
        <el-form-item prop="company_abbreviation">
          <el-input placeholder="请输入公司简称" style="width: 380px;" v-model="shopForm.company_abbreviation"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="company_abbreviation_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.company_abbreviation_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="经营类目描述">
      <el-col :span="7">
        <el-form-item prop="category_description">
          <el-input type="textarea" placeholder="非必填" style="width: 380px;" v-model="shopForm.category_description"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="category_description_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.category_description_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="店铺后缀">
      <el-col :span="7">
        <el-form-item prop="shop_suffix">
          <el-select v-model="shopForm.shop_suffix" style="width: 380px;" placeholder="请选择店铺后缀">
            <el-option v-if="shopForm.store_type === 1"  :key="0" :value="0" label="专营店"></el-option>
            <el-option v-if="shopForm.store_type === 2"  :key="1" :value="1" label="专卖店"></el-option>
            <el-option v-if="shopForm.store_type === 0" :key="2" :value="2" label="旗舰店"></el-option>
            <el-option v-if="shopForm.store_type === 0"  :key="3" :value="3" label="官方旗舰店"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="shop_suffix_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.shop_suffix_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="店铺LOGO">
      <el-col :span="7">
        <span style="font-size: 18px">上传店铺LOGO</span>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="store_logo_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.store_logo_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="店铺简介">
      <el-col :span="7">
        <el-form-item prop="shop_profile">
          <el-input placeholder="请输入店铺简介" style="width: 380px;" v-model="shopForm.shop_profile"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="shop_profile_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.shop_profile_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="品牌简介">
      <el-col :span="7">
        <el-form-item prop="brand_profile">
          <el-input placeholder="请输入品牌简介" style="width: 380px;" v-model="shopForm.brand_profile"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="brand_profile_score">
          分值
          <el-input-number placeholder="请输入分值" v-model="shopForm.brand_profile_score" style="width: 120px;margin: 0 10px 0 12px" :min="0.1" :max="99999999999" :controls="false" :precision="1"></el-input-number>分
        </el-form-item>
      </el-col>
    </el-form-item>
  </el-form>
  <div class="content-item">
    <div class="content-title">上传素材</div>
    <div class="item-content">
      <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
      <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
             accept="application/zip, .rar" title=""
             @change="changeFile($event)">
      <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G</span>
    </div>
  </div>
  <div class="content-item">
    <div class="item-content">
      <div class="upload-file-list">
        <div class="file-item" v-for="(item, index) in materialList">
          <i class="iconfont">&#xe60b;</i>
          <span class="file-name" :title="item.name">{{item.name}}</span>
          <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
          <i class="iconfont finish-icon" v-show="percent == 100">&#xe654;</i>
          <i class="iconfont del-icon" @click="delMaterial(item, index)" v-show="percent == 100">&#xe651;</i>
        </div>
        <download-btn :status="isFinish" :list="materialList"></download-btn>
      </div>
    </div>
  </div>
  <div class="footer-btn" style="margin-top: 40px;text-align: center;">
    <el-button @click="goBack">取消</el-button>
    <el-button type="primary" @click="submitShopForm('shopForm')" style="margin-left: 30px">保存</el-button>
  </div>
  <el-dialog
      @open="openDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="请选择类目"
      custom-class="green-dialog"
      :visible.sync="isShowCategory"
      width="650px">
    <i class="iconfont close-icon"  @click="closeDialog">&#xe668;</i>
    <el-cascader-panel style="height: 210px;" v-model="shopForm.good_category_id" :props="categoryProps" :options="goodCategoryList"></el-cascader-panel>
    <div slot="footer" style="text-align: center">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="submitAllocation" style="margin-left: 30px">确定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import downloadBtn from '../../../../util/downloadBtn.vue';
import {
  adminGoodGetCategory,
  operationProblemDelMaterial,
  uploadMaterial,
  operationProblemStoreSettings,
  mergeSlice
} from '@/utils/apis'
import {areaList} from '@/utils/area'
import Upload from "@/utils/slice_upload";
export default {
  name: "ShopRegisterSet",
  props: ['practiceForm', 'editData'],
  components: {
    downloadBtn
  },
  computed: {
    isFinish() {
      return Boolean(this.materialList.length) && this.materialList[0].url
    }
  },
  data(){
    // 手机号验证
    var isPhoneNum = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('手机号格式有误'))
      } else {
        callback()
      }
    }
    // 验证推荐人
    var isRecommend = (rule, value, callback) => {
      if(this.shopForm.recommend_code_score){
        if(value === ''){
          return callback(new Error('输入推荐码分值必须填入推荐码！'));
        } else {
          if(!Number.isInteger(value)){
            callback(new Error('请输入数字值'));
          } else {
            callback();
          }
        }
      } else {
        if(value !== ''){
          if(!Number.isInteger(value)){
            callback(new Error('请输入数字值'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    var isRecommend2 = (rule, value, callback) => {
      if(this.shopForm.recommend_code){
        if(value === ''){
          return callback(new Error('输入推荐码必须填入推荐码分值！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 验证统一社会信用代码
    var isUnifiedSocialCreditCode = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入统一社会信用代码'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length !== 15){
            callback(new Error('长度为15位'));
          } else {
            callback();
          }
        }
      }
    }
    // 验证纳税人识别号
    var isTaxpayersIdentityNum = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入纳税人识别号'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length !== 15){
            callback(new Error('长度为15位'));
          } else {
            callback();
          }
        }
      }
    }
    // 验证年销售额（万元)
    var isAnnualSales = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入年销售额'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length > 9){
            callback(new Error('仅输入不得超过9位数的正整数'));
          } else {
            callback();
          }
        }
      }
    }
    // 公司网站&公司网站分值验证
    var isRecommendWeb = (rule, value, callback) => {
      if(this.shopForm.company_web_score){
        if(value === ''){
          return callback(new Error('输入公司网站分值必须填入公司网站！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var isRecommendWebScore = (rule, value, callback) => {
      if(this.shopForm.company_web){
        if(value === ''){
          return callback(new Error('输入公司网站必须填入公司网站分值！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 仓库地址&仓库地址分值验证
    var isRecommendAdress = (rule, value, callback) => {
      if(this.shopForm.warehouse_address_score){
        if(value === ''){
          return callback(new Error('输入仓库地址分值必须填入仓库地址！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var isRecommendAddressScore = (rule, value, callback) => {
      if(this.shopForm.warehouse_address){
        if(value === ''){
          return callback(new Error('输入仓库地址必须填入仓库地址分值！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 代运营公司&代运营公司分值验证
    var isAgentOperatingCompany = (rule, value, callback) => {
      if(this.shopForm.agent_operating_company_score){
        if(value === ''){
          return callback(new Error('输入代运营公司分值必须填入代运营公司！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var isAgentOperatingCompanyScore = (rule, value, callback) => {
      if(this.shopForm.agent_operating_company){
        if(value === ''){
          return callback(new Error('输入代运营公司必须填入代运营公司分值！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 经营类目描述&经营类目描述分值验证
    var isCategoryDescription = (rule, value, callback) => {
      if(this.shopForm.category_description_score){
        if(value === ''){
          return callback(new Error('输入经营类目描述分值必须填入经营类目描述！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var isCategoryDescriptionScore = (rule, value, callback) => {
      if(this.shopForm.category_description){
        if(value === ''){
          return callback(new Error('输入经营类目描述必须填入经营类目描述分值！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      shopForm:{
        contact_name:'',
        contact_name_score:void 0,
        contact_phone:'',
        contact_phone_score:void 0,
        contact_email:'',
        contact_email_score:void 0,
        is_abutting_joint_JD:1,
        name:'',
        name_score:void 0,
        recommend_code:'',
        recommend_code_score:void 0,
        document_type:'',
        document_type_score:void 0,
        businessLicense_score:void 0,
        corporate_name:'',
        corporate_name_score:void 0,
        unified_social_credit_code:'',
        unified_social_credit_code_score:void 0,
        business_license_location_province:'',
        business_license_location_city:'',
        business_license_location_area:'',
        business_license_location_score:void 0,
        business_license_address:'',
        business_license_address_score:void 0,
        incorporation_date:'',
        incorporation_date_score:void 0,
        business_date_begin:'',
        business_date_end:'',
        business_date_type:0,
        business_date_score: void 0,
        registered_capital: void 0,
        registered_capital_score:void 0,
        business_scope:'',
        business_scope_score:void 0,
        legal_representative_certificate_type:'',
        legal_representative_certificate_type_score:void 0,
        legal_person_certificate:void 0,
        legal_representative_name:'',
        legal_representative_name_score: void 0,
        legal_representative_certificates_num:'',
        legal_representative_certificates_num_score:void 0,
        alidity_period_begin:'',
        alidity_period_end:'',
        alidity_period_type:0,
        alidity_period_score: void 0,
        taxpayers_type:'',
        taxpayers_type_score: void 0,
        taxpayers_identity_num:'',
        taxpayers_identity_num_score:void 0,
        yax_type:'',
        yax_type_score: void 0,
        tax_registration_score: void 0,
        taxpayer_qualification_certificate_score: void 0,
        corporate_settlement_bank_account:'',
        corporate_settlement_bank_account_score: void 0,
        branch_bank_account:'',
        branch_bank_account_score: void 0,
        branch_bank_name:'',
        branch_bank_name_score: void 0,
        open_account_bank_province:'',
        open_account_bank_cit:'',
        open_account_bank_area:'',
        open_account_bank_score: void 0,
        company_type:'',
        company_type_score:void 0,
        company_web:'',
        company_web_score:void 0,
        annual_sales:'',
        annual_sales_score:void 0,
        goods_num:'',
        goods_num_score: void 0,
        avg_price:'',
        avg_price_score: void 0,
        warehouse_situation:'',
        warehouse_situation_score: void 0,
        warehouse_address:'',
        warehouse_address_score: void 0,
        common_logistics:'',
        common_logistics_score: void 0,
        ERP_type:'',
        ERP_type_score: void 0,
        agent_operating_company:'',
        agent_operating_company_score: void 0,
        store_type:'',
        store_type_score: void 0,
        good_category_id:'',
        good_category_score: void 0,
        company_abbreviation:'',
        company_abbreviation_score: void 0,
        category_description:'',
        category_description_score: void 0,
        shop_suffix:'',
        shop_suffix_score: void 0,
        store_logo_score: void 0,
        shop_profile:'',
        shop_profile_score: void 0,
        brand_profile:'',
        brand_profile_score: void 0,
        material_file:[],
      },
      // 营业执照选择地区
      businessProvince:[],
      businessCity:[],
      businessArea:[],
      // 开户银行支行选择地区
      openAccountBankProvince:[],
      openAccountBankCity:[],
      openAccountBankArea:[],
      shopFormRules:{
        contact_name:[
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contact_name_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        contact_phone:[
          { required: true, message: '请输入联系人手机', trigger: 'blur' },
          { validator:isPhoneNum,  trigger: 'blur'}
        ],
        contact_phone_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        contact_email:[
          { required: true, message: '请输入联系人电子邮箱', trigger: 'blur' },
          {type: 'email', message: '请输入正确的邮箱', trigger: 'blur'}
        ],
        contact_email_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        is_abutting_joint_JD:[
          { required: true, message: '请选择是否有无对接京东招商人员', trigger: 'change' }
        ],
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        name_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        recommend_code:[
          { validator:isRecommend, trigger: 'blur' }
        ],
        recommend_code_score:[
          { validator: isRecommend2, trigger: 'blur' }
        ],
        document_type:[
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        document_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        businessLicense_score:[
          { required: true, message: '请输入营业执照电子版分值', trigger: 'blur' }
        ],
        corporate_name:[
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        corporate_name_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        unified_social_credit_code:[
          { validator:isUnifiedSocialCreditCode, trigger: 'blur'}
        ],
        unified_social_credit_code_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        business_license_location_province:[
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        business_license_location_city:[
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        business_license_location_area:[
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        business_license_location_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        business_license_address:[
          { required: true, message: '请输入营业执照详细地址', trigger: 'blur' }
        ],
        business_license_address_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        incorporation_date:[
          { type: 'string', required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        incorporation_date_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        business_date_begin:[
          { type: 'string', required: true, message: '请选择营业开始日期', trigger: 'change' }
        ],
        business_date_end:[
          { type: 'string', required: true, message: '请选择营业结束日期', trigger: 'change' }
        ],
        business_date_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        registered_capital:[
          { required: true, message: '请输入注册资本', trigger: 'blur' }
        ],
        registered_capital_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        business_scope:[
          { required: true, message: '请输入经营范围', trigger: 'blur' }
        ],
        business_scope_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        legal_representative_certificate_type:[
          { required: true, message: '请选择法定代表人证件类型', trigger: 'change' }
        ],
        legal_representative_certificate_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        legal_person_certificate:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        legal_representative_name:[
          { required: true, message: '请输入法定代表人姓名', trigger: 'blur' }
        ],
        legal_representative_name_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        legal_representative_certificates_num:[
          { required: true, message: '请输入法定代表人证件号', trigger: 'blur' }
        ],
        legal_representative_certificates_num_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        alidity_period_begin:[
          { type: 'string', required: true, message: '请选择有效期开始日期', trigger: 'change' }
        ],
        alidity_period_end:[
          { type: 'string', required: true, message: '请选择有效期结束日期', trigger: 'change' }
        ],
        alidity_period_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        taxpayers_type:[
          { required: true, message: '请选择纳税人类型', trigger: 'change' }
        ],
        taxpayers_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        taxpayers_identity_num:[
          { validator:isTaxpayersIdentityNum, trigger: 'blur'}
        ],
        taxpayers_identity_num_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        yax_type:[
          { required: true, message: '请选择纳税人类型税码', trigger: 'change' }
        ],
        yax_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        tax_registration_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        taxpayer_qualification_certificate_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        corporate_settlement_bank_account:[
          { required: true, message: '请输入对公结算银行账号', trigger: 'blur' }
        ],
        corporate_settlement_bank_account_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        branch_bank_account: [
          { required: true, message: '请输入支行联行号', trigger: 'blur' }
        ],
        branch_bank_account_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        branch_bank_name: [
          { required: true, message: '请输入支行名称', trigger: 'blur' }
        ],
        branch_bank_name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        open_account_bank_province:[
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        open_account_bank_cit:[
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        open_account_bank_area:[
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        open_account_bank_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        company_type:[
          { required: true, message: '请选择公司类型', trigger: 'change' }
        ],
        company_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        company_web:[
          { validator:isRecommendWeb, trigger: 'blur' }
        ],
        company_web_score:[
          { validator: isRecommendWebScore, trigger: 'blur' }
        ],
        annual_sales:[
          { validator:isAnnualSales, trigger: 'blur'}
        ],
        annual_sales_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        goods_num:[
          { required: true, message: '请选择商品数量', trigger: 'change' }
        ],
        goods_num_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        avg_price:[
          { required: true, message: '请选择平均客单价', trigger: 'change' }
        ],
        avg_price_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        warehouse_situation:[
          { required: true, message: '请选择仓库情况', trigger: 'change' }
        ],
        warehouse_situation_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        warehouse_address:[
          { validator:isRecommendAdress, trigger: 'blur' }
        ],
        warehouse_address_score:[
          { validator: isRecommendAddressScore, trigger: 'blur' }
        ],
        common_logistics: [
          { required: true, message: '请输入常用物流', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        common_logistics_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        ERP_type:[
          { required: true, message: '请选择ERP类型', trigger: 'change' }
        ],
        ERP_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        agent_operating_company:[
          { validator:isAgentOperatingCompany, trigger: 'blur' }
        ],
        agent_operating_company_score:[
          { validator: isAgentOperatingCompanyScore, trigger: 'blur' }
        ],
        store_type:[
          { required: true, message: '请选择店铺类型', trigger: 'change' }
        ],
        store_type_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        good_category_id:[
          { required: true, message: '请选择主营类目', trigger: 'blur' }
        ],
        good_category_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        company_abbreviation: [
          { required: true, message: '请输入公司简称', trigger: 'blur' }
        ],
        company_abbreviation_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        category_description: [
          { validator:isCategoryDescription, trigger: 'blur' }
        ],
        category_description_score: [
          { validator:isCategoryDescriptionScore, trigger: 'blur' }
        ],
        shop_suffix:[
          { required: true, message: '请选择店铺后缀', trigger: 'change' }
        ],
        shop_suffix_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        store_logo_score:[
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        shop_profile: [
          { required: true, message: '请输入店铺简介', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shop_profile_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
        brand_profile: [
          { required: true, message: '请输入品牌简介', trigger: 'blur' }
        ],
        brand_profile_score: [
          { required: true, message: '请输入分值', trigger: 'blur' }
        ],
      },
      isLongTerm:false,//是否长期
      isLongTermRepresentative:false, //法人代表长期
      //法定代表人数组
      legalRepresentative:[
        {
          id:0,
          name:'大陆身份证'
        },
        {
          id:1,
          name:'护照'
        },
        {
          id:2,
          name:'港澳居民通行证'
        },
        {
          id:3,
          name:'台湾居民通行证'
        },
      ],
      // 纳税人类型
      taxpayersList:[
        {
          id:0,
          name:'一般纳税人'
        },
        {
          id:1,
          name:'小规模纳税人'
        },
        {
          id:2,
          name:'非增值税纳税人'
        },
      ],
      // 纳税人类型税码
      yaxList:[
        {
          id:0,
          name:'0%'
        },
        {
          id:1,
          name:'1%'
        },
        {
          id:2,
          name:'3%'
        },
        {
          id:3,
          name:'6%'
        },
        {
          id:4,
          name:'7%'
        },
        {
          id:5,
          name:'9%'
        },
        {
          id:6,
          name:'10%'
        },
        {
          id:7,
          name:'13%'
        },
        {
          id:8,
          name:'图书9%免税'
        },
      ],
      // 公司类型list
      companyTypeList:[
        {
          id:0,
          name:'生产厂商'
        },
        {
          id:1,
          name:'品牌商'
        },
        {
          id:2,
          name:'代理商'
        },
        {
          id:3,
          name:'经销商'
        },
      ],
      // 商品数量list
      goodsNumList:[
        {
          id:0,
          name:'0-100'
        },
        {
          id:1,
          name:'100-200'
        },
        {
          id:2,
          name:'200-500'
        },
        {
          id:3,
          name:'大于500'
        },
      ],
      // 平均客单价list
      avgPriceList:[
        {
          id:0,
          name:'0-100'
        },
        {
          id:1,
          name:'100-200'
        },
        {
          id:2,
          name:'200-500'
        },
        {
          id:3,
          name:'大于500'
        },
      ],
      // 仓库情况list
      warehouseSituationList:[
        {
          id:0,
          name:'自有仓库'
        },
        {
          id:1,
          name:'第三方仓库'
        },
        {
          id:2,
          name:'无仓库'
        },
      ],
      // ERP类型list
      ERPTypeList:[
        {
          id:0,
          name:'自有ERP'
        },
        {
          id:1,
          name:'第三方ERP代运营'
        },
        {
          id:2,
          name:'无'
        },
      ],
      // 选择类目弹框显示隐藏
      isShowCategory:false,
      // 选择类目可选列表
      goodCategoryList:[],
      selectCategoryName:'',
      categoryProps:{value:'id',label:'name'},
      //素材列表
      materialList: [],
      percent: 0,
      uploadInstance: null,
      lastCategoryId:null,
    }
  },
  mounted() {
    this.getAdminGoodGetCategory()
    areaList.forEach(item=>{
      this.businessProvince.push(item);
      this.openAccountBankProvince.push(item);
    })
    if(this.$route.query.id){
      this.getEditData()
    }
  },
  methods:{
    // 获取编辑数据
    getEditData(){
      for(let i in this.shopForm){
        if(this.editData.hasOwnProperty(i)){
          if(i === 'shop_suffix'){
            this.shopForm[i] = Number(this.editData[i])
          } else if(i === 'recommend_code'){
            if(this.editData[i] === ''){
              this.shopForm[i] = ''
            } else {
              this.shopForm[i] = Number(this.editData[i])
            }
          } else if(i === 'recommend_code_score'){
            if(this.editData[i] === 0){
              this.shopForm[i] = void 0
            } else {
              this.shopForm[i] = Number(this.editData[i])
            }
          } else if(i === 'unified_social_credit_code'){
            this.shopForm[i] = Number(this.editData[i])
          } else if(i === 'taxpayers_identity_num'){
            this.shopForm[i] = Number(this.editData[i])
          } else {
            this.shopForm[i] = this.editData[i];
          }
        }
      }
      this.score = this.editData.score;
      this.material_file = this.editData.material_file;
      this.materialList = this.editData.material_file;
      this.percent = 100;
      if(this.shopForm.business_date_type === 1){
        this.isLongTerm = true
      } else {
        this.isLongTerm = false
      }
      if(this.shopForm.alidity_period_type === 1){
        this.isLongTermRepresentative = true
      } else {
        this.isLongTermRepresentative = false
      }
    },
    //营业执照-选择地区
    selectBusinessProvince(val,type){
      if(type === 1){
        this.shopForm.business_license_location_city = '';
        this.shopForm.business_license_location_area = '';
        this.businessCity = [];
        this.businessArea = [];
        let length = this.businessProvince.length;
        for(let i = 0; i < length; i++){
          if(this.businessProvince[i].name === val){
            this.businessCity = this.businessProvince[i].city;
            return
          }
        }
      } else if(type === 2){
        this.shopForm.business_license_location_area = '';
        this.businessArea = [];
        let length = this.businessCity.length;
        for(let i = 0; i < length;i++){
          if(this.businessCity[i].name === val){
            this.businessArea = this.businessCity[i].area
            return
          }
        }
      }
    },
    //开户银行支行所在地-选择地区
    selectOpenAccountBank(val,type){
      if(type === 1){
        this.shopForm.open_account_bank_cit = '';
        this.shopForm.open_account_bank_area = '';
        this.openAccountBankCity = [];
        this.openAccountBankArea = [];
        let length = this.openAccountBankProvince.length;
        for(let i = 0; i < length; i++){
          if(this.openAccountBankProvince[i].name === val){
            this.openAccountBankCity = this.openAccountBankProvince[i].city;
            return
          }
        }
      } else if(type === 2){
        this.shopForm.open_account_bank_area = '';
        this.openAccountBankArea = [];
        let length = this.openAccountBankCity.length;
        for(let i = 0; i < length;i++){
          if(this.openAccountBankCity[i].name === val){
            this.openAccountBankArea = this.openAccountBankCity[i].area
            return
          }
        }
      }
    },
    // 选择店铺类目
    selectStoreType(){
      this.shopForm.shop_suffix = ''
    },
    // 获取类目
    getAdminGoodGetCategory(){
      adminGoodGetCategory({}).then((res)=>{
        this.goodCategoryList = res.data
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 取消类目
    closeDialog(){
      this.shopForm.good_category_id = JSON.parse(JSON.stringify(this.lastCategoryId))
      this.isShowCategory = false
    },
    // 确定选择类目
    submitAllocation(){
      if(this.shopForm.good_category_id.length){
        this.isShowCategory = false;
      } else {
        this.$message('请选择类目')
      }
    },
    selectLongTerm(val){
      if(val){
        this.shopForm.business_date_type = 1
      } else {
        this.shopForm.business_date_type = 0
      }
    },
    selectLongTermRepresentative(val){
      if(val){
        this.shopForm.alidity_period_type = 1
      } else {
        this.shopForm.alidity_period_type = 0
      }
    },
    // 选择营业日期
    selectBusinessDate(){
      if(this.shopForm.business_date_type !== 1){
        if(this.shopForm.business_date_begin && this.shopForm.business_date_end){
          if(this.shopForm.business_date_begin > this.shopForm.business_date_end){
            this.$nextTick(()=>{
              this.$set(this.shopForm, 'business_date_end', '');
              this.$message('结束日期不能大于开始日期！');
            })
          }
        }
      } else {

      }
    },
    // 选择待定代表人有效期
    selectAlidityPeriod(){
      if(this.shopForm.alidity_period_type !== 1){
        if(this.shopForm.alidity_period_begin && this.shopForm.alidity_period_end){
          if(this.shopForm.alidity_period_begin > this.shopForm.alidity_period_end){
            this.$nextTick(()=>{
              this.$set(this.shopForm, 'alidity_period_end', '');
              this.$message('结束日期不能大于开始日期！');
            })
          }
        }
      } else {

      }
    },
    // 上传素材
    changeFile(event) {
      let chunkSize = 10 * 1024 * 1024;
      let file = event.target.files[0];
      this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
      let obj = {
        name: file.name,
        url: ''
      }
      this.materialList = []
      this.shopForm.material_file = [];
      this.materialList.push(obj);
      this.shopForm.material_file.push(obj);
      let index = 0;
      this.uploadSlice(index);
      this.percent = 0
    },
    uploadSlice(index) {
      let num = this.uploadInstance.total_block_num;
      if (index === num && num !== 1) {
        //合并分片
        mergeSlice({
          flag: this.uploadInstance.file_tmp,
          slice_size: this.uploadInstance.block_size
        }).then(res => {
          if (res.code === 200) {
            this.percent = 100;
            this.shopForm.material_file[0].url = res.data.src
            this.materialList[0].url = res.data.src
            this.$refs.uploadFile.value = null
            this.$message.success(res.msg);
          }
        });
        return;
      }
      this.uploadInstance.uploadSlice(index).then(res => {
        if (res.code === 200) {
          if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
            this.percent = Math.ceil((index / num) * 100);
            this.uploadSlice(++index);
          } else {
            this.percent = 100;
            this.shopForm.material_file[0].url = res.data.src
            this.materialList[0].url = res.data.src
            this.$refs.uploadFile.value = null
            this.$message.success(res.msg);
          }
        }
      });
    },
    //删除素材
    delMaterial(item, index) {
      this.$confirm('是否删除', '提示消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'green-message-box',
        type: 'warning'
      }).then(() => {
        let param = {
          file_url: item.url
        }
        operationProblemDelMaterial(param).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.shopForm.material_file.splice(index, 1);
            this.materialList.splice(index, 1);
          }
        }).catch((err) => {
          console.log(err);
        })
      }).catch(() => {
        this.$message.error('已取消删除');
      })
    },
    openDialog(){
      this.lastCategoryId = JSON.parse(JSON.stringify(this.shopForm.good_category_id));
    },
    goBack(){
      this.$router.go(-1)
    },
    // 保存
    submitShopForm(formName){
      if (!this.practiceForm.name || this.practiceForm.name === '') {
        this.$message.warning('请填写名称！');
        return;
      }
      if (this.practiceForm.name.length < 3 || this.practiceForm.name.length > 50) {
        this.$message.warning('名称必须大于3个字符且小于50个字符！');
        return;
      }
      if (this.practiceForm.classValue.length === 0) {
        this.$message.warning('请选择分类！');
        return;
      }
      if (!this.practiceForm.time) {
        this.$message.warning('请填写考核时间！');
        return;
      }
      // if (this.practiceForm.taskList.length === 0) {
      //   this.$message.warning('请添加任务目标！');
      //   return;
      // }
      if (!this.practiceForm.taskBackground) {
        this.$message.warning('请填写操作要求！');
        return;
      }
      // if (!this.practiceForm.taskAnalysis) {
      //   this.$message.warning('请填写任务分析！');
      //   return;
      // }
      // if (!this.practiceForm.taskOperation) {
      //   this.$message.warning('请填写任务操作！');
      //   return;
      // }
      if (this.practiceForm.scoreList.length === 0) {
        this.$message.warning('请添加评分标准！');
        return;
      }
      this.$refs[formName].validate((valid)=>{
        if(valid){
          if(this.shopForm.material_file.length === 0){
            this.$message.warning('请上传素材');
            return;
          }
          let params = {
            op_name: this.practiceForm.name,
            exam_module_id: this.practiceForm.module_level_three_value,
            category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
            assess_time: this.practiceForm.time,
            task_goal: this.practiceForm.taskList,
            task_background: this.practiceForm.taskBackground,
            task_analysis: this.practiceForm.taskAnalysis,
            task_operation: this.practiceForm.taskOperation,
            scoring_criteria: this.practiceForm.scoreList,
            contact_name:this.shopForm.contact_name,
            contact_name_score:this.shopForm.contact_name_score,
            contact_phone:this.shopForm.contact_phone,
            contact_phone_score:this.shopForm.contact_phone_score,
            contact_email:this.shopForm.contact_email,
            contact_email_score:this.shopForm.contact_email_score,
            is_abutting_joint_JD:this.shopForm.is_abutting_joint_JD,
            document_type:this.shopForm.document_type,
            document_type_score:this.shopForm.document_type_score,
            corporate_name:this.shopForm.corporate_name,
            corporate_name_score:this.shopForm.corporate_name_score,
            unified_social_credit_code:this.shopForm.unified_social_credit_code,
            unified_social_credit_code_score:this.shopForm.unified_social_credit_code_score,
            business_license_location_province:this.shopForm.business_license_location_province,
            business_license_location_city:this.shopForm.business_license_location_city,
            business_license_location_area:this.shopForm.business_license_location_area,
            business_license_location_score:this.shopForm.business_license_location_score,
            business_license_address:this.shopForm.business_license_address,
            business_license_address_score:this.shopForm.business_license_address_score,
            incorporation_date:this.shopForm.incorporation_date,
            incorporation_date_score:this.shopForm.incorporation_date_score,
            business_date_begin:this.shopForm.business_date_begin,
            business_date_type:this.shopForm.business_date_type,
            business_date_score:this.shopForm.business_date_score,
            registered_capital:this.shopForm.registered_capital,
            registered_capital_score:this.shopForm.registered_capital_score,
            business_scope:this.shopForm.business_scope,
            business_scope_score:this.shopForm.business_scope_score,
            legal_representative_certificate_type:this.shopForm.legal_representative_certificate_type,
            legal_representative_certificate_type_score:this.shopForm.legal_representative_certificate_type_score,
            legal_representative_name:this.shopForm.legal_representative_name,
            legal_representative_name_score:this.shopForm.legal_representative_name_score,
            legal_representative_certificates_num:this.shopForm.legal_representative_certificates_num,
            legal_representative_certificates_num_score:this.shopForm.legal_representative_certificates_num_score,
            alidity_period_begin:this.shopForm.alidity_period_begin,
            alidity_period_type:this.shopForm.alidity_period_type,
            alidity_period_score:this.shopForm.alidity_period_score,
            taxpayers_type:this.shopForm.taxpayers_type,
            taxpayers_type_score:this.shopForm.taxpayers_type_score,
            taxpayers_identity_num:this.shopForm.taxpayers_identity_num,
            taxpayers_identity_num_score:this.shopForm.taxpayers_identity_num_score,
            yax_type:this.shopForm.yax_type,
            yax_type_score:this.shopForm.yax_type_score,
            corporate_settlement_bank_account:this.shopForm.corporate_settlement_bank_account,
            corporate_settlement_bank_account_score:this.shopForm.corporate_settlement_bank_account_score,
            branch_bank_account:this.shopForm.branch_bank_account,
            branch_bank_account_score:this.shopForm.branch_bank_account_score,
            branch_bank_name:this.shopForm.branch_bank_name,
            branch_bank_name_score:this.shopForm.branch_bank_name_score,
            open_account_bank_province:this.shopForm.open_account_bank_province,
            open_account_bank_cit:this.shopForm.open_account_bank_cit,
            open_account_bank_area:this.shopForm.open_account_bank_area,
            open_account_bank_score:this.shopForm.open_account_bank_score,
            company_type:this.shopForm.company_type,
            company_type_score:this.shopForm.company_type_score,
            annual_sales:this.shopForm.annual_sales,
            annual_sales_score:this.shopForm.annual_sales_score,
            goods_num:this.shopForm.goods_num,
            goods_num_score:this.shopForm.goods_num_score,
            avg_price:this.shopForm.avg_price,
            avg_price_score:this.shopForm.avg_price_score,
            warehouse_situation:this.shopForm.warehouse_situation,
            warehouse_situation_score:this.shopForm.warehouse_situation_score,
            common_logistics:this.shopForm.common_logistics,
            common_logistics_score:this.shopForm.common_logistics_score,
            ERP_type:this.shopForm.ERP_type,
            ERP_type_score:this.shopForm.ERP_type_score,
            store_type:this.shopForm.store_type,
            store_type_score:this.shopForm.store_type_score,
            good_category_id:this.shopForm.good_category_id[this.shopForm.good_category_id.length - 1],
            good_category_score:this.shopForm.good_category_score,
            company_abbreviation:this.shopForm.company_abbreviation,
            company_abbreviation_score:this.shopForm.company_abbreviation_score,
            shop_suffix:this.shopForm.shop_suffix,
            shop_suffix_score:this.shopForm.shop_suffix_score,
            shop_profile:this.shopForm.shop_profile,
            shop_profile_score:this.shopForm.shop_profile_score,
            brand_profile:this.shopForm.brand_profile,
            brand_profile_score:this.shopForm.brand_profile_score,
            businessLicense_score:this.shopForm.businessLicense_score,
            legal_person_certificate:this.shopForm.legal_person_certificate,
            tax_registration_score:this.shopForm.tax_registration_score,
            taxpayer_qualification_certificate_score:this.shopForm.taxpayer_qualification_certificate_score,
            store_logo_score:this.shopForm.store_logo_score,
            material_file:this.shopForm.material_file,
          }
          params.recommend_code = this.shopForm.recommend_code;
          if(this.shopForm.recommend_code_score === undefined){
            params.recommend_code_score = ''
          } else {
            params.recommend_code_score = this.shopForm.recommend_code_score
          }
          if(this.shopForm.is_abutting_joint_JD === 1){
            params.name = this.shopForm.name;
            params.name_score = this.shopForm.name_score;
          }
          if(this.shopForm.business_date_type !== 1){
            params.business_date_end = this.shopForm.business_date_end;
          }
          if(this.shopForm.alidity_period_type !== 1){
            params.alidity_period_end = this.shopForm.alidity_period_end;
          }
          if(this.shopForm.company_web || this.shopForm.company_web_score){
            params.company_web = this.shopForm.company_web;
            params.company_web_score = this.shopForm.company_web_score;
          }
          if(this.shopForm.warehouse_address || this.shopForm.warehouse_address_score){
            params.warehouse_address = this.shopForm.warehouse_address;
            params.warehouse_address_score = this.shopForm.warehouse_address_score;
          }
          if(this.shopForm.agent_operating_company || this.shopForm.agent_operating_company_score){
            params.agent_operating_company = this.shopForm.agent_operating_company;
            params.agent_operating_company_score = this.shopForm.agent_operating_company_score;
          }
          if(this.shopForm.category_description || this.shopForm.category_description_score){
            params.category_description = this.shopForm.category_description;
            params.category_description_score = this.shopForm.category_description_score;
          }
          if(this.$route.query.id){
            params.id = this.$route.query.id
          }
          operationProblemStoreSettings(params).then((res)=>{
          if(res.code==200){
            this.$message.success(res.msg);
            this.$router.push({
              path: '/practice/index'
            })
          }
          }).catch((err)=>{
            console.log('err', err)
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.shopRegisterSet{
  padding-top: 38px;
}
::v-deep .select-address .el-form-item__content .el-col-7{
  display: flex;
}
::v-deep .el-input-number .el-input__inner{
  text-align: left;
}
.uploading-box{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 16px;
    .left-icon {
      display: inline-block;
      width: 4px;
      height: 16px;
      background-color: #2DC079;
      margin-right: 6px;
      border-radius: 2px;
    }
  }
  .hot-goods {
    justify-content: flex-start;
    .distribution-goods {
      margin-left: 20px;
    }
  }
  //.content-item {
  //  .item {
  //    margin-bottom: 16px;
  //    .text {
  //      display: inline-block;
  //      width: 100px;
  //      text-align: right;
  //      font-size: 16px;
  //      color: #333333;
  //      margin-right: 10px;
  //    }
  //    .name-input {
  //      width: 360px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //        text-align: left;
  //      }
  //    }
  //    .practice-select {
  //      width: 360px;
  //      margin-right: 10px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //      }
  //    }
  //    .practice-cascader {
  //      width: 420px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //      }
  //    }
  //  }
  //  .has-sore {
  //    display: flex;
  //    .has-btn {
  //      display: flex;
  //      align-items: center;
  //      .name-input {
  //        border: 1px solid #DCDFE6;
  //        color: #606266;
  //        height: 40px;
  //        line-height: 40px;
  //        box-sizing: border-box;
  //        padding: 0 15px;
  //        margin-right: 16px;
  //      }
  //      .none-data {
  //        color: #b1b4c1d6;
  //      }
  //    }
  //  }
  //  .goods-put {
  //    .item {
  //      display: flex;
  //      align-items: center;
  //    }
  //  }
  //  .goods-title {
  //    .name-input {
  //      width: 474px;
  //    }
  //  }
  //}
  .upload-btn {
    position: relative;
    display: flex;
    align-items: center;
    .btn {
      width: 88px;
      height: 38px;
      background: #E7F6EF;
      border: 1px solid #2DC079;
      border-radius: 4px;
      line-height: 38px;
      text-align: center;
      color: #2DC079;
      cursor: pointer;
    }
    input {
      width: 88px;
      height: 40px;
      position: absolute;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }
    .text {
      color: #999999;
      font-size: 14px;
      margin-left: 15px;
    }
  }
  .upload-file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: center;
    .file-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 228px;
      height: 40px;
      padding: 10px;
      box-sizing: border-box;
      color: #333333;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        background-color: #F5F7FA;
        .del-icon {
          display: block;
        }
        .finish-icon {
          display: none;
        }
      }
      .file-name {
        display: inline-block;
        width: 1%;
        flex: 1;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      i {
        color: #2461EF;
        margin: 0 10px 0 0;
      }
      .finish-icon {
        color: #2DC079;
        margin: 0 0 0 10px;
      }
      .del-icon {
        display: none;
        color: #FF0000;
        margin: 0 0 0 10px;
      }
    }
  }
}
::v-deep .el-form-item__error{
  //line-height: 10px;
}
.content-item {
  .content-title {
    position: relative;
    padding-left: 10px;
    margin-bottom: 22px;
    &:before {
      content: '';
      width: 4px;
      height: 16px;
      background-color: #2DC079;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .item-content {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .item-title {
      width: 120px;
      text-align: right;
      margin-right: 10px;
    }
    .divide {
      width: 24px;
      height: 2px;
      background: #D2D2D2;
      margin: 0 18px;
    }
    .file-upload-btn {
      display: none;
    }
    .upload-text {
      margin-left: 11px;
      font-size: 14px;
      color: #999999;
    }
    .upload-file-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      align-items: center;
      .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          background-color: #F5F7FA;
          .del-icon {
            display: block;
          }
          .finish-icon {
            display: none;
          }
        }
        .file-name {
          display: inline-block;
          width: 1%;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .el-progress {
          margin-left: 20px;
          width: 160px;
        }
        i {
          color: #2461EF;
          margin: 0 10px 0 0;
        }
        .finish-icon {
          color: #2DC079;
          margin: 0 0 0 10px;
        }
        .del-icon {
          display: none;
          color: #FF0000;
          margin: 0 0 0 10px;
        }
      }
    }
  }

}
.green-dialog{
  position: relative;
  .close-icon{
    position: absolute;
    right: 26px;
    top: 18px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
::v-deep .el-cascader{
  .is-disabled{
    .el-input__inner{
      padding-right: 0;
    }
    .el-input__suffix{
      display: none;
    }
  }
}
</style>

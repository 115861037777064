<template>
    <div>
        <div class="line-item" style="margin-top: 30px">
            <div class="left">数据中心名称</div>
            <div class="right">
                <el-select v-model="ruleForm.data_center_tpl_id" filterable placeholder="请选择数据中心名称" style="width: 360px">
                    <el-option v-for="(item, index) in dataCenterList" :key="`dataCenterList_${index}`" :label="item.date_center_tpl_name" :value="item.data_center_tpl_id"></el-option>
                </el-select>
            </div>
        </div>

        <div class="second-title">
            <span>电子商务基础数据分析</span>
            <el-button type="primary" @click="addBtn">添加数据分析内容</el-button>
        </div>
        <el-table :data="ruleForm.data_analysis_center" class="customTable" style="width: 100%;">
            <el-table-column label="数据分析内容">
                <template slot-scope="scope">
                    <div class="text-overflow-2">{{scope.row.topic}}</div>
                </template>
            </el-table-column>
            <el-table-column label="答案" align="center">
                <template slot-scope="scope">
                    <div class="text-overflow-2">{{scope.row.answer}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="分值" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row, scope.$index)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.$index)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button @click="resetForm">取消</el-button>
        </div>

        <el-dialog title="添加数据分析内容" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <el-form :model="addForm" :rules="rules" ref="addForm" label-width="120px" class="demo-ruleForm" :hide-required-asterisk="true">
                <el-form-item label="数据分析内容" prop="topic">
                    <el-input v-model="addForm.topic"></el-input>
                </el-form-item>
                <el-form-item label="答案" prop="answer">
                    <el-input v-model="addForm.answer"></el-input>
                </el-form-item>
                <el-form-item label="分值" prop="score">
                    <el-input-number class="number-input" v-model="addForm.score" :precision="1" :controls="false" :min="0.1" :max="100" style="width: 100%"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitDataForm('addForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { operationProblemOpDetail, dataCentorTplGetDataCenterTpl, baseDataAnalysisSaveDataAnalysis } from '@/utils/apis.js'
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                ruleForm: {
                    data_center_tpl_id: null,
                    data_analysis_center: [],
                },
                dataCenterList: [], // 数据中心名称列表

                // S 弹窗
                dialogVisible: false,
                addForm: {
                    id: 0,
                    topic: '',
                    answer: '',
                    score: void 0,
                },
                rules: {
                    topic: [
                        { required: true, message: '请输入数据分析内容', trigger: 'blur' },
                    ],
                    answer: [
                        { required: true, message: '请输入答案', trigger: 'blur' },
                    ],
                    score: [
                        { required: true, message: '请选输入分值', trigger: 'blur' },
                    ],
                },
                isEdit: null,
            }
        },
        mounted() {
            this.getDataCenterList()
            if (this.$route.query.id) {
                this.initDetail();
            }
        },
        methods: {
            // 编辑获取详情
            initDetail() {
                operationProblemOpDetail({id: this.$route.query.id}).then(res => {
                    this.ruleForm = {
                        data_center_tpl_id: res.data.data_center_tpl_id,
                        data_analysis_center: res.data.data_analysis_center,
                    }
                }).catch((err) => {})
            },
            getDataCenterList() {
                dataCentorTplGetDataCenterTpl({paging: 0}).then((res) => {
                    this.dataCenterList = res.data.list
                }).catch((err) => {})
            },
            addBtn() {
                this.dialogVisible = true
                this.addForm.id = this.ruleForm.data_analysis_center.length
            },
            editBtn(row, index) {
                this.addForm = JSON.parse(JSON.stringify(row))
                this.dialogVisible = true
                this.isEdit = index
            },
            delBtn(index) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box'
                }).then(() => {
                    this.$message.success('删除成功')
                    this.ruleForm.data_analysis_center.splice(index, 1)
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetDataForm()
                this.isEdit = null
            },
            // 保存数据
            submitDataForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let tmp = {
                            id: this.addForm.id,
                            topic: this.addForm.topic,
                            answer: this.addForm.answer,
                            score: this.addForm.score,
                        }
                        if (this.isEdit === null) {
                            this.ruleForm.data_analysis_center.push(tmp)
                        } else {
                            this.$set(this.ruleForm.data_analysis_center, this.isEdit, tmp)
                        }
                        this.$message.success('数据分析内容添加成功')
                        this.dialogVisible = false
                    } else {
                        return false;
                    }
                });
            },
            // 重置商品
            resetDataForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    id: null,
                    topic: '',
                    answer: '',
                    score: void 0,
                }
            },
            // 保存试题
            submitForm() {
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                if (this.practiceForm.taskList.length === 0) {
                    return this.$message.warning('请添加任务目标！');
                }
                if (!this.practiceForm.taskBackground) {
                    // return this.$message.warning('请填写任务背景！');
                    return this.$message.warning('请填写操作要求！');
                }
                if (!this.practiceForm.taskAnalysis) {
                    return this.$message.warning('请填写任务分析！');
                }
                if (!this.practiceForm.taskOperation) {
                    return this.$message.warning('请填写任务操作！');
                }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }
                if (!this.ruleForm.data_center_tpl_id) {
                    return this.$message.warning('请选择数据中心名称！');
                }
                if (this.ruleForm.data_analysis_center.length === 0) {
                    return this.$message.warning('请添加数据分析内容！');
                }

                let params = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    data_center_tpl_id: this.ruleForm.data_center_tpl_id,
                    data_analysis_center: this.ruleForm.data_analysis_center,
                }
                if (this.$route.query.id) {
                    params.id = Number(this.$route.query.id);
                }
                baseDataAnalysisSaveDataAnalysis(params).then((res) => {
                    if(res.code==200){
                    this.$message.success(res.msg)
                    this.$router.push('/practice/index')
                    }
                }).catch((err) => {})
            },
            // 重置试题
            resetForm() {
                this.ruleForm = {
                    data_center_tpl_id: null,
                    data_analysis_center: [],
                }
                this.$emit('update:practiceForm', {
                    ...this.practiceForm,
                    name: '',
                    time: '',
                    module_level_one_value: '',
                    module_level_tow_value: '',
                    module_level_three_value: '',
                    classValue: [],
                    taskList: [],
                    taskBackground: '',
                    taskAnalysis: '',
                    taskOperation: '',
                    scoreList: [],
                })
                this.$router.push('/practice/index');
            },
        }
    }
</script>

<style scoped lang="scss">
    .line-item {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .left {
            width: 100px;
            margin-right: 10px;
            color: #333;
            font-size: 16px;
            text-align: right;
        }
    }
    .second-title {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background: #2DC079;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    .demo-ruleForm {
        .el-form-item {
            margin-bottom: 16px;
        }
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #666;
        }
        ::v-deep .el-form-item__error {
            padding-top: 2px;
        }
    }
</style>
<template>
    <div class="main-box">
        <div class="title-block">
            <div class="title-txt">
                上传素材
            </div>
            <div class="upload-content">
                <el-button type="primary" @click="handleFileUpload">点击上传</el-button>
                <p>文件支持格式为zip和rar，文件大小不超过1G</p>
                <input type="file" name="" id="file" ref="file" @change="uploadFile">
            </div>
            <div class="file-list-preview" v-if="name">
                <div class="file-item">
                    <div class="file-icon">
                        <i class="iconfont">&#xe60b;</i>
                    </div>
                    <div class="file-name">
                        <span>{{name}}</span>
                    </div>
                    <div class="process-bar" v-if="percentage">
                        <el-progress :percentage="percentage" color="#2DC079"></el-progress>
                    </div>
                    <div class="action-icon">
                        <i class="el-icon-success icon-ok" v-if="isFinish"></i>
                        <i class="el-icon-error icon-fail" @click="cancelUpload" v-if="percentage"></i>
                    </div>
                    <div class="download-item" v-if="url">
                        <el-button size="mini" @click="downloadFile">下载</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {sliceFile, fileNameExt, randomStr} from "@/utils/common";
import axios from "axios";
export default {
    name: "materialUpload",
    props: {
        url: String,
        name: String
    },
    data() {
        return {
            fileExt: '',
            fileName: '',
            blobList: [],
            percentage: 0,
            status: true,
            isFinish: false,
        }
    },
    methods: {
        downloadFile() {
            window.open(localStorage.getItem('hosturl') + this.url);
            console.log(this.url);
        },
        uploadFile() {
            let fileEle = this.$refs.file.files[0];
            const [blobList, total] = sliceFile(fileEle, 1024 * 1024);
            this.blobList = blobList;
            //文件名和文件后缀
            let [fileName, fileExt] = fileNameExt(fileEle.name);
            this.fileName = fileName;
            this.fileExt = fileExt;
            console.log(fileName,fileExt);
            if(fileExt==='zip' || fileExt==='rar'){
                this.$emit('setname', `${fileName}.${fileExt}`);
                this.$emit('change', {name: `${fileName}.${fileExt}`, url: ''});
                let flag = randomStr(8);
                this.status = true;
                this.uploadSlice(0, flag, total);
            }else{
                this.$message.warning("文件格式不对，仅支持zip,rar格式！")
            }
            
        },
        handleFileUpload() {
            this.$refs.file.click()
        },
        cancelUpload() {
            this.status = false;
            this.$emit('setname', '');
            this.percentage = 0;
            this.$refs.file.value = '';
        },
        uploadSlice(i, flag, total) {
            if (!this.status) {
                return
            }
            // console.log(this.fileName,"ll");
            let formdata = new FormData();
            let sliceFile = new File([this.blobList[i]], `${this.fileName}_${i}.${this.fileExt}`);
            formdata.append('file', sliceFile);
            formdata.append('flag', flag);
            formdata.append('index', i);
            formdata.append('total', total);

            this.uploadSliceApi(formdata).then(res => {
                i++
                this.percentage = Math.round((i / total) * 100);
                if (res.code === 200 && res.data.url) {
                    console.log(res.data.url,"返回的路径");
                    this.isFinish = true;
                    this.percentage = 0;
                    this.$emit('change', {name: `${this.fileName}.${this.fileExt}`, url: res.data.url});
                }
                this.$refs.file.value = '';
                if (i < total && !this.stopFlag) {
                    this.uploadSlice(i, flag, total);
                }
            }).catch(err => {
                this.$refs.file.value = '';
                console.log('err', err)
            })
        },
        //不走loading组件的动效交互，自己封装一个后端请求api
        uploadSliceApi(param) {
            const instance = axios.create();
            instance.interceptors.response.use(res => {
                return res.data;
            }, error => {
                return Promise.reject(error);
            })
            return instance.post('upload/material', param, {
                headers: {
                    "authorization": localStorage.getItem('adminToken'),
                    "Content-Type": "application/json"
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.main-box {
    &, & * {
        box-sizing: border-box;
    }
    #file {
        display: none;
    }
    .title-block {
        .title-txt {
            font-size: 16px;
            color: #333333;
            position: relative;
            padding-left: 14px;
            &::before {
                display: inline-block;
                position: absolute;
                width: 4px;
                height: 16px;
                top: 2px;
                left: 0px;
                content: "";
                background-color: #2DC079;
                border-radius: 2px;
            }
        }
        .upload-content {
            margin-top: 26px;
        }
        .file-list-preview {
            margin-top: 16px;
            .file-item {
                height: 40px;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                padding: 0 20px;
                &:hover {
                    background-color: #F5F7FA;
                }
                .file-icon {
                    i {
                        color: #2461EF;
                    }
                }
                .file-name {
                    margin: 0  10px 0 12px;
                    font-size: 14px;
                    color: #333333;
                }
                .process-bar {
                    width: 200px;
                }
                .action-icon {
                    cursor: pointer;
                    margin-left: 12px;
                    i {
                        font-size: 16px;
                    }
                    .icon-ok {
                        color: #2DC079;
                    }
                    .icon-fail {
                        color: #ff0000;
                    }
                }
                .download-item {
                    margin-left: 12px;
                }
            }
        }
    }
}
</style>
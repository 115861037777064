<template>
    <div>
        <div class="line-item" style="margin-top: 30px">
            <div class="left">运营推广名称</div>
            <div class="right">
                <el-select v-model="ruleForm.operation_promote_id" placeholder="请选择运营推广名称" style="width: 320px">
                    <el-option v-for="(item, index) in operateList" :key="`dataCenterList_${index}`" :label="item.title" :value="item.id"></el-option>
                </el-select>
            </div>
        </div>
        <div class="line-item">
            <div class="left">满分转化数</div>
            <div class="right">
                <el-input-number class="number-input" v-model="ruleForm.full_marks" :precision="0" :controls="false" :min="1" :max="9999999999" style="width: 320px"></el-input-number>
            </div>
        </div>
        <div class="line-item">
            <div class="left">设置分值</div>
            <div class="right">
                <el-input-number class="number-input" v-model="ruleForm.score" :precision="1" :controls="false" :min="0.1" :max="100.0" style="width: 320px"></el-input-number>
            </div>
        </div>

        <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button @click="resetForm">取消</el-button>
        </div>
    </div>
</template>

<script>
    import { operationProblemGetInformationList, operationProblemSavePromote, operationProblemGetInformationInfo } from '@/utils/apis.js'
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            },
            typeId: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                ruleForm: {
                    operation_promote_id: null,
                    full_marks: void 0,
                    score: void 0,
                },
                operateList: [], // 运营推广列表
            }
        },
        mounted() {
            this.getOperateList()
            if (this.$route.query.id) {
                this.initDetail();
            }
        },
        methods: {
            // 编辑获取详情
            initDetail() {
                operationProblemGetInformationInfo(this.$route.query.id).then(res => {
                    this.ruleForm = {
                        operation_promote_id: res.data.detail.operation_promote_id,
                        full_marks: res.data.detail.full_marks,
                        score: res.data.op_main.score,
                    }
                })
            },
            // 获取运营推广列表
            getOperateList() {
                operationProblemGetInformationList({module_id: this.typeId}).then((res) => {
                    this.operateList = res.data
                }).catch((err) => {})
            },
            // 保存试题
            submitForm() {
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                // if (this.practiceForm.taskList.length === 0) {
                //     return this.$message.warning('请添加任务目标！');
                // }
                if (!this.practiceForm.taskBackground) {
                    return this.$message.warning('请填写操作要求！');
                }
                // if (!this.practiceForm.taskAnalysis) {
                //     return this.$message.warning('请填写任务分析！');
                // }
                // if (!this.practiceForm.taskOperation) {
                //     return this.$message.warning('请填写任务操作！');
                // }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }
                if (!this.ruleForm.operation_promote_id) {
                    return this.$message.warning('请选择运营推广名称！');
                }
                if (!this.ruleForm.full_marks) {
                    return this.$message.warning('请输入满分转化数！');
                }
                if (!this.ruleForm.score) {
                    return this.$message.warning('请输入分值！');
                }

                let params = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    operation_promote_id: this.ruleForm.operation_promote_id,
                    full_marks: this.ruleForm.full_marks,
                    score: this.ruleForm.score,
                }
                if (this.$route.query.id) {
                    params.id = Number(this.$route.query.id);
                }
                operationProblemSavePromote(params).then((res) => {
                   if(res.code==200){
                    this.$message.success(res.msg)
                    this.$router.push('/practice/index')
                   }
                })
            },
            // 重置试题
            resetForm() {
                this.ruleForm = {
                    operation_promote_id: null,
                    full_marks: void 0,
                    score: void 0,
                }
                this.$emit('update:practiceForm', {
                    ...this.practiceForm,
                    name: '',
                    time: '',
                    module_level_one_value: '',
                    module_level_tow_value: '',
                    module_level_three_value: '',
                    classValue: [],
                    taskList: [],
                    taskBackground: '',
                    taskAnalysis: '',
                    taskOperation: '',
                    scoreList: [],
                })
                this.$router.push('/practice/index');
            },
        }
    }
</script>

<style scoped lang="scss">
    .line-item {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .left {
            width: 100px;
            margin-right: 10px;
            color: #333;
            font-size: 16px;
            text-align: right;
        }
    }
</style>
